import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const sessionToken = localStorage.getItem("sessionToken");
		const authRequest = req.clone({
			headers: req.headers.set("Authorization", [sessionToken]),
		});
		return next.handle(authRequest);
	}
}
