import { FixtureUpdates } from "./fixtureUpdates";

export class FixtureUpdatesTracker {
	protected ids: string[];
	protected _updates: FixtureUpdates;

	constructor(ids: string[]) {
		this.ids = ids;
		this._updates = new FixtureUpdates();
	}

	protected getSelectedIds(): string[] {
		return this.ids;
	}

	protected getPendingStopStart(): boolean {
		return this.ids.length > 0 && this._updates && this._updates.halt && this._updates.halt.length > 0;
	}

	protected getPendingRestart(): boolean {
		return this.ids.length > 0 && this._updates && this._updates.restart && this._updates.restart.length > 0;
	}

	protected getPendingUpdates(): boolean {
		return (
			this.ids.length > 0 &&
			this._updates &&
			(this._updates.server != null ||
				this._updates.logging != null ||
				this._updates.logging_betfair != null ||
				(this._updates.markets && this._updates.markets.length > 0) ||
				(this._updates.sets && this._updates.sets.length > 0))
		);
	}

	protected getUpdates() {
		if (this.getPendingStopStart()) {
			return {
				ids: this.ids,
				data: {
					halt: this._updates.halt.toArray(),
				},
			};
		} else if (this.getPendingRestart()) {
			return {
				ids: this.ids,
				data: {
					sets: this._updates.restart,
				},
			};
		} else {
			return {
				ids: this.ids,
				data: {
					server: this._updates.server,
					logging: this._updates.logging,
					logging_betfair: this._updates.logging_betfair,
					markets: this._updates.markets.toArray(),
					sets: this._updates.sets.toArray(),
					factor: null,
				},
			};
		}
	}

	setServer(server: string) {
		if (server) {
			this._updates.server = server;
		} else {
			this._updates.server = "unset";
		}
	}

	setMarket(marketCode: string, selected: boolean) {
		this._updates.addMarket(marketCode, selected);
	}

	setFollow(setId: string, selected: boolean) {
		this._updates.addSet(setId, selected);
	}

	setHalt(setId: string, selected: boolean) {
		this._updates.addHalt(setId, selected);
	}

	setRestart(setId: string) {
		this._updates.addRestart(setId);
	}

	setLogging(enabled: boolean) {
		this._updates.logging = enabled ? "1" : "0";
	}

	setLoggingBetfair(enabled: boolean) {
		this._updates.logging_betfair = enabled ? "1" : "0";
	}
}
