import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "capitalize" })
export class CapitalizePipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return null;
		}

		const words = value.split(" ");
		for (let index = 0; index < words.length; index++) {
			let word = words[index];
			word = word[0].toUpperCase() + word.substr(1).toLowerCase();
			words[index] = word;
		}

		return words.join(" ");
	}
}
