import { StatisticsFeed } from "./statisticsFeed";

export class Statistics {
	home_score: string;
	home_score_ht: string;
	home_rcards: string;
	home_ycards: string;
	home_corners: string;
	home_dat: string;

	away_score: string;
	away_score_ht: string;
	away_rcards: string;
	away_ycards: string;
	away_corners: string;
	away_dat: string;

	period: string;
	minute: string;
	duration: number;

	fastestFeed: string;
	feeds: StatisticsFeed[];

	constructor(statistics: Statistics) {
		this.feeds = new Array<StatisticsFeed>();

		if (statistics) {
			this.home_score = statistics.home_score;
			this.home_score_ht = statistics.home_score_ht;
			this.home_rcards = statistics.home_rcards;
			this.home_ycards = statistics.home_ycards;
			this.home_corners = statistics.home_corners;
			this.home_dat = statistics.home_dat;

			this.away_score = statistics.away_score;
			this.away_score_ht = statistics.away_score_ht;
			this.away_rcards = statistics.away_rcards;
			this.away_ycards = statistics.away_ycards;
			this.away_corners = statistics.away_corners;
			this.away_dat = statistics.away_dat;

			this.fastestFeed = statistics.fastestFeed;

			this.period = statistics.period;
			this.minute = statistics.minute;
			this.duration = statistics.duration;

			this.setFeeds(statistics.feeds);
		}
	}

	get xy(): StatisticsFeed[] {
		return this.feeds.filter((feed) => feed.xy);
	}

	get gameTime(): string {
		let result = "-";

		if (this.period) {
			result = this.period;
			if (this.minute) {
				result += ` - ${this.minute}`;
			}
		} else {
			if (this.minute) {
				result = this.minute;
			}
		}

		return result;
	}

	get score_full(): string {
		if (this.home_score != null && this.away_score != null) {
			return `${this.home_score} - ${this.away_score}`;
		} else {
			return "-";
		}
	}

	get score_half(): string {
		if (this.home_score_ht != null && this.away_score_ht != null) {
			return `${this.home_score_ht} - ${this.away_score_ht}`;
		} else {
			return "-";
		}
	}

	get rcards(): string {
		if (this.home_rcards != null && this.away_rcards != null) {
			return `${this.home_rcards} - ${this.away_rcards}`;
		} else {
			return "-";
		}
	}

	get ycards(): string {
		if (this.home_ycards != null && this.away_ycards != null) {
			return `${this.home_ycards} - ${this.away_ycards}`;
		} else {
			return "-";
		}
	}

	get corners(): string {
		if (this.home_corners != null && this.away_corners != null) {
			return `${this.home_corners} - ${this.away_corners}`;
		} else {
			return "-";
		}
	}

	get dats(): string {
		if (this.home_dat != null && this.away_dat != null) {
			return `${this.home_dat} - ${this.away_dat}`;
		} else {
			return "-";
		}
	}

	update(data: Statistics) {
		Object.keys(data).forEach((property) => {
			if (property !== "feeds") {
				this[property] = data[property];
			}
		});

		this.setFeeds(data.feeds);
	}

	private setFeeds(data: StatisticsFeed[]) {
		this.feeds = [];

		if (data) {
			for (const feed of data) {
				this.feeds.push(new StatisticsFeed(feed));
			}
			this.feeds.sort(this.feedSorter);
		}
	}

	private feedSorter(lh: StatisticsFeed, rh: StatisticsFeed) {
		return lh.origin < rh.origin ? -1 : 1;
	}
}
