export class ScoreEvent {
	hashCode: number;
	state: string;
	home_score: string;
	away_score: string;
	home_rcards: string;
	away_rcards: string;
	scoreState: string;
	time: Date;
	eventsFeed: string;

	constructor(scoreState: ScoreEvent) {
		this.hashCode = scoreState.hashCode;
		this.state = scoreState.state;
		this.home_score = "home_score" in scoreState ? scoreState.home_score : null;
		this.away_score = "away_score" in scoreState ? scoreState.away_score : null;
		this.home_rcards = "home_rcards" in scoreState ? scoreState.home_rcards : null;
		this.away_rcards = "away_rcards" in scoreState ? scoreState.away_rcards : null;
		this.scoreState = scoreState.scoreState;
		this.time = new Date(scoreState.time);
		this.eventsFeed = scoreState.eventsFeed;
	}
}
