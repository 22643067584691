<mat-chip class="user-avatar" *ngIf="user" color="primary" selected="true" [matTooltip]="user.name | capitalize" [matMenuTriggerFor]="userMenu">
	{{user.name | initial}}
</mat-chip>
<mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
	<button mat-menu-item (click)="userProfile()">Profile</button>
	<button mat-menu-item [matMenuTriggerFor]="themeMenu">Theme</button>
	<mat-menu #themeMenu="matMenu" xPosition="before">
		<button mat-menu-item (click)="lightTheme()">Light</button>
		<button mat-menu-item (click)="darkTheme()">Dark</button>
	</mat-menu>
	<mat-divider></mat-divider>
	<button mat-menu-item (click)="logout()">Log out</button>
</mat-menu>
