import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve) => {
			this.authService.authenticate().subscribe(
				() => {
					resolve(true);
				},
				() => {
					this.router.navigate(["/auth/login"], {
						queryParams: { returnUrl: state.url },
					});
					resolve(false);
				}
			);
		});
	}
}
