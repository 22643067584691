export const environment = {
	production: false,
	name: "Staging2",
	color: "staging2",
	AUTH_ENDPOINT: "https://betrobot-staging2.grassiventures.com:15053/api",
	TXODDS_PUSHFEED_ENDPOINT: "https://pushfeed.grassiventures.com:15040/api",
	BETFAIR_HISTORIC_DATA_ENDPOINT: "https://betrobot.grassiventures.com:15060/api",
	GOLDFINGER_MONITOR_ENDPOINT: "https://betrobot.grassiventures.com:8090",
	BETROBOT_API_ENDPOINT: "https://betrobot-staging2.grassiventures.com:15073/api",
	BETROBOT_PUSHFEED_ENDPOINT: "betrobot-staging2.grassiventures.com:15075",
};
