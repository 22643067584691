import { Exchange } from "./exchange";
import { ExchangeSet } from "./exchangeSet";

export class ExchangeList {
	private _exchanges: Exchange[];

	constructor(exchanges?: Exchange[]) {
		if (exchanges) {
			this._exchanges = exchanges;
		} else {
			this._exchanges = new Array<Exchange>();
		}
	}

	get(): Exchange[] {
		return this._exchanges;
	}

	push(exchanges: Exchange[]) {
		exchanges.forEach((exchange) => {
			this._exchanges.push(exchange);
		});
	}

	translateMarketName(marketTypeId: number): string {
		let result = null;

		if (this._exchanges) {
			const exchange = this.getExchangeForMarketType(marketTypeId);
			if (exchange) {
				const marketType = exchange.marketTypes.find((mt) => mt.id === marketTypeId);
				if (marketType) {
					result = marketType.name;
				}
			}
		}

		return result;
	}

	translateSetName(setId: string): string {
		let result = null;

		if (this._exchanges) {
			const exchange = this.getExchangeForSet(setId);
			if (exchange) {
				const set = exchange.sets.find((s) => s.code === setId);
				if (set) {
					result = set.name;
				}
			}
		}

		return result;
	}

	getAllSets(): ExchangeSet[] {
		return this._exchanges.map((exchange) => exchange.sets).reduce((a, b) => a.concat(b));
	}

	private getExchangeForSet(setId: string): Exchange {
		let result = null;

		this._exchanges.forEach((exchange) => {
			if (exchange.sets.findIndex((set) => set.code === setId) > -1) {
				result = exchange;
				return false;
			}
		});

		return result;
	}

	private getExchangeForMarketType(marketTypeId: number): Exchange {
		let result = null;

		this._exchanges.forEach((exchange) => {
			if (exchange.marketTypes.findIndex((marketType) => marketType.id === marketTypeId) > -1) {
				result = exchange;
				return false;
			}
		});

		return result;
	}
}
