export class Log {
	message;
	messageText: string;
	explain: Array<[string, Array<string>]>;
	private _summary: string;

	constructor(log: Log) {
		if (log.message.explain) {
			this.explain = Object.keys(log.message.explain).map((entry) => {
				return [entry, Object.values(log.message.explain[entry])];
			});
			delete log.message.explain;

			for (const section of this.explain) {
				const cleaned = [];
				let table = [];
				for (const line of section[1]) {
					if (line.startsWith("<T>")) {
						table.push("<table>");
					} else if (line.startsWith("<H>")) {
						table.push(
							"<tr>" +
								line
									.replace("<H>", "")
									.split(";")
									.map((e) => `<th>${e}&nbsp;&nbsp;</th>`)
									.join("") +
								"</tr>"
						);
					} else if (line.startsWith("<R>")) {
						table.push(
							"<tr>" +
								line
									.replace("<R>", "")
									.split(";")
									.map((e) => `<td>${e}&nbsp;&nbsp;</td>`)
									.join("") +
								"</tr>"
						);
					} else if (line.startsWith("</T>")) {
						table.push("</table>");
						cleaned.push(table.join(""));
						table = [];
					} else {
						cleaned.push(line);
					}
				}
				section[1] = cleaned;
			}
		}

		if (log.message.message) {
			this.messageText = log.message.message
				.split(" | ")
				.map((entry) =>
					entry.includes("##")
						? entry
								.trim()
								.substring(entry.indexOf("##") + 3)
								.replace(/ {2}/g, " ")
						: entry
				)
				.reverse()
				.join("<br/>");
		}
		this.message = log.message;
		this._summary = null;
	}

	get bookieSummary(): string {
		if (!this._summary) {
			const table = [];

			if (this.message.input && this.message.input.odds) {
				table.push("<table>");

				const line = this.message["input"]["odds"];
				Object.keys(line).forEach((lineOdd) => {
					Object.keys(line[lineOdd]).forEach((bookieOdd) => {
						const bookie = line[lineOdd][bookieOdd];
						if (bookie.valid && !bookie.outlier && !bookie.monitor) {
							table.push("<tr>");

							let draw = "";
							if ("58805" in bookie) {
								draw = bookie["58805"];
							}
							if ("2004841" in bookie) {
								draw = bookie["2004841"];
							}

							let home = "2";
							let away = "3";
							if ("30246" in bookie) {
								home = "30246";
								away = "30247";
							}

							table.push("<td>");
							table.push(bookie["bookie"] + "&nbsp;");
							table.push("</td>");
							table.push("<td>");
							table.push(lineOdd.replace("_", ".") + "&nbsp;");
							table.push("</td>");
							table.push("<td>");
							table.push(bookie[home] + "&nbsp;");
							table.push("</td>");
							if (draw) {
								table.push("<td>");
								table.push(" / ");
								table.push("</td>");
								table.push("<td>");
								table.push(draw + "&nbsp;");
								table.push("</td>");
							}
							table.push("<td>");
							table.push(" / ");
							table.push("</td>");
							table.push("<td>");
							table.push(bookie[away] + "&nbsp;");
							table.push("</td>");
							table.push("<td>");
							table.push(new Date(bookie["lastupdate"]).toISOString());
							table.push("</td>");

							table.push("</tr>");
						}
					});
				});

				table.push("</table>");
			}

			this._summary = table.join("");
		}

		return this._summary;
	}
}
