import { DateTime } from "luxon";

const setMapping = new Map<string, string>();
setMapping.set("1", "4e1d906a66a0848304000005");
setMapping.set("2", "4fdf23ad66a084ac50000378");
setMapping.set("3", "50ffa91dcf46094185f12b45");
setMapping.set("4", "514839858df9013969000002");
setMapping.set("5", "51ac434ff973b27f68373160");
setMapping.set("6", "51caae7696d8dd9d030000d2");
setMapping.set("7", "51cac7749fb6243dfbdf3485");
setMapping.set("8", "5229765757ad18e60d000002");
setMapping.set("9", "530476edb1c17d73038b4569");
setMapping.set("11", "5c4eca0ba94869ac086852b1");
setMapping.set("12", "60b4a3667ec753648d5c02fb");
setMapping.set("13", "621e1675dbd03eda20d0f302");
setMapping.set("14", "649547a6774925e513613c19");
setMapping.set("15", "64e73def573a33aeac213e67");
setMapping.set("16", "6527e020d2aad8e1fafa178d");
setMapping.set("17", "65cf3d35acf12fee885eb9bf");
setMapping.set("18", "660bf83377ad8cba43bdc5c1");
setMapping.set("20", "67f379c9c07960affa36d316");

const lowPriorityAlerts = new Array<string>();
lowPriorityAlerts.push("fixed");
lowPriorityAlerts.push("different_mPrice");

export class Alert {
	public _id: string;
	public fixtureId: string;
	public matchId: number;
	public type: string;
	public home: string;
	public away: string;
	public league: string;
	public leagueFactor: number;
	public matchTime: Date;
	public score: string;
	public minute: string;
	public gameTime: string;
	public setNr: string;
	public details: string;
	public processed: Date;
	public user: string;
	public note: string;
	public timestamp: Date;
	public haltedSets: string[];
	public checked: string;
	public halted: boolean;
	public logging: boolean;

	public uniqId: string;
	public sport: string;
	public raceId: string;

	constructor(source: Alert) {
		if (!source._id.startsWith("id_")) {
			this._id = "id_" + source._id;
		} else {
			this._id = source._id;
		}

		this.fixtureId = source.fixtureId;
		this.matchId = source.matchId;
		this.type = source.type;
		this.home = source.home;
		this.away = source.away;
		this.league = source.league;
		this.leagueFactor = source.leagueFactor;
		this.matchTime = source.matchTime ? DateTime.fromSeconds(+source.matchTime).toJSDate() : null;
		this.score = source.score;
		this.minute = source.minute;
		this.gameTime = source.gameTime;
		this.setNr = source.setNr ? source.setNr.toString() : null;
		this.details = source.details;
		this.processed = source.processed ? DateTime.fromSeconds(+source.processed).toJSDate() : null;
		this.user = source.user;
		this.note = source.note;
		this.timestamp = DateTime.fromSeconds(+source.timestamp).toJSDate();
		this.haltedSets = source.haltedSets;
		this.checked = source.checked;
		this.halted = source.halted;
		this.logging = source.logging;
		this.uniqId = source.uniqId;
		this.sport = source.sport;
		this.raceId = source.raceId;
	}

	get setCode(): string {
		if (setMapping.has(this.setNr)) {
			return setMapping.get(this.setNr);
		} else {
			return "4e1d906a66a0848304000005";
		}
	}

	get beingChecked(): string {
		if (this.checked) {
			return `Being checked by ${this.checked}`;
		} else {
			return "";
		}
	}

	get fixture(): string {
		let result = this.home;

		if (this.away) {
			result += " - " + this.away;
		}

		return result;
	}

	get warn(): boolean {
		return this.state === 1;
	}

	get state(): number {
		if (this.processed) {
			return 2;
		} else if (this.checked) {
			return 1;
		} else {
			return 0;
		}
	}

	get priority(): number {
		if (this.halted) {
			return 1;
		} else {
			if (lowPriorityAlerts.includes(this.type)) {
				return -1;
			} else {
				return 0;
			}
		}
	}

	get linkDetails(): string {
		if (this.raceId) {
			if (this.sport == "horses" || this.sport == "greyhounds") {
				return "Races Details";
			} else {
				return "Other Sport Details";
			}
		} else {
			return "Details";
		}
	}

	get linkArgs(): string {
		if (this.fixtureId) {
			return `${this.fixtureId}:${this.setCode}`;
		} else if (this.raceId) {
			return `${this.uniqId}:${this.sport}:${this.raceId}`;
		} else {
			return null;
		}
	}
}
