export class Betcount {
	public accountId: number;
	public account: string;
	public betfair: number;
	public betrobot: number;
	public noSet: number;
	public old: number;

	constructor(betcount: Betcount) {
		this.accountId = betcount.accountId;
		this.account = betcount.account;
		this.betfair = betcount.betfair || 0;
		this.betrobot = betcount.betrobot || 0;
		this.old = betcount.old || 0;
		this.noSet = betcount.noSet || 0;
	}

	get warn(): boolean {
		return this.betrobot !== this.betfair;
	}

	get enableDetail(): boolean {
		return this.betfair === 0 && this.betfair < this.betrobot;
	}
}
