import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-actions-dialog",
	templateUrl: "actions-dialog.component.html",
	styleUrls: ["./actions-dialog.component.scss"],
})
export class ActionsDialogComponent {
	state = "prompt";

	constructor(public dialogRef: MatDialogRef<ActionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

	proceed(): void {
		this.state = "running";

		this.data
			.action()
			.toPromise()
			.then((response) => {
				if (this.data.successHandler) {
					const successMessage = this.data.successHandler(response);
					if (successMessage) {
						this.data.message = successMessage;
					}
				}
				this.state = "success";
			})
			.catch((error) => {
				this.state = "error";
				console.error(error);
			});
	}
}
