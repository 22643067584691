<h3 mat-dialog-title
	cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle>
	<span>Edit note {{data.records.length > 1 ? "" : " - " + data.records[0].fixture}}</span>
</h3>
<form [formGroup]="updateForm" novalidate (ngSubmit)="save()">
	<mat-dialog-content>
		<div class="form-group">
			<mat-form-field class="input-full-width">
					<input type="text" class="form-control" formControlName="note" matInput placeholder="Note" required>
					<mat-error *ngIf="updateForm.controls['note'].invalid">
						<span *ngIf="updateForm.controls['note'].hasError('required')">Field is required</span>
					</mat-error>
				</mat-form-field>

				<div class="input-full-width small" *ngIf="data.halted">
					<mat-checkbox class="form-control" formControlName="resume">Resume match{{data.records.length > 1 ? "es" : ""}}</mat-checkbox>
				</div>
				<div class="input-full-width small" *ngIf="data.validations">
					<mat-checkbox class="form-control" formControlName="validations">Add validations</mat-checkbox>
				</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<div class="buttons">
			<button mat-button mat-dialog-close color="primary">Close</button>
			<button mat-button type="submit" [disabled]="!updateForm.valid" color="primary" mat-raised-button>Save</button>
		</div>
	</mat-dialog-actions>
</form>
