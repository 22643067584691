import { FixtureParameter } from "./fixtureParameter";

export class Fixture {
	_id: string;
	id: number;
	tipex_matchid: string;
	matchTime: string;
	home: string;
	away: string;
	league: string;
	factor_league: string;
	factor_custom: string;
	factor: string;
	factor_match: string;
	server: string;
	betfairId: string;
	betfairEventId: number;
	logging: number;
	logging_betfair: number;
	activityFeed: string;
	bookies: string;
	coverage: string;
	starting_ft_tge: number;
	starting_ft_sup: number;
	_factor_computed: string; // for filtering purposes
	_league_factor_formatted: string; // for filtering purposes
	haltedSets: string[];
	warningSets: string[];
	selectedSets: string[];
	selectedMarkets: string[] | string;
	disabledMarkets: string[] | string;
	unAvailableMarkets: string[] | string;
	parameters: FixtureParameter[];

	bf_teams_reversed: number;
	unmanaged: number;
	postponed: number;
	notGoingInPlay: number;
	no_game_events: number;
	reason_stopped: string;
	activityScore: string;
	unlink_sp: number;
	invert_sp: number;
	invert_bb: number;
	cluster: number;

	constructor(fixture: Fixture) {
		this._id = fixture._id;
		this.id = fixture.id;
		this.tipex_matchid = fixture.tipex_matchid;
		this.matchTime = fixture.matchTime;
		this.home = fixture.home;
		this.away = fixture.away;
		this.league = fixture.league;
		this.factor_league = fixture.factor_league;
		this.factor_custom = fixture.factor_custom;
		this.factor = fixture.factor;
		this.factor_match = fixture.factor_match;
		this.server = fixture.server;
		this.logging = +fixture.logging || 0;
		this.logging_betfair = +fixture.logging_betfair || 0;
		this.activityFeed = fixture.activityFeed || "-";
		this.bookies = fixture.bookies || "-";
		this.starting_ft_tge = fixture.starting_ft_tge || 0;
		this.starting_ft_sup = fixture.starting_ft_sup || 0;
		this.haltedSets = fixture.haltedSets ? fixture.haltedSets : [];
		this.warningSets = fixture.warningSets ? fixture.warningSets : [];
		this.selectedSets = fixture.selectedSets;
		this.selectedMarkets = fixture.selectedMarkets;
		this.disabledMarkets = fixture.disabledMarkets;
		this.unAvailableMarkets = fixture.unAvailableMarkets;
		this.betfairEventId = fixture.betfairEventId;
		this.coverage = fixture.coverage && fixture.coverage !== "0" ? fixture.coverage : "";

		this.bf_teams_reversed = fixture.bf_teams_reversed || 0;
		this.unmanaged = fixture.unmanaged || 0;
		this.postponed = fixture.postponed || 0;
		this.notGoingInPlay = fixture.notGoingInPlay || 0;
		this.no_game_events = fixture.no_game_events || 0;
		this.reason_stopped = fixture.reason_stopped || "Unknown";
		this.activityScore = fixture.activityScore || null;
		this.unlink_sp = fixture.unlink_sp || 0;
		this.invert_sp = fixture.invert_sp || 0;
		this.invert_bb = fixture.invert_bb || 0;
		this.cluster = fixture.cluster || 0;

		this.betfairId = fixture.betfairId ? fixture.betfairId.toString() : "";
		if (this.betfairId.length > 1 && this.betfairId.length < 11) {
			if (this.betfairId.toString().padEnd) {
				this.betfairId = this.betfairId.toString().padEnd(11, "0");
			}
		}

		this.parameters = new Array<FixtureParameter>();
		if (fixture.parameters) {
			fixture.parameters.map((param) => this.parameters.push(new FixtureParameter(param)));
		}
	}

	isMarketAvailable(marketCode: string): boolean {
		return this.unAvailableMarkets.indexOf(marketCode) === -1;
	}

	get formatted_bookies(): string {
		if (this.bookies) {
			return this.bookies
				.split(",")
				.map((bookie) => {
					if (bookie.includes("_")) {
						return `${bookie.split("_")[1].substring(0, 3)} (${bookie.split("_")[0]})`;
					} else {
						return bookie;
					}
				})
				.join("\n");
		} else {
			return "-";
		}
	}

	get isRunning(): boolean {
		if (this.server) {
			return this.selectedSets.filter((selected) => this.haltedSets.indexOf(selected) === -1).length > 0;
		} else {
			return false;
		}
	}

	get league_factor_formatted(): string {
		let result = "-";

		result = this.factor_league;
		if (result) {
			result = "f/" + parseFloat(result).toFixed(2);
		}

		this._league_factor_formatted = result;
		return result;
	}

	get factor_computed(): string {
		let result = "-";

		result = this.factor_league;
		if (this.factor) {
			result = this.factor;
		} else {
			if (this.factor_match) {
				result = (parseFloat(this.factor_league) * parseFloat(this.factor_match)).toString();
			}
		}

		if (result) {
			result = "f/" + parseFloat(result).toFixed(2);
		}

		this._factor_computed = result;
		return result;
	}

	public update(data) {
		Object.keys(data).forEach((property) => {
			if (property === "coverage") {
				if (data[property] && data[property] != "0") {
					this[property] = data[property];
				}
			} else {
				if (property !== "liabilities") {
					if (property === "haltedSets" || property === "warningSets") {
						if (data[property]) {
							this[property] = data[property];
						} else {
							this[property] = [];
						}
					} else {
						this[property] = data[property];
					}
				}
			}
		});
	}
}
