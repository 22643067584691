<div class="wrapper">
	<h3 mat-dialog-title
		cdkDrag
		cdkDragRootElement=".cdk-overlay-pane"
		cdkDragHandle>
		<span>Bet Info</span>
	</h3>
	<mat-dialog-content>
		<div class="content">
			<div *ngIf="info">
				<table mat-table
					[dataSource]="info"
					[class.dark-theme]="darkTheme"
					class="alternate">

					<ng-container matColumnDef="key">
					<td mat-cell *matCellDef="let bet">{{bet.key}}</td>
					</ng-container>

					<ng-container matColumnDef="value">
					<td mat-cell *matCellDef="let bet" [innerHtml]="bet.value"></td>
					</ng-container>

					<tr mat-row *matRowDef="let row; columns: ['key', 'value'];"></tr>
				</table>
			</div>
			<div *ngIf="!info">{{message}}</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<div class="buttons">
			<button mat-button mat-dialog-close mat-raised-button>Close</button>
		</div>
	</mat-dialog-actions>
</div>
