import { MarketType } from "../../home/models";

export class ExchangeSet {
	public code: string;
	public name: string;
	public short: string;
	public marketTypes: MarketType[];
	public accountId: number;

	constructor(set) {
		if (set != null) {
			this.code = set.code;
			this.name = set.name;
			this.short = set.short;
			this.marketTypes = set.marketTypes;
			this.accountId = set.accountId || 0;
		}
	}

	static isOUSet(setCode: string): boolean {
		const ouSets = [
			"4e1d906a66a0848304000005",
			"4fdf23ad66a084ac50000378",
			"50ffa91dcf46094185f12b45",
			"5c4eca0ba94869ac086852b1",
			"5229765757ad18e60d000002",
			"6527e020d2aad8e1fafa178d",
			"65cf3d35acf12fee885eb9bf",
			"67f379c9c07960affa36d316",
		];

		return ouSets.includes(setCode);
	}

	static isHBSet(setCode: string): boolean {
		const ouSets = ["621e1675dbd03eda20d0f302", "64e73def573a33aeac213e67", "660bf83377ad8cba43bdc5c1"];

		return ouSets.includes(setCode);
	}

	static isBackEnabled(setCode: string): boolean {
		const backSets = ["4e1d906a66a0848304000005", "5c4eca0ba94869ac086852b1"];

		return backSets.includes(setCode);
	}
}
