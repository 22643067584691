import { ExchangeList } from "../../models";

export class Calculation {
	_id: string;
	timestamp: Date;
	timestamp_hours: string;
	area: string;
	selected_market: string;
	market: string;
	average_price_2: string;
	average_price_3: string;
	deduction_min_2: string;
	deduction_min_3: string;
	price_lay_2: string;
	price_lay_3: string;
	price_min_2: string;
	price_max_2: string;
	price_min_3: string;
	price_max_3: string;
	average_price_2_back: string;
	average_price_3_back: string;
	deduction_min_2_back: string;
	deduction_min_3_back: string;
	price_min_2_back: string;
	price_max_2_back: string;
	price_min_3_back: string;
	price_max_3_back: string;
	selected_market_balanced: string;
	average_price_2_balanced: string;
	average_price_3_balanced: string;
	deduction_min_2_balanced: string;
	deduction_min_3_balanced: string;
	price_min_2_balanced: string;
	price_max_2_balanced: string;
	price_min_3_balanced: string;
	price_max_3_balanced: string;
	bf_price_2: string;
	bf_price_3: string;
	goals: number;
	playtime: number;
	message: string;
	market_type: number;
	mtm: string;
	selected_market_ou: string;
	selected_market_cs: string;
	selection: string;
	deduction_min: string;
	deduction_max_back: string;
	deduction_min_back: string;
	avgPrice: string;
	drawBR: string;
	hundred_price: string;
	hundred_price_back: string;
	awccFactor: string;
	fork_min: string;
	fork_max: string;
	fork_max_org: string;
	fork_min_back: string;
	fork_max_back: string;
	betfair_back: string;
	betfair_back_stake: string;
	betfair_lay: string;
	betfair_lay_stake: string;
	betfair_back_depth: string;
	betfair_back_depth_stake: string;
	betfair_lay_depth: string;
	betfair_lay_depth_stake: string;
	betfair_back_increment: string;
	betfair_back_increment_stake: string;
	betfair_lay_increment: string;
	betfair_lay_increment_stake: string;
	betfair_lay_optimal: string;
	betfair_lay_optimal_stake: string;
	betfair_back_optimal: string;
	betfair_back_optimal_stake: string;
	betfair_lay_bets_removed: string;
	betfair_lay_bets_removed_stake: string;
	betfair_back_bets_removed: string;
	betfair_back_bets_removed_stake: string;
	price_lay: string;
	hashCode: number;
	price_min_secondary_2: string;
	price_max_secondary_2: string;
	price_min_secondary_3: string;
	price_max_secondary_3: string;
	tge: string;
	suppremacy: string;
	highTGEFactor: string;
	highTGEFactor_2: string;
	highTGEFactor_3: string;

	priceOptimalBack: string;
	priceOptimalLay: string;
	priceBetfairBack: string;
	priceBetfairLay: string;
	hundredPercentPrice: string;
	hundredPercentPriceCorrectedBack: string;
	hundredPercentPriceCorrectedLay: string;

	selected_price_lay: string;
	selected_price_back: string;
	liability_available_lay: string;
	liability_available_back: string;
	liability_required_lay: string;
	liability_required_back: string;
	liability_required_lay_inc: string;
	liability_required_back_inc: string;
	arbitrage: number;
	odds_pin_mollybet: string;
	odds_pin_eastbridge: string;
	odds_sp_pinnacle: string;
	odds_bm_isn: string;
	odds_be_isn: string;
	odds_bi_isn: string;
	fastest_feed: string;
	activity_wh: string;
	activity_mrt: string;
	activity_sb: string;
	activity_xy: string;
	activity_rbo: string;
	activity_rb: string;
	activity_bv: string;
	activity_bob: string;
	activity_prf: string;

	private _exchangeList: ExchangeList;
	private _marketTypeName: string;

	constructor(calculation: Calculation, exchangeList: ExchangeList) {
		this._id = calculation._id;
		this.timestamp = new Date(calculation.timestamp);
		this.timestamp_hours = calculation.timestamp_hours;
		this.area = calculation.area;
		this.hashCode = calculation.hashCode;

		if (typeof calculation.selected_market === "number") {
			calculation.selected_market = parseFloat(calculation.selected_market).toFixed(2);
		}
		this.selected_market = typeof calculation.selected_market === "string" ? calculation.selected_market : "-";

		if (typeof calculation.selected_market_balanced === "number") {
			calculation.selected_market_balanced = parseFloat(calculation.selected_market_balanced).toFixed(2);
		}
		this.selected_market_balanced =
			typeof calculation.selected_market_balanced === "string" ? calculation.selected_market_balanced : "-";

		if (typeof calculation.market === "number") {
			calculation.market = parseFloat(calculation.market).toFixed(1);
		}
		this.market = calculation.market || "-";

		this.average_price_2 = calculation.average_price_2 || "-";
		this.average_price_3 = calculation.average_price_3 || "-";
		this.deduction_min_2 = calculation.deduction_min_2 || "-";
		this.deduction_min_3 = calculation.deduction_min_3 || "-";
		this.price_lay_2 = calculation.price_lay_2 || "-";
		this.price_lay_3 = calculation.price_lay_3 || "-";
		this.price_min_2 = calculation.price_min_2 || "-";
		this.price_min_3 = calculation.price_min_3 || "-";
		this.price_max_2 = calculation.price_max_2 || "-";
		this.price_max_3 = calculation.price_max_3 || "-";

		this.average_price_2_back = calculation.average_price_2_back || "-";
		this.average_price_3_back = calculation.average_price_3_back || "-";
		this.deduction_min_2_back = calculation.deduction_min_2_back || "-";
		this.deduction_min_3_back = calculation.deduction_min_3_back || "-";
		this.price_min_2_back = calculation.price_min_2_back || "-";
		this.price_min_3_back = calculation.price_min_3_back || "-";
		this.price_max_2_back = calculation.price_max_2_back || "-";
		this.price_max_3_back = calculation.price_max_3_back || "-";

		this.selected_market_balanced = calculation.selected_market_balanced || "-";
		this.average_price_2_balanced = calculation.average_price_2_balanced || "-";
		this.average_price_3_balanced = calculation.average_price_3_balanced || "-";
		this.deduction_min_2_balanced = calculation.deduction_min_2_balanced || "-";
		this.deduction_min_3_balanced = calculation.deduction_min_3_balanced || "-";
		this.price_min_2_balanced = calculation.price_min_2_balanced || "-";
		this.price_min_3_balanced = calculation.price_min_3_balanced || "-";
		this.price_max_2_balanced = calculation.price_max_2_balanced || "-";
		this.price_max_3_balanced = calculation.price_max_3_balanced || "-";

		this.bf_price_2 = calculation.bf_price_2 || "-";
		this.bf_price_3 = calculation.bf_price_3 || "-";
		this.goals = calculation.goals;
		this.playtime = calculation.playtime;
		this.message = calculation.message;
		this.market_type = calculation.market_type;
		this.mtm = calculation.mtm;

		if (typeof calculation.selected_market_ou === "number") {
			calculation.selected_market_ou = parseFloat(calculation.selected_market_ou).toFixed(2);
		}
		this.selected_market_ou = calculation.selected_market_ou || "-";
		if (typeof calculation.selected_market_cs === "number") {
			calculation.selected_market_cs = parseFloat(calculation.selected_market_cs).toFixed(2);
		}
		this.selected_market_cs = calculation.selected_market_cs || "-";

		this.selection = calculation.selection || "-";
		this.deduction_min = calculation.deduction_min || "-";
		this.deduction_min_back = calculation.deduction_min_back || "-";
		this.deduction_max_back = calculation.deduction_max_back || "-";
		this.drawBR = calculation.drawBR || "-";
		this.avgPrice = calculation.avgPrice || "-";
		this.hundred_price = calculation.hundred_price || "-";
		this.hundred_price_back = calculation.hundred_price_back || "-";
		this.awccFactor = calculation.awccFactor || "-";
		this.fork_min = calculation.fork_min || "-";
		this.fork_max = calculation.fork_max || "-";
		this.fork_max_org = calculation.fork_max_org || "-";
		this.fork_min_back = calculation.fork_min_back || "-";
		this.fork_max_back = calculation.fork_max_back || "-";
		this.betfair_back = calculation.betfair_back || "-";
		this.betfair_back_stake = calculation.betfair_back_stake || "-";
		this.betfair_lay = calculation.betfair_lay || "-";
		this.betfair_lay_stake = calculation.betfair_lay_stake || "-";
		this.betfair_back_depth = calculation.betfair_back_depth || "-";
		this.betfair_back_depth_stake = calculation.betfair_back_depth_stake || "-";
		this.betfair_lay_depth = calculation.betfair_lay_depth || "-";
		this.betfair_lay_depth_stake = calculation.betfair_lay_depth_stake || "-";
		this.betfair_back_increment = calculation.betfair_back_increment || "-";
		this.betfair_back_increment_stake = calculation.betfair_back_increment_stake || "-";
		this.betfair_lay_increment = calculation.betfair_lay_increment || "-";
		this.betfair_lay_increment_stake = calculation.betfair_lay_increment_stake || "-";
		this.betfair_lay_optimal = calculation.betfair_lay_optimal || "-";
		this.betfair_lay_optimal_stake = calculation.betfair_lay_optimal_stake || "-";
		this.betfair_back_optimal = calculation.betfair_back_optimal || "-";
		this.betfair_back_optimal_stake = calculation.betfair_back_optimal_stake || "-";
		this.betfair_lay_bets_removed = calculation.betfair_lay_bets_removed || "-";
		this.betfair_lay_bets_removed_stake = calculation.betfair_lay_bets_removed_stake || "-";
		this.betfair_back_bets_removed = calculation.betfair_back_bets_removed || "-";
		this.betfair_back_bets_removed_stake = calculation.betfair_back_bets_removed_stake || "-";
		this.price_lay = calculation.price_lay || "-";

		this.price_min_secondary_2 = calculation.price_min_secondary_2 || "-";
		this.price_max_secondary_2 = calculation.price_max_secondary_2 || "-";
		this.price_min_secondary_3 = calculation.price_min_secondary_3 || "-";
		this.price_max_secondary_3 = calculation.price_max_secondary_3 || "-";
		this.tge = calculation.tge || "";
		this.suppremacy = calculation.suppremacy || "";
		this.highTGEFactor = calculation.highTGEFactor || "";
		this.highTGEFactor_2 = calculation.highTGEFactor_2 || "";
		this.highTGEFactor_3 = calculation.highTGEFactor_3 || "";

		this.priceOptimalBack = calculation.priceOptimalBack || "-";
		this.priceOptimalLay = calculation.priceOptimalLay || "-";
		this.priceBetfairBack = calculation.priceBetfairBack || "-";
		this.priceBetfairLay = calculation.priceBetfairLay || "-";
		this.hundredPercentPrice = calculation.hundredPercentPrice || "-";
		this.hundredPercentPriceCorrectedBack = calculation.hundredPercentPriceCorrectedBack || "-";
		this.hundredPercentPriceCorrectedLay = calculation.hundredPercentPriceCorrectedLay || "-";

		this.selected_price_lay = calculation.selected_price_lay || "-";
		this.selected_price_back = calculation.selected_price_back || "-";
		this.liability_available_lay = calculation.liability_available_lay || "";
		this.liability_available_back = calculation.liability_available_back || "";
		this.liability_required_lay = calculation.liability_required_lay || "-";
		this.liability_required_back = calculation.liability_required_back || "-";
		this.liability_required_lay_inc = calculation.liability_required_lay_inc || "-";
		this.liability_required_back_inc = calculation.liability_required_back_inc || "-";
		this.arbitrage = calculation.arbitrage;
		this.odds_pin_mollybet = calculation.odds_pin_mollybet || "-";
		this.odds_pin_eastbridge = calculation.odds_pin_eastbridge || "-";
		this.odds_sp_pinnacle = calculation.odds_sp_pinnacle || "-";
		this.odds_bm_isn = calculation.odds_bm_isn || "-";
		this.odds_be_isn = calculation.odds_be_isn || "-";
		this.odds_bi_isn = calculation.odds_bi_isn || "-";
		this.fastest_feed = calculation.fastest_feed || "-";
		this.activity_wh = calculation.activity_wh || "-";
		this.activity_mrt = calculation.activity_mrt || "-";
		this.activity_sb = calculation.activity_sb || "-";
		this.activity_xy = calculation.activity_xy || "-";
		this.activity_rbo = calculation.activity_rbo || "-";
		this.activity_rb = calculation.activity_rb || "-";
		this.activity_bv = calculation.activity_bv || "-";
		this.activity_bob = calculation.activity_bob || "-";
		this.activity_prf = calculation.activity_prf || "-";

		this._exchangeList = exchangeList;
		this._marketTypeName = null;
	}

	get marketTypeName(): string {
		if (!this._marketTypeName) {
			const marketTypeName = this._exchangeList.translateMarketName(this.market_type);
			if (marketTypeName) {
				this._marketTypeName = marketTypeName;
			}
		}

		if (!this._marketTypeName) {
			return `[${this.market_type}]`;
		} else {
			return this._marketTypeName;
		}
	}

	get fullMarketName(): string {
		if (this.market === this.marketTypeName) {
			return this.marketTypeName;
		} else {
			return `${this.marketTypeName} ${this.market}`;
		}
	}

	get hasBfPrices(): boolean {
		if (this.selection || this.selection !== "-") {
			return (this.betfair_back && this.betfair_back !== "-") || (this.betfair_lay && this.betfair_lay !== "-");
		} else {
			return false;
		}
	}
}
