import { Calculation } from "./calculation";

export class BFPricesSelection {
	name: string;
	back: number;
	backStake: number;
	lay: number;
	layStake: number;
	backIncr: number;
	backIncrStake: number;
	layIncr: number;
	layIncrStake: number;
	backDepth: number;
	backDepthStake: number;
	layDepth: number;
	layDepthStake: number;
	layOptimal: number;
	layOptimalStake: number;
	backOptimal: number;
	backOptimalStake: number;
	layBetsRemoved: number;
	layBetsRemovedStake: number;
	backBetsRemoved: number;
	backBetsRemovedStake: number;
	totals: boolean;
	priceOptimalBack: string;
	priceOptimalLay: string;
	priceBetfairBack: string;
	priceBetfairLay: string;
	hundredPercentPrice: number;
	hundredPercentPriceCorrectedBack: number;
	hundredPercentPriceCorrectedLay: number;

	constructor(data: Calculation = null) {
		this.totals = false;

		if (data) {
			this.name = data.selection;
			this.update(data);
		}
	}

	update(data: Calculation) {
		this.back = +data.betfair_back || 0;
		this.backStake = +data.betfair_back_stake || 0;
		this.lay = +data.betfair_lay || 0;
		this.layStake = +data.betfair_lay_stake || 0;
		this.backIncr = +data.betfair_back_increment || 0;
		this.backIncrStake = +data.betfair_back_increment_stake || 0;
		this.layIncr = +data.betfair_lay_increment || 0;
		this.layIncrStake = +data.betfair_lay_increment_stake || 0;
		this.layOptimal = +data.betfair_lay_optimal || 0;
		this.layOptimalStake = +data.betfair_lay_optimal_stake || 0;
		this.backOptimal = +data.betfair_back_optimal || 0;
		this.backOptimalStake = +data.betfair_back_optimal_stake || 0;
		this.layBetsRemoved = +data.betfair_lay_bets_removed || 0;
		this.layBetsRemovedStake = +data.betfair_lay_bets_removed_stake || 0;
		this.backBetsRemoved = +data.betfair_back_bets_removed || 0;
		this.backBetsRemovedStake = +data.betfair_back_bets_removed_stake || 0;
		this.backDepth = +data.betfair_back_depth || 0;
		this.backDepthStake = +data.betfair_back_depth_stake || 0;
		this.layDepth = +data.betfair_lay_depth || 0;
		this.layDepthStake = +data.betfair_lay_depth_stake || 0;
		this.hundredPercentPrice = +data.hundredPercentPrice || 0;
		this.hundredPercentPriceCorrectedBack = +data.hundredPercentPriceCorrectedBack || 0;
		this.hundredPercentPriceCorrectedLay = +data.hundredPercentPriceCorrectedLay || 0;
		this.priceOptimalBack = data.priceOptimalBack;
		this.priceOptimalLay = data.priceOptimalLay;
		this.priceBetfairBack = data.priceBetfairBack;
		this.priceBetfairLay = data.priceBetfairLay;
	}

	clear() {
		this.back = 0;
		this.backStake = 0;
		this.lay = 0;
		this.layStake = 0;
		this.backIncr = 0;
		this.backIncrStake = 0;
		this.layIncr = 0;
		this.layIncrStake = 0;
		this.backDepth = 0;
		this.backDepthStake = 0;
		this.layDepth = 0;
		this.layDepthStake = 0;
		this.layOptimal = 0;
		this.layOptimalStake = 0;
		this.backOptimal = 0;
		this.backOptimalStake = 0;
		this.hundredPercentPrice = 0;
		this.hundredPercentPriceCorrectedBack = 0;
		this.hundredPercentPriceCorrectedLay = 0;
		this.priceOptimalBack = "";
		this.priceOptimalLay = "";
		this.priceBetfairBack = "";
		this.priceBetfairLay = "";
	}
}
