import { Component, Input } from "@angular/core";

import { BaseTable, BaseTableColumn, BaseTableRow, TransactionSet } from "../../../models";

@Component({
	selector: "app-transactions-summary-set",
	templateUrl: "../summary.component.html",
	styleUrls: ["../summary.component.scss"],
})
export class TransactionsSummarySetComponent {
	data: BaseTable;

	@Input() darkTheme = false;
	@Input("data") set onData(transactions: TransactionSet) {
		this.data = new BaseTable();

		let placedSuccessTotal = 0;
		let placedErrorTotal = 0;
		let cancelledSuccessTotal = 0;
		let cancelledErrorTotal = 0;

		let headerRow = new BaseTableRow("header first-row");
		headerRow.columns.push(new BaseTableColumn("Hour", "separator left-align"));
		headerRow.columns.push(new BaseTableColumn("Placed", "separator centered", 4));
		headerRow.columns.push(new BaseTableColumn("Cancelled", "separator centered", 4));
		headerRow.columns.push(new BaseTableColumn("Totals", "centered", 2));
		this.data.rows.push(headerRow);

		headerRow = new BaseTableRow("header");
		headerRow.columns.push(new BaseTableColumn("", "separator left_align"));
		headerRow.columns.push(new BaseTableColumn("Success"));
		headerRow.columns.push(new BaseTableColumn("Error"));
		headerRow.columns.push(new BaseTableColumn("Totals"));
		headerRow.columns.push(new BaseTableColumn("Err%", "separator"));
		headerRow.columns.push(new BaseTableColumn("Success"));
		headerRow.columns.push(new BaseTableColumn("Error"));
		headerRow.columns.push(new BaseTableColumn("Totals"));
		headerRow.columns.push(new BaseTableColumn("Err%", "separator"));
		headerRow.columns.push(new BaseTableColumn("Requests"));
		headerRow.columns.push(new BaseTableColumn("Transactions"));
		this.data.rows.push(headerRow);

		for (let hour = 0; hour <= 23; hour++) {
			const formattedHour = hour.toString().padStart(2, "0") + ":00";

			let placedSuccess = 0;
			let placedError = 0;
			let cancelledSuccess = 0;
			let cancelledError = 0;
			let requests = 0;
			let trans = 0;

			const hourData = transactions.getHourData(formattedHour);
			if (hourData) {
				if ("place" in hourData.values) {
					placedSuccess = hourData.values["place"]["success"] || 0;
					placedError = hourData.values["place"]["error"] || 0;
				}
				if ("cancel" in hourData.values) {
					cancelledSuccess = hourData.values["cancel"]["success"] || 0;
					cancelledError = hourData.values["cancel"]["error"] || 0;
				}

				placedSuccessTotal += placedSuccess;
				placedErrorTotal += placedError;
				cancelledSuccessTotal += cancelledSuccess;
				cancelledErrorTotal += cancelledError;
				requests += placedSuccess + placedError + cancelledSuccess + cancelledError;
				trans += placedSuccess + placedError + cancelledError;
			}

			const placed = placedSuccess + placedError;
			const cancelled = cancelledSuccess + cancelledError;

			const dataRow = new BaseTableRow();
			dataRow.columns.push(new BaseTableColumn(formattedHour, "separator left-align"));
			dataRow.columns.push(new BaseTableColumn(placedSuccess, "transaction"));
			dataRow.columns.push(new BaseTableColumn(placedError, "transaction"));
			dataRow.columns.push(new BaseTableColumn(placed, ""));
			dataRow.columns.push(new BaseTableColumn(placed > 0 ? (placedError / placed) * 100 : 0, "separator"));
			dataRow.columns.push(new BaseTableColumn(cancelledSuccess, ""));
			dataRow.columns.push(new BaseTableColumn(cancelledError, "transaction"));
			dataRow.columns.push(new BaseTableColumn(cancelled, ""));
			dataRow.columns.push(new BaseTableColumn(cancelled > 0 ? (cancelledError / cancelled) * 100 : 0, "separator"));
			dataRow.columns.push(new BaseTableColumn(requests, "totals"));
			// dataRow.columns.push(new BaseTableColumn(trans, `totals transaction ${trans > 5000 ? "highlight" : ""}`));
			dataRow.columns.push(new BaseTableColumn(trans, `totals transaction`));
			this.data.rows.push(dataRow);
		}

		const placedTotal = placedSuccessTotal + placedErrorTotal;
		const cancelledTotal = cancelledSuccessTotal + cancelledErrorTotal;

		const totalsRow = new BaseTableRow("footer");
		totalsRow.columns.push(new BaseTableColumn("Totals", "separator left-align"));
		totalsRow.columns.push(new BaseTableColumn(placedSuccessTotal, "transaction"));
		totalsRow.columns.push(new BaseTableColumn(placedErrorTotal, "transaction"));
		totalsRow.columns.push(new BaseTableColumn(placedTotal, ""));
		totalsRow.columns.push(
			new BaseTableColumn(placedTotal > 0 ? (placedErrorTotal / placedTotal) * 100 : 0, "separator")
		);
		totalsRow.columns.push(new BaseTableColumn(cancelledSuccessTotal, ""));
		totalsRow.columns.push(new BaseTableColumn(cancelledErrorTotal, "transaction"));
		totalsRow.columns.push(new BaseTableColumn(cancelledTotal, ""));
		totalsRow.columns.push(
			new BaseTableColumn(cancelledTotal > 0 ? (cancelledErrorTotal / cancelledTotal) * 100 : 0, "separator")
		);
		totalsRow.columns.push(
			new BaseTableColumn(placedSuccessTotal + placedErrorTotal + cancelledSuccessTotal + cancelledErrorTotal, "totals")
		);
		totalsRow.columns.push(
			new BaseTableColumn(placedSuccessTotal + placedErrorTotal + cancelledErrorTotal, "totals transaction")
		);
		this.data.rows.push(totalsRow);
	}
}
