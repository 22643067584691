import { Injectable } from "@angular/core";
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";
import { ApplicationService } from "src/app/shared/services";

@Injectable()
export class DuoGuardService implements CanActivate {
	constructor(
		private authService: AuthService,
		private router: Router,
		private applicationService: ApplicationService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve) => {
			this.authService.verifyCode().subscribe(
				() => {
					this.applicationService.user.authenticated = true;
					resolve(true);
				},
				() => {
					this.authService.signRequest().subscribe((response: Record<string, unknown>) => {
						this.router.navigate(["/auth/duo"], {
							queryParams: {
								returnUrl: state.url,
								signRequest: response.signRequest,
							},
						});
						resolve(false);
					});
				}
			);
		});
	}
}
