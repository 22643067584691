import { Market } from "./market";
import { MarketSelection } from "./marketSelection";

export class MarketType {
	public id: number;
	public name: string;
	public markets: Market[];
	public selections: MarketSelection[];

	constructor(marketType: MarketType) {
		this.id = marketType.id;
		this.name = marketType.name;
		this.markets = marketType.markets.map((market) => new Market(market));
		this.selections = marketType.selections.map((selection) => new MarketSelection(selection));
	}

	// constructor(id: number, name: string, markets: Market[], selections: MarketSelection[]) {
	// 	this.id = id;
	// 	this.name = name;
	// 	this.markets = markets;
	// 	this.selections = selections;
	// }
}
