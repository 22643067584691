import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { DateTime } from "luxon";

@Component({
	selector: "app-date-range",
	templateUrl: "./date-range.component.html",
	styleUrls: ["./date-range.component.scss"],
})
export class DateRangeComponent {
	@Input("fromDate") set setFromDate(value: Date) {
		this.fromDate.setValue(DateTime.fromJSDate(value).startOf("day").toJSDate());
	}
	@Input("toDate") set setToDate(value: Date) {
		this.toDate.setValue(DateTime.fromJSDate(value).startOf("day").toJSDate());
	}
	@Output() onChange = new EventEmitter<[Date, Date]>();

	public fromDate = new UntypedFormControl(DateTime.now().toJSDate());
	public toDate = new UntypedFormControl(DateTime.now().toJSDate());

	changeFromDate(event: MatDatepickerInputEvent<Date>) {
		if (event.value) {
			this.fromDate.setValue(DateTime.fromJSDate(event.value).startOf("day").toJSDate());
			if (this.fromDate.value > this.toDate.value) {
				this.toDate.setValue(this.fromDate.value);
			}
			this.onChange.emit([this.fromDate.value, this.toDate.value]);
		}
	}

	changeToDate(event: MatDatepickerInputEvent<Date>) {
		if (event.value) {
			this.toDate.setValue(DateTime.fromJSDate(event.value).startOf("day").toJSDate());
			if (this.toDate.value < this.fromDate.value) {
				this.fromDate.setValue(this.toDate.value);
			}
			this.onChange.emit([this.fromDate.value, this.toDate.value]);
		}
	}
}
