import { OddsLine } from "./oddsLine";

export class OddsMarket {
	id: number;
	name: string;
	lines: OddsLine[];

	constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
		this.lines = new Array<OddsLine>();
	}

	get lineCount(): number {
		return this.lines.length;
	}

	add(odd: OddsLine) {
		const index = this.lines.findIndex((entry) => entry.id === odd.id);
		if (index > -1) {
			this.lines[index] = new OddsLine(odd);
		} else {
			this.lines.push(new OddsLine(odd));
		}
		this.lines.sort(this.lineSorter);
	}

	update(odd) {
		const line = this.lines.find((entry) => entry.id === odd.id);
		if (line) {
			line.update(odd);
			if (line.bookies.length === 0) {
				this.lines.splice(this.lines.indexOf(line), 1);
			}
		} else {
			if (odd.changes && Object.keys(odd.changes).filter((field) => field.startsWith("bookie_")).length > 0) {
				Object.assign(odd, odd.changes);
				delete odd.changes;
				this.lines.push(new OddsLine(odd));
				this.lines.sort(this.lineSorter);
			}
		}
	}

	remove(oddId: string) {
		const index = this.lines.findIndex((entry) => entry.id === oddId);
		if (index > -1) {
			this.lines.splice(index, 1);
		}
	}

	hasLine(oddId: string): boolean {
		return this.lines.findIndex((entry) => entry.id === oddId) > -1;
	}

	refresh() {
		this.lines.forEach((line) => line.refresh());
	}

	private lineSorter(lh: OddsLine, rh: OddsLine): number {
		if (Number.isNaN(lh.market)) {
			return lh.market < rh.market ? -1 : 1;
		} else {
			return +lh.market < +rh.market ? -1 : 1;
		}
	}
}
