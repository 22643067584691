import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../environments/env";
import { KeyValuePair, ScraperLinkValidation } from "../../shared/models";

@Injectable()
export class ScraperService {
	protected url: string;
	private _scraperTypes: Array<KeyValuePair<string, string>>;

	constructor(protected http: HttpClient) {
		this.url = environment.BETROBOT_API_ENDPOINT + "/scraper";
		this._scraperTypes = new Array<KeyValuePair<string, string>>();
		this._scraperTypes.push(new KeyValuePair<string, string>("WH", "William Hill"));
		this._scraperTypes.push(new KeyValuePair<string, string>("MRT", "Marathon"));
		this._scraperTypes.push(new KeyValuePair<string, string>("1X", "OneXbet"));
		this._scraperTypes.push(new KeyValuePair<string, string>("RB", "Runningball"));
		this._scraperTypes.push(new KeyValuePair<string, string>("NB", "NoviBet"));
		this._scraperTypes.push(new KeyValuePair<string, string>("SB", "SkyBet"));
		this._scraperTypes.push(new KeyValuePair<string, string>("SP", "Sportative"));
		this._scraperTypes.push(new KeyValuePair<string, string>("MRS", "Marsbet"));
		this._scraperTypes.push(new KeyValuePair<string, string>("BV", "BetVictor"));
		this._scraperTypes.push(new KeyValuePair<string, string>("BOB", "Betobet"));
		this._scraperTypes.push(new KeyValuePair<string, string>("PRF", "Perform"));
	}

	get scraperTypes() {
		return this._scraperTypes;
	}

	overrideSP(id: number, sportativeId: string) {
		return this.http.post(`${this.url}/overrideSP`, { id, sportativeId });
	}

	overrideBB(matchId: number, globId: string) {
		return this.http.post(`${this.url}/overrideBB`, {
			id: matchId,
			globId: globId,
		});
	}

	addLinkValidation(entry: ScraperLinkValidation) {
		return this.http.post(`${this.url}/validation`, entry);
	}
}
