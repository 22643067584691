import { Dictionary } from "./dictionary";

export class FixtureUpdates {
	public server: string;
	public logging: string;
	public logging_betfair: string;
	public markets: Dictionary<string, boolean>;
	public sets: Dictionary<string, boolean>;
	public halt: Dictionary<string, boolean>;
	public restart: Array<string>;

	constructor(
		markets?: Dictionary<string, boolean>,
		sets?: Dictionary<string, boolean>,
		halt?: Dictionary<string, boolean>,
		restart?: Array<string>,
		server?: string,
		logging?: string,
		logging_betfair?: string,
	) {
		this.markets = markets || new Dictionary<string, boolean>();
		this.sets = sets || new Dictionary<string, boolean>();
		this.halt = halt || new Dictionary<string, boolean>();
		this.restart = restart || new Array<string>();
		this.server = server || null;
		this.logging = logging || null;
		this.logging_betfair = logging_betfair || null;
	}

	public addMarket(marketCode: string, selected: boolean) {
		this.markets.add(marketCode, selected);
	}

	public removeMarket(marketCode: string) {
		this.markets.remove(marketCode);
	}

	public addSet(setId: string, selected: boolean) {
		this.sets.add(setId, selected);
	}

	public removeSet(setId: string) {
		this.sets.remove(setId);
	}

	public addHalt(setId: string, halted: boolean) {
		this.halt.add(setId, halted);
	}

	public addRestart(setId: string) {
		this.restart.push(setId);
	}

	public removeHalt(setId: string) {
		this.halt.remove(setId);
	}
}
