import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { Constants } from "../../shared/constants";
import { CustomValidators } from "../../shared/validators";
import { UserService } from "../services";
import { User } from "../models";

@Component({
	selector: "app-users-edit-dialog",
	templateUrl: "edit-dialog.component.html",
	styleUrls: ["./edit-dialog.component.scss"],
})
export class UsersEditDialogComponent implements OnInit {
	public USER_NAME_MAX_LENGTH = Constants.USER_NAME_MAX_LENGTH;
	public MOBILE_MIN_LENGTH = Constants.MOBILE_MIN_LENGTH;
	public MOBILE_MAX_LENGTH = Constants.MOBILE_MAX_LENGTH;

	inputForm: UntypedFormGroup;

	public record: User;
	public isProfile: boolean;
	private otherNames: string[];
	private otherEmails: string[];

	constructor(
		private userService: UserService,
		private formBuilder: UntypedFormBuilder,
		private dialogRef: MatDialogRef<UsersEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {
		this.otherNames = data.otherNames;
		this.otherEmails = data.otherEmails;
		this.record = data.record;
		this.isProfile = data.isProfile || false;
	}

	ngOnInit() {
		this.inputForm = this.formBuilder.group({
			name: [
				this.record.name || "",
				Validators.compose([
					Validators.required,
					Validators.maxLength(this.USER_NAME_MAX_LENGTH),
					CustomValidators.existsIn(this.otherNames),
				]),
			],
			role: [this.record.role || "user"],
			email: [
				this.record.email || "",
				Validators.compose([Validators.required, Validators.email, CustomValidators.existsIn(this.otherEmails)]),
			],
			sms: [
				this.record.sms || "",
				Validators.compose([
					Validators.required,
					Validators.minLength(this.MOBILE_MIN_LENGTH),
					Validators.maxLength(this.MOBILE_MAX_LENGTH),
					CustomValidators.mobile,
				]),
			],
			enable_email: [this.record.enable_email || false],
			enable_sms: [this.record.enable_sms || false],
		});
	}

	save(): void {
		if (this.inputForm.status === "VALID") {
			const user = new User(null);
			user._id = this.record._id;
			user.name = this.inputForm.controls.name.value;
			user.role = this.inputForm.controls.role.value;
			user.email = this.inputForm.controls.email.value;
			user.sms = this.inputForm.controls.sms.value;
			user.enable_email = this.inputForm.controls.enable_email.value ? 1 : 0;
			user.enable_sms = this.inputForm.controls.enable_sms.value ? 1 : 0;

			this.userService
				.updateUser(user, this.record._id)
				.toPromise()
				.then(() => {
					this.dialogRef.close(new User(user));
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}
}
