import { DateTime } from "luxon";

export class FixtureNote {
	public _id: string;
	public matchId: number;
	public user: string;
	public note: string;
	public timestamp: Date;

	constructor(source: FixtureNote = null) {
		if (source) {
			this._id = source._id;
			this.matchId = source.matchId;
			this.user = source.user;
			this.note = source.note;
			this.timestamp = DateTime.fromSeconds(+source.timestamp).toJSDate();
		}
	}

	get type(): string {
		return "Custom note";
	}

	get processed(): Date {
		return this.timestamp;
	}
}
