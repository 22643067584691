import { MarketType } from ".";

export class FixtureFollowedSet {
	private selectedMarkets: MarketType[];
	private allMarkets: MarketType[];
	private _status: string;
	private missingMarkets: string[];

	code: string;
	name: string;
	halted: boolean;
	warning: boolean;

	constructor(code: string, name: string, allMarketTypes: MarketType[], selectedMarketTypes: MarketType[]) {
		this.code = code;
		this.name = name;
		this.halted = false;
		this.warning = false;
		this.allMarkets = allMarketTypes;
		this.selectedMarkets = selectedMarketTypes;

		this.missingMarkets = new Array<string>();

		this.computeStatus();
	}

	private computeStatus() {
		if (this.selectedMarkets.length === 0) {
			this._status = "none";
		} else {
			this.allMarkets.forEach((marketType) => {
				const selectedMarketType = this.selectedMarkets.find((sm) => sm.id === marketType.id);
				if (selectedMarketType) {
					this.missingMarkets = this.missingMarkets.concat(
						marketType.markets
							.filter((market) => selectedMarketType.markets.find((sm) => sm.code === market.code) === undefined)
							.map((market) => (marketType.name === market.name ? market.name : marketType.name + ":" + market.name))
					);
				} else {
					this.missingMarkets = this.missingMarkets.concat(
						marketType.markets.map((market) =>
							marketType.name === market.name ? market.name : marketType.name + ":" + market.name
						)
					);
				}
			});

			this.missingMarkets = this.missingMarkets.filter((m) => !m.startsWith("OUET"));
			this._status = this.missingMarkets.length > 0 ? "partial" : "all";
		}
	}

	get status(): string {
		return this._status;
	}

	get markets(): string[] {
		return this.selectedMarkets.map((marketType) => marketType.name);
	}

	get tooltip(): string {
		if (this.halted) {
			return this.name + " stopped";
		} else if (this.warning) {
			return "Liability reached";
		} else if (this.status === "none") {
			return "No markets selected for " + this.name;
		} else if (this.status === "partial") {
			return "Following markets are not selected:\n\n" + this.missingMarkets.sort().join("\n");
		}
	}

	get SetNo(): number {
		try {
			return Number.parseFloat(this.name.toLowerCase().replace("s", ""));
		} catch (err) {
			return 0;
		}
	}
}
