export class ScraperLinkValidation {
	_id: string;
	string1: string;
	string2: string;
	match: boolean;

	constructor(data: ScraperLinkValidation = null) {
		if (data) {
			this._id = data._id;
			this.string1 = data.string1;
			this.string2 = data.string2;
			this.match = data.match;
		} else {
			this.match = false;
		}
	}
}
