import { Injectable } from "@angular/core";
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";

@Injectable()
export class CodeGuardService implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve) => {
			this.authService.verifyCode().subscribe(
				() => {
					resolve(true);
				},
				() => {
					this.router.navigate(["/auth/code"], {
						queryParams: { returnUrl: state.url },
					});
					resolve(false);
				}
			);
		});
	}
}
