import { Bet as SharedBet } from "../../models";

export class Bet extends SharedBet {
	areaColor: string;
	activity: string;

	constructor(bet: Bet) {
		super(bet);

		this.areaColor = bet.areaColor;
		this.activity = bet.activity;
	}
}
