import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { PipesModule } from "../pipes.module";
import { MaterialModule } from "../material.module";
import { UsersRoutingModule } from "./users-routing.module";

import { UserService } from "./services";
import { UsersComponent } from "./users.component";
import { UsersEditDialogComponent } from "./edit-dialog/edit-dialog.component";
import { UsersConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MaterialModule, PipesModule, UsersRoutingModule],
	providers: [UserService],
	declarations: [UsersComponent, UsersEditDialogComponent, UsersConfirmDialogComponent],
})
export class UsersModule {}
