import { DateTime } from "luxon";

export class AlertSummary {
	private timestamp: number;
	private counts: { [name: string]: number } = {};

	get date(): Date {
		return DateTime.fromSeconds(this.timestamp).startOf("day").toJSDate();
	}

	get week(): Date {
		return DateTime.fromSeconds(this.timestamp).startOf("week").toJSDate();
	}

	get month(): Date {
		return DateTime.fromSeconds(this.timestamp).startOf("month").toJSDate();
	}

	constructor(timestamp: number, counts) {
		this.timestamp = timestamp;
		for (const [key, value] of Object.entries(counts)) {
			this.counts[key] = value as number;
		}
	}

	getValue(alertType: string): number {
		return this.counts[alertType] || 0;
	}
}
