export class Bet {
	id: string;
	sport: string;
	betId: number;
	setId: string;
	setNr: string;
	mDate: Date;
	score: string;
	selection: string;
	market: string;
	marketType: string;
	minute: string;
	mMinute: string;
	mPrice: number;
	price: number;
	mStake: number;
	fork: boolean;
	preemptive: boolean;
	bspBet: boolean;
	preBspBet: boolean;
	type: string;
	lateSuspension: boolean;
	secondary: boolean;
	rbDataPlaced;
	rbData;
	raceStatusMatched: string;
	crossmatchYellow: boolean;
	bfiswin: number;
	matchId: number;
	handicap: number;

	bookiePricesCS: string;
	bookiePrices1X2: string;
	bookiePricesBtts: string;
	bookiePricesAHC: string;
	bookiePricesOUSelected: string;
	bookiePricesOUMarket: string;
	bookiePricesOUSet8: string;
	bookiePricesOU: string;

	hundredPrices1X2: string;
	greenOut100Race: number;
	greenOut100Runner: number;

	rcard: boolean;
	penalty: boolean;
	eh: boolean;
	var: boolean;
	betstop: boolean;
	injury: boolean;
	substitution: boolean;

	private _formatted_selection: string;

	constructor(bet: Bet) {
		this.id = bet.id;
		this.sport = bet.sport;
		this.betId = bet.betId;
		this.setId = bet.setId;
		this.matchId = bet.matchId || null;
		this.setNr = bet.setNr || null;

		if (bet.mDate) {
			const dt = +bet.mDate;
			if (Math.trunc(dt).toString().length > 10) {
				this.mDate = new Date(dt);
			} else {
				this.mDate = new Date(dt * 1000);
			}
		}

		this.score = bet.score;
		this.selection = bet.selection;
		this.market = bet.market;
		this.marketType = bet.marketType;
		this.minute = bet.minute;
		this.mPrice = bet.mPrice;
		this.price = bet.price;
		this.mStake = bet.mStake;
		this.fork = String(bet.fork) === "true" || String(bet.fork) === "1";
		this.preemptive = String(bet.preemptive) === "true" || String(bet.preemptive) === "1";
		this.bspBet = String(bet.bspBet) === "true" || String(bet.bspBet) === "1";
		this.preBspBet = String(bet.preBspBet) === "true" || String(bet.preBspBet) === "1";
		this.secondary = String(bet.secondary) === "true" || String(bet.secondary) === "1";
		this.type = bet.type;
		this.lateSuspension = bet.lateSuspension;
		this.mMinute = bet.mMinute;
		this.rbDataPlaced = bet.rbDataPlaced;
		this.rbData = bet.rbData;
		this.raceStatusMatched = bet.raceStatusMatched || "";
		this.crossmatchYellow = String(bet.crossmatchYellow) === "true" || String(bet.crossmatchYellow) === "1";
		this.bfiswin = bet.bfiswin;
		this.handicap = bet.handicap;

		this.bookiePricesCS = bet.bookiePricesCS;
		this.bookiePrices1X2 = bet.bookiePrices1X2;
		this.bookiePricesBtts = bet.bookiePricesBtts;
		this.bookiePricesAHC = bet.bookiePricesAHC;
		this.bookiePricesOUSelected = bet.bookiePricesOUSelected;
		this.bookiePricesOUMarket = bet.bookiePricesOUMarket;
		this.bookiePricesOUSet8 = bet.bookiePricesOUSet8;
		this.bookiePricesOU = bet.bookiePricesOU;

		this.hundredPrices1X2 = bet.hundredPrices1X2;
		this.greenOut100Race = bet.greenOut100Race;
		this.greenOut100Runner = bet.greenOut100Runner;

		this.rcard = bet.rcard;
		this.penalty = bet.penalty;
		this.eh = bet.eh;
		this.var = bet.var;
		this.betstop = bet.betstop;
		this.injury = bet.injury;
		this.substitution = bet.substitution;

		this._formatted_selection = null;
	}

	get highlightBet(): boolean {
		return this.eh || this.rcard || this.penalty || this.var || this.betstop || this.injury || this.substitution;
	}

	get formatted_selection(): string {
		if (!this._formatted_selection) {
			this._formatted_selection = this.selection;

			if (this.marketType === "AHC") {
				this._formatted_selection += ` (${this.handicap})`;
			} else if (this.marketType === "OU" || this.marketType === "OUHT" || this.marketType === "OUET") {
				this._formatted_selection += ` (${this.marketType} ${this.market})`;
			} else if (this.marketType === "CS" || this.marketType === "CSHT" || this.marketType === "1X2HT") {
				this._formatted_selection += ` (${this.marketType})`;
			} else if (
				this.marketType === "WIN" ||
				this.marketType === "PLACE_2" ||
				this.marketType === "PLACE_3" ||
				this.marketType === "PLACE_4" ||
				this.marketType === "FORECAST" ||
				this.marketType === "REV_FORECAST" ||
				this.marketType === "WITHOUT_FAV"
			) {
				let abv = this.marketType[0];
				const underscore = this.marketType.indexOf("_");
				if (underscore > -1) {
					abv += this.marketType.substr(underscore + 1, 1);
				}
				this._formatted_selection += ` (${abv})`;
			} else if (this.marketType && this.marketType.startsWith("1X2")) {
				this._formatted_selection += " (1X2)";
			} else if (this.marketType && this.marketType.startsWith("OVER_UNDER")) {
				this._formatted_selection += " (OU)";
			} else if (this.marketType && this.marketType.startsWith("FIRST_HALF_GOALS")) {
				this._formatted_selection += " (OUHT)";
			} else if (this.marketType) {
				const abv = this.marketType
					.split("_")
					.map((item) => (Number.isNaN(+item) ? item.substr(0, 1).toUpperCase() : item))
					.join("");
				this._formatted_selection += ` (${abv})`;
			}
		}

		return this._formatted_selection;
	}

	get final_price(): number {
		if (this.mPrice) {
			return this.mPrice;
		} else {
			return this.price;
		}
	}

	get price_class(): string {
		if (this.mPrice && this.mPrice !== this.price) {
			return "red";
		} else {
			return "";
		}
	}

	get price_tooltip(): string {
		if (this.mPrice && this.mPrice !== this.price) {
			return `Price: ${this.price}`;
		} else {
			return "";
		}
	}

	get minutePlaced(): string {
		return this.rbDataPlaced && this.rbDataPlaced.seconds ? this.convertToGameTime(this.rbDataPlaced.seconds) : this.minute;
	}

	get minuteMatched(): string {
		return this.rbData && this.rbData.seconds ? this.convertToGameTime(this.rbData.seconds) : this.mMinute;
	}

	get minuteDisplay(): string {
		return this.minuteMatched ? this.minuteMatched : this.minutePlaced;
	}

	get liability(): number {
		if (this.type) {
			if (this.type.toLowerCase().startsWith("b")) {
				return (this.mPrice - 1) * this.mStake; // showing payout instead of liability as per Joe's request 23/03/2021
			} else {
				return (this.mPrice - 1) * this.mStake;
			}
		}
	}

	get payout(): number {
		return this.mPrice * this.mStake;
	}

	private convertToGameTime(seconds: number): string {
		if (seconds || seconds === 0) {
			return (
				Math.trunc(seconds / 60)
					.toString()
					.padStart(2, "0") +
				":" +
				(seconds % 60).toString().padStart(2, "0")
			);
		} else {
			return null;
		}
	}
}
