<h3 mat-dialog-title
	cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle>
	<span>Amazon Servers</span>
</h3>
<mat-dialog-content>
	<div class="server-selection">
		<select [value]="selectedServer" (change)="onServerSelection($event)" size="7" class="listbox">
			<option *ngFor="let server of servers" [value]="server[0]">{{ server[0] }}</option>
		</select>
	</div>
	<!-- <mat-form-field>
		<mat-label>Server</mat-label>
		<mat-select (selectionChange)="onServerSelection($event)" [(value)]="selectedServer">
			<mat-option *ngFor="let server of servers" [value]="server.key">{{ server.key }}</mat-option>
		</mat-select>
	</mat-form-field> -->
	<div class="server-status">
		<div class="server-selected">
			<h1>{{selectedServer}}</h1>
			<mat-icon class="link" *ngIf="hasLink" matTooltip="Open in new window" (click)="open()">open_in_browser</mat-icon>
		</div>
		<div class="status">
			{{status.Name}}
			<span *ngIf="status.Code === 16 && status.RunningTime"><br/>{{status.RunningTime}}</span>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button mat-raised-button *ngIf="status.Code===80" [disabled]="disabled" class="btnStart" (click)="start()">Start</button>
	<button mat-button mat-raised-button *ngIf="status.Code!==80" [disabled]="disabled" class="btnStop" (click)="stop()">Stop</button>
</mat-dialog-actions>
