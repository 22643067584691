import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { User } from "./models";
import { ApplicationService, UserService, AlertService } from "./services";
import { UsersEditDialogComponent } from "./edit-dialog/edit-dialog.component";
import { UsersConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";

@Component({
	selector: "app-users",
	templateUrl: "./users.component.html",
	styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;

	dataSource: MatTableDataSource<User>;
	loading = false;

	columnsToDisplay = ["actions", "name", "role", "email", "sms", "enable_email", "enable_sms", "spacer"];

	constructor(
		private alertService: AlertService,
		private applicationService: ApplicationService,
		private userService: UserService,
		public dialog: MatDialog
	) {}

	ngOnInit() {
		this.loadData();
	}

	get darkTheme(): boolean {
		return this.applicationService.darkTheme;
	}

	private handleError(error) {
		this.loading = false;

		if (error.message) {
			this.alertService.alertError(error.message);
		} else {
			this.alertService.alertError(error);
		}
	}

	loadData() {
		this.loading = true;
		this.userService
			.getUsers()
			.toPromise()
			.then((data) => this.handleData(data))
			.catch((error) => this.handleError(error));
	}

	private handleData(data: User[]) {
		this.dataSource = new MatTableDataSource(data);
		setTimeout(() => {
			this.dataSource.sort = this.sort;
		});
		this.loading = false;
	}

	edit(record: User) {
		const dlg = this.dialog.open(UsersEditDialogComponent, {
			data: {
				record,
				otherNames: this.dataSource.data.filter((user) => user._id !== record._id).map((user) => user.name),
				otherEmails: this.dataSource.data.filter((user) => user._id !== record._id).map((user) => user.email),
			},
			disableClose: false,
		});
		dlg.afterClosed().subscribe((response: User) => {
			if (response) {
				this.loadData();
				if (response._id === this.applicationService.user._id) {
					this.applicationService.setUser(response);
				}
			}
		});
	}

	delete(record: User) {
		const dlg = this.dialog.open(UsersConfirmDialogComponent, {
			data: record,
			disableClose: false,
		});
		dlg.afterClosed().subscribe((response) => {
			if (response) {
				this.loadData();
			}
		});
	}
}
