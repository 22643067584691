export class KeyValuePair<T1, T2> {
	public key: T1;
	public value: T2;

	static keySorter(a: KeyValuePair<unknown, unknown>, b: KeyValuePair<unknown, unknown>): number {
		return a.key === b.key ? 0 : +(a.key > b.key) || -1;
	}

	static valueSorter(a: KeyValuePair<unknown, unknown>, b: KeyValuePair<unknown, unknown>): number {
		return a.value === b.value ? 0 : +(a.value > b.value) || -1;
	}

	constructor(key: T1, value: T2) {
		this.key = key;
		this.value = value;
	}
}
