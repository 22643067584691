import { Component, Input } from "@angular/core";

import { BaseTable, BaseTableColumn, BaseTableRow, TransactionDate } from "../../../models";

@Component({
	selector: "app-transactions-summary-hours",
	templateUrl: "../summary.component.html",
	styleUrls: ["../summary.component.scss"],
})
export class TransactionsSummaryHoursComponent {
	data: BaseTable;

	@Input() darkTheme = false;
	@Input("data") set onData(transactions: TransactionDate) {
		this.data = new BaseTable();

		const columnTotals = [];

		const headerRow = new BaseTableRow("header");
		headerRow.columns.push(new BaseTableColumn("Hour", "separator left-align"));
		for (const set of transactions.data) {
			headerRow.columns.push(new BaseTableColumn(set.setName));
			columnTotals.push(0);
		}
		headerRow.columns[headerRow.columns.length - 1].cssClass = "separator";
		headerRow.columns.push(new BaseTableColumn("Totals"));
		this.data.rows.push(headerRow);

		for (let hour = 0; hour <= 23; hour++) {
			const formattedHour = hour.toString().padStart(2, "0") + ":00";
			let rowTotal = 0;

			const dataRow = new BaseTableRow();
			dataRow.columns.push(new BaseTableColumn(formattedHour, "separator left-align"));

			let index = 0;
			for (const set of transactions.data) {
				let value = 0;

				const hourData = set.getHourData(formattedHour);
				if (hourData) {
					if ("place" in hourData.values) {
						if ("success" in hourData.values["place"]) {
							value += hourData.values["place"]["success"];
						}
						if ("error" in hourData.values["place"]) {
							value += hourData.values["place"]["error"];
						}
					}
					if ("cancel" in hourData.values) {
						// if ("success" in hourData.values["cancel"]) {
						// 	value += hourData.values["cancel"]["success"];
						// }
						if ("error" in hourData.values["cancel"]) {
							value += hourData.values["cancel"]["error"];
						}
					}

					dataRow.columns.push(new BaseTableColumn(value));
					rowTotal += value;
					columnTotals[index] += value;
				} else {
					dataRow.columns.push(new BaseTableColumn(0));
				}
				index++;
			}

			dataRow.columns[dataRow.columns.length - 1].cssClass = "separator";
			// dataRow.columns.push(new BaseTableColumn(rowTotal, `totals ${rowTotal > 5000 ? "highlight" : ""}`));
			dataRow.columns.push(new BaseTableColumn(rowTotal, `totals`));
			this.data.rows.push(dataRow);
		}

		let grandTotal = 0;
		const totalsRow = new BaseTableRow("footer");
		totalsRow.columns.push(new BaseTableColumn("Totals", "separator left-align"));
		for (const value of columnTotals) {
			totalsRow.columns.push(new BaseTableColumn(value));
			grandTotal += value;
		}
		totalsRow.columns[totalsRow.columns.length - 1].cssClass = "separator";
		totalsRow.columns.push(new BaseTableColumn(grandTotal, "totals"));
		this.data.rows.push(totalsRow);
	}
}
