import {
	Validators,
	AbstractControl,
	ValidatorFn,
	UntypedFormGroup,
	UntypedFormControl,
	FormGroup,
	ValidationErrors,
} from "@angular/forms";

export class CustomValidators {
	static get mobile() {
		return Validators.pattern("^\\+[0-9]*$");
	}

	static existsIn(list: string[]): ValidatorFn {
		return (control: AbstractControl) => {
			if (list.includes(control.value)) {
				return { existsIn: { valid: false } };
			}
			return null;
		};
	}

	static identical(a: string, b: string): ValidatorFn {
		return (group: UntypedFormGroup): { [key: string]: unknown } => {
			const ac = group.controls[a];
			const bc = group.controls[b];

			if (ac.value !== bc.value) {
				return { identical: { valid: false } };
			}
			return null;
		};
	}

	static gameMinute(control: UntypedFormControl) {
		if (control.value && control.value.toLowerCase() != "ht" && isNaN(+control.value)) {
			return { gameMinute: true };
		}
		return null;
	}

	static range(a: string, b: string): ValidationErrors | null {
		return (group: FormGroup): { [key: string]: unknown } => {
			const ac = group.controls[a];
			const bc = group.controls[b];

			if (ac.value && bc.value && +ac.value > +bc.value) {
				const error = { range: { valid: false } };
				bc.setErrors(error);
				return error;
			}
			return null;
		};
	}
}
