import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AuthGuardService, AdminGuardService, DuoGuardService } from "./auth/services";

const routes: Routes = [
	{
		path: "auth",
		loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
			},
		],
	},
	{
		path: "matches",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./matches/matches.module").then((m) => m.MatchesModule),
			},
		],
	},
	{
		path: "settings",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule),
			},
		],
	},
	{
		path: "users",
		canActivate: [AdminGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./users/users.module").then((m) => m.UsersModule),
			},
		],
	},
	{
		path: "reports",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsModule),
			},
		],
	},
	{
		path: "ticker",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./ticker/ticker.module").then((m) => m.TickerModule),
			},
		],
	},
	{
		path: "horsebet",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./horsebet/horsebet.module").then((m) => m.HorsebetModule),
			},
		],
	},
	{
		path: "txodds-pushfeed",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./txodds-pushfeed/txodds-pushfeed.module").then((m) => m.TxoddsPushfeedModule),
			},
		],
	},
	{
		path: "betfair-historic-data",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () =>
					import("./betfair-historic-data/betfair-historic-data.module").then((m) => m.BetfairHistoricDataModule),
			},
		],
	},
	{
		path: "betfair-catalogue",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./betfair-catalogue/bf-catalogue.module").then((m) => m.BetfairCatalogueModule),
			},
		],
	},
	{
		path: "gold-finger-monitor",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./gold-finger/gold-finger.module").then((m) => m.GoldFingerModule),
			},
		],
	},
	{
		path: "betfair-raw-data",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./betfair-raw-data/betfair-raw-data.module").then((m) => m.BetfairRawDataModule),
			},
		],
	},
	{
		path: "scraper",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./scraper/scraper.module").then((m) => m.ScraperModule),
			},
		],
	},
	{
		path: "feeds-viewer",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./feeds-viewer/feeds-viewer.module").then((m) => m.FeedsViewerModule),
			},
		],
	},
	{
		path: "mobile",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./mobile-view/mobileView.module").then((m) => m.MobileViewPanelModule),
			},
		],
	},
	{
		path: "prematch-stats",
		canActivate: [AuthGuardService],
		children: [
			{
				path: "",
				canActivate: [DuoGuardService],
				loadChildren: () => import("./prematch-stats/prematch-stats.module").then((m) => m.PreMatchStatsModule),
			},
		],
	},
	{
		path: "**",
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
