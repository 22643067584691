export enum FilterStatusLevel {
	Info,
	Warning,
	Error,
}

export class FilterStatus {
	text: string;
	level: FilterStatusLevel;

	constructor(text: string, level: FilterStatusLevel) {
		this.text = text;
		this.level = level;
	}
}
