import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/env";
import { Dictionary, Exchange } from "../models";

@Injectable()
export class ExchangeService {
	private url: string;
	private exchangesList: Exchange[];
	private marketsDictionary: Dictionary<string, Market>;

	constructor(private http: HttpClient) {
		this.url = environment.BETROBOT_API_ENDPOINT + "/exchanges";

		this.getExchangeList();
	}

	getExchange(exchangeId: number): Exchange {
		if (this.exchangesList) {
			return this.exchangesList.find((exchange) => exchange.id === exchangeId);
		} else {
			return null;
		}
	}

	async getExchangeList(): Promise<Exchange[]> {
		if (this.exchangesList) {
			return new Promise<Exchange[]>((resolve) => resolve(this.exchangesList));
		} else {
			return this.getFollowing().toPromise();
		}
	}

	private getFollowing(): Observable<Array<Exchange>> {
		return this.http
			.get<Array<Exchange>>(this.url + "/following")
			.pipe(map((exchanges) => (this.exchangesList = exchanges.map((exchange) => new Exchange(exchange)))));
	}

	getMarketsDefinition(): Observable<Dictionary<string, Market>> {
		if (this.marketsDictionary) {
			return of(this.marketsDictionary);
		} else {
			return this.http.get(this.url + "/markets/definition").pipe(
				map((data: any[]) => {
					this.marketsDictionary = new Dictionary<string, Market>();
					for (const entry of data) {
						this.marketsDictionary.add(entry.code, {
							code: entry.code,
							name: entry.name,
							order: entry.order,
							selections: entry.selections,
						});
					}

					return this.marketsDictionary;
				})
			);
		}
	}
}

type Selection = {
	id: string;
	name: string;
	order: number;
	label?: string;
};

type Market = {
	code: string;
	name: string;
	short?: string;
	order: number;
	selections: Selection[];
};
