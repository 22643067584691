export class EventsSource {
	away_rcards: string;
	away_score: string;
	away_score_ht: string;
	away_ycards: string;
	home_rcards: string;
	home_score: string;
	home_score_ht: string;
	home_ycards: string;
	minute: string;
	period: string;
	source: string;
	goals: string;
	ts: number;

	constructor(source: EventsSource) {
		this.update(source);
	}

	update(source: EventsSource) {
		this.away_rcards = source.away_rcards;
		this.away_score = source.away_score;
		this.away_score_ht = source.away_score_ht;
		this.away_ycards = source.away_ycards;
		this.home_rcards = source.home_rcards;
		this.home_score = source.home_score;
		this.home_score_ht = source.home_score_ht;
		this.home_ycards = source.home_ycards;
		this.minute = source.minute;
		this.period = source.period;
		this.source = source.source;
		this.goals = source.goals;
		this.ts = source.ts;
	}

	get score(): string {
		if (this.home_score != null && this.away_score != null) {
			return `${this.home_score} - ${this.away_score}`;
		} else {
			if (this.goals != null) {
				return `${this.goals} goal(s)`;
			} else {
				return "";
			}
		}
	}

	get score_ht(): string {
		if (this.home_score_ht != null && this.away_score_ht != null) {
			return `${this.home_score_ht} - ${this.away_score_ht}`;
		} else {
			return "";
		}
	}

	get rcards(): string {
		if (this.home_rcards != null && this.away_rcards != null) {
			return `${this.home_rcards} - ${this.away_rcards}`;
		} else {
			return "";
		}
	}

	get ycards(): string {
		if (this.home_ycards != null && this.away_ycards != null) {
			return `${this.home_ycards} - ${this.away_ycards}`;
		} else {
			return "";
		}
	}
}
