import { KeyValuePair } from "../../../shared/models";

export class BookieNames {
	private _dictionary: KeyValuePair<string, string>[];

	constructor() {
		this._dictionary = new Array<KeyValuePair<string, string>>();
		this._dictionary.push(new KeyValuePair("pinnaclesports", "PIN"));
		this._dictionary.push(new KeyValuePair("pinnacle", "PIN"));
		this._dictionary.push(new KeyValuePair("pinnacle_HT", "PIN_HT"));
		this._dictionary.push(new KeyValuePair("ibcbet", "IBC"));
		this._dictionary.push(new KeyValuePair("singbet", "SNG"));
		this._dictionary.push(new KeyValuePair("sing_HT", "SNG_HT"));
		this._dictionary.push(new KeyValuePair("sbobet", "SBO"));
		this._dictionary.push(new KeyValuePair("sbo", "SBO"));
		this._dictionary.push(new KeyValuePair("sbo_HT", "SBO_HT"));
		this._dictionary.push(new KeyValuePair("betisn", "ISN"));
		this._dictionary.push(new KeyValuePair("isn", "ISN"));
		this._dictionary.push(new KeyValuePair("isn55", "ISN"));
		this._dictionary.push(new KeyValuePair("sing2", "SNG2"));
	}

	translate(bookieName: string): string {
		let name = bookieName;

		if (name) {
			const translation = this._dictionary.find((entry) => entry.key.toLowerCase() === bookieName.toLowerCase());
			if (translation) {
				name = translation.value;
			}
		}

		return name;
	}
}
