import { NgModule, Injectable } from "@angular/core";

import {
	NgxMatNativeDateModule,
	NgxMatDatetimePickerModule,
	NgxMatTimepickerModule,
	NGX_MAT_DATE_FORMATS,
	NgxMatDateAdapter,
} from "@angular-material-components/datetime-picker";

import { DragDropModule } from "@angular/cdk/drag-drop";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MatNativeDateModule,
	MatRippleModule,
	NativeDateAdapter,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatBadgeModule } from "@angular/material/badge";

const DATE_FORMATS = {
	parse: {
		dateInput: "DD-MM-YYYY",
	},
	display: {
		dateInput: "DD-MM-YYYY",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMMM YYYY",
	},
};

const DATE_TIME_FORMATS = {
	parse: {
		dateInput: "DD-MM-YYYY HH:mm:ss",
	},
	display: {
		dateInput: "DD-MM-YYYY HH:mm:ss",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMMM YYYY",
		enableMeridian: true,
	},
};

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
	parse(value: string | number, parseFormat): Date | null {
		if (value) {
			if (typeof value === "number") {
				return new Date(value);
			} else {
				const dateParts = this.parseDate(value);
				return new Date(dateParts.year, dateParts.month, dateParts.day);
			}
		} else {
			return null;
		}
	}

	format(date: Date, displayFormat): string {
		if (typeof displayFormat == "string") {
			const exploded = this.explodeDate(date);
			return displayFormat
				.replace("LL", `${exploded.day} ${this.getMonthNames("long")[exploded.month - 1]} ${exploded.year}`)
				.replace("YYYY", exploded.year.toString())
				.replace("MMMM", this.getMonthNames("long")[exploded.month - 1])
				.replace("MMM", this.getMonthNames("short")[exploded.month - 1])
				.replace("MM", this.to2digit(exploded.month))
				.replace("DD", this.to2digit(exploded.day));
		} else {
			console.log("Date format:", displayFormat);
			return date.toDateString();
		}
	}

	protected to2digit(n: number) {
		return ("00" + n).slice(-2);
	}

	protected explodeDate(date: Date) {
		return {
			year: date.getFullYear(),
			month: date.getMonth() + 1,
			day: date.getDate(),
		};
	}

	protected parseDate(date: string) {
		if (date.includes("/")) {
			const [day, month, year] = date.split("/");
			return {
				year: +year,
				month: +month - 1,
				day: +day,
			};
		} else if (date.includes("-")) {
			const [day, month, year] = date.split("-");
			return {
				year: +year,
				month: +month - 1,
				day: +day,
			};
		} else {
			throw new Error("Cannot parse date:" + date);
		}
	}
}

@Injectable()
export class CustomDateTimeAdapter extends CustomDateAdapter {
	getHour(date: Date): number {
		return date.getHours();
	}

	getMinute(date: Date): number {
		return date.getMinutes();
	}

	getSecond(date: Date): number {
		return date.getSeconds();
	}

	setHour(date: Date, value: number): void {
		date.setHours(value);
	}

	setMinute(date: Date, value: number): void {
		date.setMinutes(value);
	}

	setSecond(date: Date, value: number): void {
		date.setSeconds(value);
	}

	isSameTime(a: Date, b: Date): boolean {
		if (a && b) {
			const explodedTimeA = this.explodeTime(a);
			const explodedTimeB = this.explodeTime(b);

			return (
				explodedTimeA.hour == explodedTimeB.hour &&
				explodedTimeA.minute == explodedTimeB.minute &&
				explodedTimeA.second == explodedTimeB.second
			);
		} else {
			return !a && !b;
		}
	}

	copyTime(toDate: Date, fromDate: Date): void {
		const explodedTime = this.explodeTime(fromDate);
		toDate.setHours(explodedTime.hour);
		toDate.setMinutes(explodedTime.minute);
		toDate.setSeconds(explodedTime.second);
	}

	compareDateWithTime(first: Date, second: Date, showSeconds?: boolean): number {
		let explodedDate = this.explodeDate(first);
		let explodedTime = this.explodeTime(first);
		first = new Date(
			explodedDate.year,
			explodedDate.month,
			explodedDate.day,
			explodedTime.hour,
			explodedTime.minute,
			showSeconds ? explodedTime.second : 0
		);

		explodedDate = this.explodeDate(second);
		explodedTime = this.explodeTime(second);
		second = new Date(
			explodedDate.year,
			explodedDate.month,
			explodedDate.day,
			explodedTime.hour,
			explodedTime.minute,
			showSeconds ? explodedTime.second : 0
		);

		const f = +first;
		const s = +second;

		return f < s ? -1 : f > s ? 1 : 0;
	}

	setTimeByDefaultValues(date: Date, defaultTime: number[]): void {
		date.setHours(defaultTime[0]);
		date.setMinutes(defaultTime[1]);
		date.setSeconds(defaultTime[2]);
	}

	parse(value: string, parseFormat): Date | null {
		if (value) {
			if (typeof value === "number") {
				return new Date(value);
			} else {
				const [date, time] = value.split(" ");
				const dateParts = this.parseDate(date);
				const timeParts = this.parseTime(time);
				return new Date(
					dateParts.year,
					dateParts.month,
					dateParts.day,
					timeParts.hour,
					timeParts.minute,
					timeParts.second
				);
			}
		} else {
			return null;
		}
	}

	format(date: Date, displayFormat): string {
		if (typeof displayFormat == "string") {
			const explodedDate = this.explodeDate(date);
			const explodedTime = this.explodeTime(date);

			const y = explodedDate.year.toString();
			const m = this.to2digit(explodedDate.month);
			const d = this.to2digit(explodedDate.day);
			const h = this.to2digit(explodedTime.hour);
			const n = this.to2digit(explodedTime.minute);
			const s = this.to2digit(explodedTime.second);

			return displayFormat
				.replace("LL", `${d} ${this.getMonthNames("long")[explodedDate.month - 1]} ${y} ${h}:${n}`)
				.replace("YYYY", y)
				.replace("MMMM", this.getMonthNames("long")[explodedDate.month - 1])
				.replace("MMM", this.getMonthNames("short")[explodedDate.month - 1])
				.replace("MM", m)
				.replace("DD", d)
				.replace("HH", h)
				.replace("mm", n)
				.replace("ss", s);
		} else {
			console.log("DateTime format:", displayFormat);
			return date.toDateString();
		}
	}

	protected parseTime(time: string) {
		const [hour, minute, second] = time.split(":");
		return {
			hour: +hour,
			minute: +minute,
			second: +second || 0,
		};
	}

	protected explodeTime(date: Date) {
		return {
			hour: date.getHours(),
			minute: date.getMinutes(),
			second: date.getSeconds(),
		};
	}
}

@NgModule({
	imports: [
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatNativeDateModule,
		MatBadgeModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		NgxMatDatetimePickerModule,
		DragDropModule,
	],
	exports: [
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatBadgeModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		NgxMatDatetimePickerModule,
		DragDropModule,
	],
	providers: [
		{ provide: DateAdapter, useClass: CustomDateAdapter },
		{ provide: NgxMatDateAdapter, useClass: CustomDateTimeAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
		{ provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMATS },
	],
})
export class MaterialModule {}
