import { Component, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ApplicationService, LinkService } from "./shared/services";
import { DateTime, Interval } from "luxon";

@Component({
	selector: "app-amazon-server-dialog",
	templateUrl: "amazonServers-dialog.component.html",
	styleUrls: ["./amazonServers-dialog.component.scss"],
})
export class AmazonServersDialogComponent implements OnDestroy {
	public servers: Array<[string, string]>;
	public selectedServer: string;
	public status = {
		Code: 0,
		Name: "",
		LaunchTime: null,
		RunningTime: null,
	};
	public disabled = true;

	private refreshInterval = 5;
	private updateInterval;

	get hasLink(): boolean {
		if (this.servers && this.selectedServer) {
			const server = this.servers.find((entry) => entry[0] == this.selectedServer);
			return server && server[1] ? true : false;
		} else {
			return false;
		}
	}

	constructor(
		public dialogRef: MatDialogRef<AmazonServersDialogComponent>,
		private applicationService: ApplicationService,
		private linkService: LinkService
	) {
		this.servers = this.applicationService.awsServers;
		this.selectedServer = "Staging1";

		this.refresh();
	}

	ngOnDestroy() {
		this.reset();
	}

	onServerSelection(event) {
		this.selectedServer = event.target.value;
		this.refresh();
	}

	refresh() {
		this.reset();
		if (this.selectedServer) {
			this.updateStatus();
			this.statusMonitoring();
		}
	}

	start() {
		this.reset();

		this.applicationService
			.serverStart(this.selectedServer.toLowerCase())
			.toPromise()
			.catch((error) => {
				console.error(error);
			});

		// delay refresh to give time the amazon monitoring server to update
		setTimeout(this.statusMonitoring.bind(this), this.refreshInterval * 1000);
	}

	stop() {
		this.reset();

		this.applicationService
			.serverStop(this.selectedServer.toLowerCase())
			.toPromise()
			.catch((error) => {
				console.error(error);
			});

		// delay refresh to give time the amazon monitoring server to update
		setTimeout(this.statusMonitoring.bind(this), this.refreshInterval * 1000);
	}

	private updateStatus() {
		this.applicationService
			.serverStatus(this.selectedServer.toLowerCase())
			.toPromise()
			.then((response) => {
				Object.assign(this.status, response);
				if (this.status.LaunchTime) {
					const diff = Interval.fromDateTimes(DateTime.fromJSDate(this.status.LaunchTime), DateTime.now())
						.toDuration(["hours", "minutes", "seconds"])
						.toObject();
					this.status["RunningTime"] =
						diff.hours.toString().padStart(2, "0") +
						":" +
						diff.minutes.toString().padStart(2, "0") +
						":" +
						Math.trunc(diff.seconds).toString().padStart(2, "0");
				}
				this.disabled = !(this.status.Code === 16 || this.status.Code === 80);
			})
			.catch((error) => {
				this.status = {
					Code: -1,
					Name: "Unknown",
					LaunchTime: null,
					RunningTime: null,
				};
				this.disabled = true;
				console.error(error);
			});
	}

	open() {
		const url = this.servers.find((s) => s[0] === this.selectedServer)[1];
		if (url) {
			this.linkService.openLink("URL", url);
		}
	}

	private reset() {
		this.disabled = true;
		if (this.updateInterval) {
			clearInterval(this.updateInterval);
		}
	}

	private statusMonitoring() {
		this.updateInterval = setInterval(this.updateStatus.bind(this), this.refreshInterval * 1000);
	}
}
