import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ConfirmDialogComponent {
	caption: string;
}

@Component({
	selector: "app-confirm-dialog",
	templateUrl: "confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
	constructor(
		private dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogComponent
	) {}

	proceed(): void {
		this.dialogRef.close(true);
	}
}
