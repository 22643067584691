import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "split" })
export class SplitPipe implements PipeTransform {
	transform(value: string | string[]): string {
		if (!value) {
			return null;
		}

		if (Array.isArray(value)) {
			return value.join("\n");
		} else {
			return value.split(",").join("\n");
		}
	}
}
