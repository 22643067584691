export class OpenBet {
	betId: string;
	setId: string;
	setNr: number;
	date: Date;
	type: string;
	market: string;
	selection: string;
	price: number;
	stake: number;
	liability: number;
	status: number;
	secondary: number;
	crossmatch: number;
	bfPrice: number;
	hundredPrice: number;
	preemptive: number;

	constructor(bet: OpenBet) {
		this.betId = bet.betId || "";
		this.setId = bet.setId;
		this.setNr = bet.setNr;
		this.date = bet.date;
		this.type = bet.type;
		this.market = bet.market;
		this.selection = "" + bet.selection;
		this.price = bet.price;
		this.stake = bet.stake;
		this.liability = bet.liability;
		this.status = bet.status;
		this.secondary = bet.secondary;
		this.crossmatch = bet.crossmatch;
		this.preemptive = bet.preemptive;
		this.bfPrice = bet.bfPrice;
		this.hundredPrice = bet.hundredPrice;
	}

	get delayedCancellation(): boolean {
		return this.status === 4;
	}
}
