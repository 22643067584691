import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { MatDialog } from "@angular/material/dialog";
import { DateTime } from "luxon";

import { AuthService } from "./auth/services";
import { ApplicationService } from "./shared/services";
import { User } from "./shared/models";
import { UsersEditDialogComponent } from "./users/edit-dialog/edit-dialog.component";

@Component({
	selector: "app-user",
	templateUrl: "./user.component.html",
	styleUrls: ["./user.component.scss"],
})
export class UserComponent {
	get user(): User {
		return this.applicationService.user;
	}

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private authService: AuthService,
		private applicationService: ApplicationService,
		private cookieService: CookieService
	) {}

	logout() {
		this.authService
			.logout()
			.toPromise()
			.then(() => {
				this.router.navigate(["/auth/login"]);
			});
	}

	lightTheme() {
		this.cookieService.set("theme", "light", DateTime.now().plus({ year: 1 }).toJSDate(), "/");
		this.setDarkTheme(false);
	}

	darkTheme() {
		this.cookieService.set("theme", "dark", DateTime.now().plus({ year: 1 }).toJSDate(), "/");
		this.setDarkTheme(true);
	}

	userProfile() {
		const dlg = this.dialog.open(UsersEditDialogComponent, {
			data: {
				record: this.user,
				otherNames: [],
				otherEmails: [],
				isProfile: true,
			},
			disableClose: false,
		});
		dlg.afterClosed().subscribe((response) => {
			if (response) {
				this.applicationService.setUser(response);
			}
		});
	}

	private setDarkTheme(set: boolean) {
		if (set) {
			this.applicationService.setDarkTheme(true);
		} else {
			this.applicationService.setDarkTheme(false);
		}
	}
}
