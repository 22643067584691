import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "odds" })
export class OddsPipe implements PipeTransform {
	transform(value: number): string {
		let valueStr = "";
		try {
			if (typeof value !== "undefined" && value != null) {
				valueStr = value.toFixed(2);
			}
		} catch (ex) {
			console.log(ex);
			valueStr = "err";
		}

		let result = "";
		if (valueStr.endsWith(".00")) {
			result = valueStr.replace(".00", "");
		} else if (valueStr.indexOf(".") > -1 && valueStr.endsWith("0")) {
			result = valueStr.substr(0, valueStr.length - 1);
		} else {
			result = valueStr;
		}

		return result;
	}
}
