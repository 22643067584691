<h3 mat-dialog-title
	cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle>
	<span *ngIf="state === 'prompt'">Confirm</span>
	<span *ngIf="state === 'running'">Please wait...</span>
	<span *ngIf="state === 'success'"><mat-icon class="success">done</mat-icon>Success</span>
	<span *ngIf="state === 'error'"><mat-icon class="error">warning</mat-icon>Error</span>
</h3>
<mat-dialog-content>
	<span *ngIf="state === 'prompt' || state === 'running'">{{ data.prompt }}</span>
	<span *ngIf="state === 'success'">{{ data.message }}</span>
	<span *ngIf="state === 'error'">An error occurred</span>
</mat-dialog-content>
<mat-dialog-actions>
	<div *ngIf="state === 'prompt' || state === 'running'">
		<button mat-button mat-dialog-close [disabled]="state === 'running'" color="primary">No</button>
		<button mat-button [disabled]="state === 'running'" (click)="proceed()" color="primary" mat-raised-button>Yes</button>
	</div>
	<div *ngIf="state !== 'prompt' && state !== 'running'">
		<button mat-button mat-dialog-close mat-raised-button>OK</button>
	</div>
</mat-dialog-actions>
