export class Hundred {
	desc: string;
	home: number;
	away: number;
	draw: number;

	constructor(hundred: Hundred) {
		if (hundred) {
			this.desc = hundred.desc;
			this.home = hundred.home;
			this.away = hundred.away;
			this.draw = hundred.draw;
		}
	}

	get percentage(): number {
		const home = this.home ? (1 / this.home) * 100 : 0;
		const away = this.away ? (1 / this.away) * 100 : 0;
		const draw = this.draw ? (1 / this.draw) * 100 : 0;

		return home + away + draw;
	}
}
