import { Betcount } from "./betcount";

export class Balance extends Betcount {
	public balance: number;
	public exposure: number;
	public available: number;
	public exposureLimit: number;

	constructor(balance: Balance) {
		super(balance);

		this.balance = balance.balance || 0;
		this.exposure = balance.exposure || 0;
		this.available = balance.available || 0;
		this.exposureLimit = balance.exposureLimit || 0;
	}

	get exposurePercentage(): number {
		if (!isNaN(this.exposure)) {
			return Math.round((this.exposure / this.exposureLimit) * 100);
		} else {
			return 0;
		}
	}
}
