import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class LinkService {
	constructor(private router: Router) {}

	openLink(code: string, name: string) {
		let url = null;
		let eventId = null;
		let setId = null;
		let exchangeId = null;
		let uniqId = null;
		let sport = null;
		let raceId = null;
		let betfairEventId = null;
		let marketId = null;
		let subSport = null;

		switch (code) {
			case "URL":
				url = `${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair":
				url = `https://www.betfair.com/exchange/plus/football/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (special)":
				marketId = name.split(":")[0];
				subSport = name.split(":")[1];
				url = `https://www.betfair.com/exchange/plus/${subSport}/market/${marketId}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (tennis)":
				url = `https://www.betfair.com/exchange/plus/tennis/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (golf)":
				url = `https://www.betfair.com/exchange/plus/golf/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (cycling)":
				url = `https://www.betfair.com/exchange/plus/cycling/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (baseball)":
				url = `https://www.betfair.com/exchange/plus/baseball/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (basketball)":
				url = `https://www.betfair.com/exchange/plus/basketball/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (icehockey)":
				url = `https://www.betfair.com/exchange/plus/ice-hockey/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (americanfootball)":
				url = `https://www.betfair.com/exchange/plus/american-football/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (darts)":
				url = `https://www.betfair.com/exchange/plus/darts/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (cricket)":
				url = `https://www.betfair.com/exchange/plus/cricket/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (snooker)":
				url = `https://www.betfair.com/exchange/plus/snooker/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (politics)":
				url = `https://www.betfair.com/exchange/plus/politics/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (rugby)":
				url = `https://www.betfair.com/exchange/plus/rugby-union/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (soccer)":
				url = `https://www.betfair.com/exchange/plus/football/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (esports)":
				url = `https://www.betfair.com/exchange/plus/e-sports/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (horses)":
				url = `https://www.betfair.com/exchange/plus/horse-racing/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Betfair (greyhounds)":
				url = `https://www.betfair.com/exchange/plus/greyhound-racing/market/${name}`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "TX (OU)":
				url = `http://ahchk2.txodds.com/RunningBall/Recorder.asp?ID=${name}&BK=pinnaclesports&OID=1&DB=AHCLiveOUAsiaOddsRec`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "TX (OUHT)":
				url = `http://ahchk2.txodds.com/RunningBall/Recorder.asp?ID=${name}&BK=pinnaclesports_1sthalf&OID=1&DB=AHCLiveOUAsiaOddsRec`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "TX (1X2)":
				url = `http://ahchk2.txodds.com/RunningBall/Recorder.asp?ID=${name}&BK=pinnaclesports&OID=1&DB=AHCLive1x2AsiaOddsRec`;
				window.open(url, "_blank", "noreferrer");
				break;
			case "Bookies Odds Data":
				// url = `http://pushfeed.grassiventures.com/txodds-pushfeed/events/${ name }?sc=`;
				// window.open(url, "_blank", "noreferrer");
				// this.router.navigate([`txodds-pushfeed/events/${ name }`], { queryParams: {"sc": ""}});
				this.router.navigate([]).then(() => {
					window.open(`txodds-pushfeed/events/${name}?sc=`, "_blank", "noreferrer");
				});
				break;
			case "Feeds Viewer":
				this.router.navigate([]).then(() => {
					window.open(`feeds-viewer/${name}`, "_blank", "noreferrer");
				});
				break;
			case "Watch Logs":
				eventId = name.split(":")[0];
				setId = name.split(":")[1];
				this.router.navigate([`matches/detail/logs/${eventId}/${setId}`]);
				break;
			case "Scraper Data":
				eventId = name.split(":")[0];
				betfairEventId = name.split(":")[1];
				this.router.navigate([`scraper/data`], {
					queryParams: { matchId: eventId, betfairEventId: betfairEventId },
				});
				break;
			case "Betfair Raw Data":
				eventId = name.split(":")[0];
				this.router.navigate([`betfair-raw-data/${eventId}`]);
				break;
			case "Soccer Overview":
				this.router.navigate([]).then(() => {
					window.open(`/`, "_blank", "noreferrer");
				});
				break;
			case "Details":
				eventId = name.split(":")[0];
				setId = name.split(":")[1];
				this.router.navigate([]).then(() => {
					window.open(`matches/detail/${eventId}/${setId}`, "_blank", "noreferrer");
				});
				break;
			case "Details (Archive)":
				eventId = name.split(":")[0];
				setId = name.split(":")[1];
				this.router.navigate([`matches/detail/archive/${eventId}/${setId}`]);
				break;
			case "Summary":
				exchangeId = name.split(":")[0];
				setId = name.split(":")[1];
				this.router.navigate([]).then(() => {
					window.open(`matches/summary/${exchangeId}/${setId}`, "_blank", "noreferrer");
				});
				break;
			case "Summary (OU)":
				exchangeId = name.split(":")[0];
				this.router.navigate([]).then(() => {
					window.open(`matches/summary/live/${exchangeId}/ou`, "_blank", "noreferrer");
				});
				break;
			case "Summary (OU Archive)":
				exchangeId = name.split(":")[0];
				this.router.navigate([]).then(() => {
					window.open(`matches/summary/archive/${exchangeId}/ou`, "_blank", "noreferrer");
				});
				break;
			case "Summary (All)":
				exchangeId = name.split(":")[0];
				this.router.navigate([]).then(() => {
					window.open(`matches/summary/live/${exchangeId}/allsets`, "_blank", "noreferrer");
				});
				break;
			case "Summary (All Archive)":
				exchangeId = name.split(":")[0];
				this.router.navigate([]).then(() => {
					window.open(`matches/summary/archive/${exchangeId}/allsets`, "_blank", "noreferrer");
				});
				break;
			case "Summary (Archive)":
				exchangeId = name.split(":")[0];
				setId = name.split(":")[1];
				this.router.navigate([]).then(() => {
					window.open(`matches/summary/archive/${exchangeId}/${setId}`, "_blank", "noreferrer");
				});
				break;

			case "Races Overview":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/races/overview`, "_blank", "noreferrer");
				});
				break;
			case "FR-Races Details":
				raceId = name;
				this.router.navigate([]).then(() => {
					window.open(`horsebet/races/detail/${raceId}/frHorses`, "_blank", "noreferrer");
				});
				break;
			case "Races Details":
				uniqId = name.split(":")[0];
				sport = name.split(":")[1];
				raceId = name.split(":").length > 2 ? name.split(":")[2] : null;
				if (raceId) {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/races/detail/${uniqId}/${raceId}?sport=${sport}`, "_blank", "noreferrer");
					});
				} else {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/races/detail/${uniqId}?sport=${sport}`, "_blank", "noreferrer");
					});
				}
				break;
			case "Races Details (Archive)":
				uniqId = name.split(":")[0];
				raceId = name.split(":").length > 1 ? name.split(":")[1] : null;
				if (raceId) {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/races/detail/archive/${uniqId}/${raceId}`, "_blank", "noreferrer");
					});
				} else {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/races/detail/archive/${uniqId}`, "_blank", "noreferrer");
					});
				}
				break;
			case "Races Summary":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/races/summary`, "_blank", "noreferrer");
				});
				break;
			case "Races Summary (Archive)":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/races/summary/archive`, "_blank", "noreferrer");
				});
				break;
			case "Races Summary (All)":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/races/summary/all`, "_blank", "noreferrer");
				});
				break;

			case "Other Sport Overview":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/other-sport/overview`, "_blank", "noreferrer");
				});
				break;
			case "Other Sport Details":
				uniqId = name.split(":")[0];
				sport = name.split(":")[1];
				raceId = name.split(":").length > 2 ? name.split(":")[2] : null;
				if (raceId) {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/other-sport/detail/${uniqId}/${raceId}?sport=${sport}`, "_blank", "noreferrer");
					});
				} else {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/other-sport/detail/${uniqId}?sport=${sport}`, "_blank", "noreferrer");
					});
				}
				break;
			case "Other Sport Details (Archive)":
				uniqId = name.split(":")[0];
				raceId = name.split(":").length > 1 ? name.split(":")[1] : null;
				if (raceId) {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/other-sport/detail/archive/${uniqId}/${raceId}`, "_blank", "noreferrer");
					});
				} else {
					this.router.navigate([]).then(() => {
						window.open(`horsebet/other-sport/detail/archive/${uniqId}`, "_blank", "noreferrer");
					});
				}
				break;
			case "Other Sport Summary":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/other-sport/summary`, "_blank", "noreferrer");
				});
				break;
			case "Other Sport Summary (Archive)":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/other-sport/summary/archive`, "_blank", "noreferrer");
				});
				break;
			case "Other Sport Summary (All)":
				this.router.navigate([]).then(() => {
					window.open(`horsebet/other-sport/summary/all`, "_blank", "noreferrer");
				});
				break;

			case "Ticker":
				this.router.navigate([]).then(() => {
					window.open(`ticker`, "_blank", "noreferrer");
				});
				break;
			case "Ticker (Archive)":
				this.router.navigate([]).then(() => {
					window.open(`ticker/archive`, "_blank", "noreferrer");
				});
				break;
			case "Alerts":
				this.router.navigate([`reports/alerts`]);
				break;
			case "Scrapers":
				eventId = name;
				this.router.navigate([]).then(() => {
					window.open(`reports/scraperlinks/${name}`, "_blank", "noreferrer");
				});
				break;
		}
	}
}
