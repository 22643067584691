export const environment = {
	production: true,
	name: "Production",
	color: "production1",
	AUTH_ENDPOINT: "https://betrobot.grassiventures.com:15053/api",
	TXODDS_PUSHFEED_ENDPOINT: "https://betrobot.grassiventures.com:15040/api",
	BETFAIR_HISTORIC_DATA_ENDPOINT: "https://betrobot.grassiventures.com:15060/api",
	GOLDFINGER_MONITOR_ENDPOINT: "https://betrobot.grassiventures.com:8090",
	BETROBOT_API_ENDPOINT: "https://betrobot.grassiventures.com:15073/api",
	BETROBOT_PUSHFEED_ENDPOINT: "betrobot.grassiventures.com:15075",
};
