import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "liability" })
export class LiabilityPipe implements PipeTransform {
	transform(value: number, round = true, colour = true, precision = 2, includeBrackets = false): string {
		const separator = 3;
		let result = "<span";

		if (colour) {
			if (value < 0) {
				result += " class='negative'";
			} else if (value > 0) {
				result += " class='positive'";
			}
		}

		let valueStr = "";
		try {
			if (typeof value !== "undefined" && value != null) {
				valueStr = value.toFixed(precision);
				if (round) {
					valueStr = Math.round(value).toString();
				}
			}
		} catch (ex) {
			console.log(ex);
			valueStr = "err";
		}

		let formatted = "";
		const decimal = valueStr.indexOf(".");
		const fraction = decimal > -1 ? valueStr.substr(decimal) : "";
		let tmpStr = valueStr.replace("-", "").replace(fraction, "");
		if (tmpStr.length > separator) {
			while (tmpStr.length > separator) {
				formatted = tmpStr.substr(tmpStr.length - separator, separator) + " " + formatted;
				tmpStr = tmpStr.slice(0, tmpStr.length - separator);
			}
		}
		formatted = (tmpStr + " " + formatted).trim() + fraction;
		if (valueStr.startsWith("-")) {
			formatted = "-" + formatted;
		}
		// valueStr = valueStr.substring(0, valueStr.length - separator) + " " + valueStr.substr(valueStr.length - separator, separator);

		if (includeBrackets) {
			formatted = `(${formatted})`;
		}
		result += `>${formatted}</span>`;
		return result;
	}
}
