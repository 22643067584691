export class BaseTableColumn {
	value;
	cssClass: string;
	colSpan: number;
	numberValue: boolean;

	constructor(value, cssClass = "", colSpan = 1) {
		this.value = value;
		this.cssClass = cssClass;
		this.colSpan = colSpan;

		if (typeof value === "number") {
			this.numberValue = true;
		} else {
			this.numberValue = false;
		}
	}
}
