import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { CommonModule } from "@angular/common";
import { MaterialModule } from "./material.module";
import { PipesModule } from "./pipes.module";
import { AlertComponent } from "./alerts/alert.component";
import { AlertEditDialogComponent } from "./alerts/alert-edit-dialog/alert-edit-dialog.component";
import { AlertContentDialogComponent } from "./alerts/alert-content-dialog/alert-content-dialog.component";
import { NoteEditDialogComponent } from "./alerts/note-edit-dialog/note-edit-dialog.component";
import { BetInfoDialogComponent } from "./shared/components/bet-info-dialog/bet-info-dialog.component";
import { TransactionsSummaryHoursComponent } from "./shared/components/transactionsTables/summary-hours/summary-hours.component";
import { TransactionsSummarySetsComponent } from "./shared/components/transactionsTables/summary-sets/summary-sets.component";
import { TransactionsSummarySetComponent } from "./shared/components/transactionsTables/summary-set/summary-set.component";
import { UserComponent } from "./user.component";
import { DateRangeComponent } from "./shared/components/date-range/date-range.component";
import { ConfirmDialogComponent } from "./shared/components/confirm-dialog/confirm-dialog.component";

@NgModule({
	imports: [CommonModule, MaterialModule, ReactiveFormsModule, PipesModule],
	declarations: [
		AlertComponent,
		AlertEditDialogComponent,
		AlertContentDialogComponent,
		DateRangeComponent,
		NoteEditDialogComponent,
		BetInfoDialogComponent,
		TransactionsSummaryHoursComponent,
		TransactionsSummarySetsComponent,
		TransactionsSummarySetComponent,
		UserComponent,
		ConfirmDialogComponent,
	],
	exports: [
		AlertComponent,
		AlertEditDialogComponent,
		AlertContentDialogComponent,
		DateRangeComponent,
		NoteEditDialogComponent,
		BetInfoDialogComponent,
		TransactionsSummaryHoursComponent,
		TransactionsSummarySetsComponent,
		TransactionsSummarySetComponent,
		UserComponent,
		ConfirmDialogComponent,
	],
})
export class SharedComponentsModule {}
