export const environment = {
	production: false,
	name: "Local",
	//TXODDS_PUSHFEED_ENDPOINT: "http://34.244.7.41:15040/api",
	TXODDS_PUSHFEED_ENDPOINT: "https://betrobot.grassiventures.com:15040/api",
	BETFAIR_HISTORIC_DATA_ENDPOINT: "http://34.244.7.41:15060/api",
	GOLDFINGER_MONITOR_ENDPOINT: "https://betrobot.grassiventures.com:8090",

	// AUTH_ENDPOINT: "http://52.16.82.138:15053/api",
	AUTH_ENDPOINT: "https://betrobot.grassiventures.com:15053/api",
	// AUTH_ENDPOINT: "https://betrobot-staging2.grassiventures.com:15053/api",

	// BETROBOT_API_ENDPOINT: "http://52.16.82.138:15073/api",
	BETROBOT_API_ENDPOINT: "https://betrobot.grassiventures.com:15073/api",
	// BETROBOT_API_ENDPOINT: "https://betrobot-staging2.grassiventures.com:15073/api",

	// BETROBOT_PUSHFEED_ENDPOINT: "52.16.82.138:15075",
	BETROBOT_PUSHFEED_ENDPOINT: "betrobot.grassiventures.com:19075",
	// BETROBOT_PUSHFEED_ENDPOINT: "betrobot-staging2.grassiventures.com:19075",
};
