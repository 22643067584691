import { EventEmitter, Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";

import { environment } from "../../../environments/env";
import { SocketIOClient } from "../transports/socket.io/client";
import { SocketIOMessage } from "../transports/socket.io/message";

@Injectable()
export class PushFeedService {
	private socket: SocketIOClient;
	private disconnectionSubscription: Subscription;
	private connectionSubscription: Subscription;
	private dataSubscription: Subscription;
	private events: Array<[string, EventEmitter<string>]>;

	public onDisconnected = new EventEmitter<string>();
	public onConnected = new EventEmitter<string>();

	constructor() {
		this.events = new Array<[string, EventEmitter<string>]>();
	}

	enterRoom(room: string): Subject<string> {
		let emitter = this.getEmitter(room);
		if (!emitter) {
			emitter = new EventEmitter<string>();
			this.events.push([room, emitter]);
		}

		if (this.socket) {
			this.socket.joinRoom(room);
		}

		return emitter;
	}

	leaveRoom(room: string) {
		if (this.socket) {
			this.socket.leaveRoom(room);
		}

		const index = this.events.findIndex((r) => r[0] == room);
		if (index > -1) {
			this.events.splice(index, 1);
		}
	}

	openConnection() {
		if (!this.socket) {
			const [socketHost, socketPort] = environment.BETROBOT_PUSHFEED_ENDPOINT.split(":");

			this.socket = new SocketIOClient(socketHost, socketPort);
			this.disconnectionSubscription = this.socket.onDisconnected.subscribe(this.handleSocketDisconnection.bind(this));
			this.connectionSubscription = this.socket.onConnected.subscribe(this.handleSocketConnection.bind(this));
			this.dataSubscription = this.socket.message$.subscribe(this.handleSocketData.bind(this));
		}
	}

	closeConnection() {
		this.dataSubscription?.unsubscribe();
		this.disconnectionSubscription?.unsubscribe();
		this.connectionSubscription?.unsubscribe();
		this.socket.close();
	}

	private handleSocketDisconnection() {
		this.onDisconnected.emit("Could not establish connection with push feed server.Data will NOT update automatically.");
	}

	private handleSocketConnection() {
		this.onConnected.emit("Connection with push feed server re-established. Data will update automatically.");
	}

	private fixDataTypes(value) {
		Object.keys(value).forEach((property) => {
			if (value[property] === null) {
				value[property] = null;
			} else if (typeof value[property] === "object") {
				this.fixDataTypes(value[property]);
			} else if (!isNaN(+value[property])) {
				value[property] = +value[property];
			}
		});
	}

	private handleSocketData(data: SocketIOMessage) {
		if (typeof data.message === "object") {
			this.fixDataTypes(data.message);
		}

		this.emit(data.subject, data.message);
	}

	private emit(room: string, message: string) {
		const emitter = this.getEmitter(room);
		if (emitter) {
			emitter.emit(message);
		}
	}

	private getEmitter(room: string): EventEmitter<string> {
		const exists = this.events.find((e) => e[0] == room);
		return exists ? exists[1] : null;
	}
}
