import { LiabilitySet } from "./liabilitySet";
import { ExchangeList } from ".";

export class LiabilityGroup {
	marketTypeId: number;
	sets: LiabilitySet[];

	private _exchangeList: ExchangeList;
	private _marketTypeName: string;

	static restructureLiabilities(data) {
		const restructured = {};

		data.forEach((entry) => {
			const setId = entry.setId;
			Object.keys(entry).forEach((property) => {
				if (property.indexOf(":") > -1) {
					const [marketTypeId, marketId, typeId, line] = property.split(":");

					if (!restructured[marketTypeId]) {
						restructured[marketTypeId] = {};
					}
					const marketType = restructured[marketTypeId];

					if (!marketType[setId]) {
						marketType[setId] = {};
					}
					const set = marketType[setId];

					if (!set[marketId]) {
						set[marketId] = {};
					}
					const market = set[marketId];

					if (!market[typeId]) {
						market[typeId] = {};
					}
					const type = market[typeId];

					type[line] = entry[property];
				}
			});
		});

		return restructured;
	}

	constructor(marketTypeId: number, setId: string, liabilities, exchangeList: ExchangeList) {
		this.marketTypeId = marketTypeId;
		this.sets = new Array<LiabilitySet>();

		this.sets.push(new LiabilitySet(setId, liabilities, exchangeList));

		this._exchangeList = exchangeList;
		this._marketTypeName = null;
	}

	update(setId: string, liabilities) {
		let requiresRedraw = false;

		const index = this.sets.findIndex((set) => set.setId === setId);
		if (index > -1) {
			requiresRedraw = this.sets[index].update(liabilities);
			// // blank potentials for markets which are no longer present
			// const marketsToReset = this.sets[index].markets.filter(
			// 	(market) => !Object.keys(liabilities).includes(market.market)
			// );
			// for (const marketToReset of marketsToReset) {
			// 	marketToReset.liabilities
			// 		.filter((liability) => liability.type === "potential")
			// 		.forEach((liability) => {
			// 			liability.lines.map((line) => (line.value = 0));
			// 		});
			// 	marketToReset.liabilities
			// 		.filter((liability) => liability.type === "potential_back")
			// 		.forEach((liability) => {
			// 			liability.lines.map((line) => (line.value = 0));
			// 		});
			// }
		} else {
			this.sets.push(new LiabilitySet(setId, liabilities, this._exchangeList));
		}

		return requiresRedraw;
	}

	get marketTypeName(): string {
		if (!this._marketTypeName) {
			const marketTypeName = this._exchangeList.translateMarketName(this.marketTypeId);
			if (marketTypeName) {
				this._marketTypeName = marketTypeName;
			}
		}

		if (!this._marketTypeName) {
			return `[${this.marketTypeId}]`;
		} else {
			return this._marketTypeName;
		}
	}
}
