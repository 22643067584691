export const environment = {
	production: false,
	name: "Horses-Staging2",
	color: "staging4",
	AUTH_ENDPOINT: "https://horses-staging2.grassiventures.com:15053/api",
	TXODDS_PUSHFEED_ENDPOINT: "https://pushfeed.grassiventures.com:15040/api",
	BETFAIR_HISTORIC_DATA_ENDPOINT: "https://betrobot.grassiventures.com:15060/api",
	BETROBOT_API_ENDPOINT: "https://horses-staging2.grassiventures.com:15073/api",
	BETROBOT_PUSHFEED_ENDPOINT: "horses-staging2.grassiventures.com:15075",
};
