import { Component, Input } from "@angular/core";

import { BaseTable, BaseTableColumn, BaseTableRow, TransactionDate } from "../../../models";

@Component({
	selector: "app-transactions-summary-sets",
	templateUrl: "../summary.component.html",
	styleUrls: ["../summary.component.scss"],
})
export class TransactionsSummarySetsComponent {
	data: BaseTable;

	@Input() darkTheme = false;
	@Input("data") set onData(transactions: TransactionDate) {
		this.data = new BaseTable();

		let placedSuccessTotal = 0;
		let placedErrorTotal = 0;
		let cancelledSuccessTotal = 0;
		let cancelledErrorTotal = 0;

		let headerRow = new BaseTableRow("header first-row");
		headerRow.columns.push(new BaseTableColumn("Set", "separator left-align"));
		headerRow.columns.push(new BaseTableColumn("Placed", "separator centered", 4));
		headerRow.columns.push(new BaseTableColumn("Cancelled", "separator centered", 4));
		headerRow.columns.push(new BaseTableColumn("Totals", "centered", 2));
		this.data.rows.push(headerRow);

		headerRow = new BaseTableRow("header");
		headerRow.columns.push(new BaseTableColumn("", "separator"));
		headerRow.columns.push(new BaseTableColumn("Success", ""));
		headerRow.columns.push(new BaseTableColumn("Error", ""));
		headerRow.columns.push(new BaseTableColumn("Totals", ""));
		headerRow.columns.push(new BaseTableColumn("Err%", "separator"));
		headerRow.columns.push(new BaseTableColumn("Success", ""));
		headerRow.columns.push(new BaseTableColumn("Error", ""));
		headerRow.columns.push(new BaseTableColumn("Totals", ""));
		headerRow.columns.push(new BaseTableColumn("Err%", "separator"));
		headerRow.columns.push(new BaseTableColumn("Requests", ""));
		headerRow.columns.push(new BaseTableColumn("Transactions", ""));
		this.data.rows.push(headerRow);

		for (const set of transactions.data) {
			let placedSuccess = 0;
			let placedError = 0;
			let cancelledSuccess = 0;
			let cancelledError = 0;
			let requests = 0;
			let trans = 0;

			for (const hourData of set.data) {
				let ps = 0;
				let pe = 0;
				let cs = 0;
				let ce = 0;

				if ("place" in hourData.values) {
					ps = hourData.values["place"]["success"] || 0;
					pe = hourData.values["place"]["error"] || 0;
				}
				if ("cancel" in hourData.values) {
					cs = hourData.values["cancel"]["success"] || 0;
					ce = hourData.values["cancel"]["error"] || 0;
				}

				placedSuccess += ps;
				placedError += pe;
				cancelledSuccess += cs;
				cancelledError += ce;
				placedSuccessTotal += ps;
				placedErrorTotal += pe;
				cancelledSuccessTotal += cs;
				cancelledErrorTotal += ce;
				requests += ps + pe + cs + ce;
				trans += ps + pe + ce;
			}

			const placed = placedSuccess + placedError;
			const cancelled = cancelledSuccess + cancelledError;

			const dataRow = new BaseTableRow();
			dataRow.columns.push(new BaseTableColumn(set.setName, "separator left-align"));
			dataRow.columns.push(new BaseTableColumn(placedSuccess, "transaction"));
			dataRow.columns.push(new BaseTableColumn(placedError, "transaction"));
			dataRow.columns.push(new BaseTableColumn(placed));
			dataRow.columns.push(new BaseTableColumn(placed > 0 ? (placedError / placed) * 100 : 0, "separator"));
			dataRow.columns.push(new BaseTableColumn(cancelledSuccess, ""));
			dataRow.columns.push(new BaseTableColumn(cancelledError, "transaction"));
			dataRow.columns.push(new BaseTableColumn(cancelled, ""));
			dataRow.columns.push(new BaseTableColumn(cancelled > 0 ? (cancelledError / cancelled) * 100 : 0, "separator"));
			dataRow.columns.push(new BaseTableColumn(requests, "totals"));
			dataRow.columns.push(new BaseTableColumn(trans, `totals transaction`));
			this.data.rows.push(dataRow);
		}

		const placedTotal = placedSuccessTotal + placedErrorTotal;
		const cancelledTotal = cancelledSuccessTotal + cancelledErrorTotal;

		const totalsRow = new BaseTableRow("footer");
		totalsRow.columns.push(new BaseTableColumn("Totals", "separator left-align"));
		totalsRow.columns.push(new BaseTableColumn(placedSuccessTotal, "transaction"));
		totalsRow.columns.push(new BaseTableColumn(placedErrorTotal, "transaction"));
		totalsRow.columns.push(new BaseTableColumn(placedTotal, ""));
		totalsRow.columns.push(new BaseTableColumn(placedTotal > 0 ? (placedErrorTotal / placedTotal) * 100 : 0, "separator"));
		totalsRow.columns.push(new BaseTableColumn(cancelledSuccessTotal, ""));
		totalsRow.columns.push(new BaseTableColumn(cancelledErrorTotal, "transaction"));
		totalsRow.columns.push(new BaseTableColumn(cancelledTotal, ""));
		totalsRow.columns.push(
			new BaseTableColumn(cancelledTotal > 0 ? (cancelledErrorTotal / cancelledTotal) * 100 : 0, "separator")
		);
		totalsRow.columns.push(
			new BaseTableColumn(placedSuccessTotal + placedErrorTotal + cancelledSuccessTotal + cancelledErrorTotal, "totals")
		);
		totalsRow.columns.push(
			new BaseTableColumn(placedSuccessTotal + placedErrorTotal + cancelledErrorTotal, "totals transaction")
		);
		this.data.rows.push(totalsRow);
	}
}
