import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/env";
import { Fixture, FixtureNote, MatchLogPrices } from "../models";
import { ScoreEvent } from "src/app/matches/detail/models";

@Injectable()
export class FixtureService {
	protected url: string;

	constructor(protected http: HttpClient) {
		this.url = `${environment.BETROBOT_API_ENDPOINT}/fixtures`;
	}

	update(ids: string[], updates): Observable<object> {
		return this.http.patch(this.url, { ids: ids, updates: updates });
	}

	stopStart(ids: string[], updates): Observable<Fixture[]> {
		return this.http.post<Array<Fixture>>(`${this.url}/stopStart`, {
			ids: ids,
			updates: updates,
		});
	}

	restartFollower(ids: string[], sets): Observable<Fixture[]> {
		return this.http.post<Array<Fixture>>(`${this.url}/restart`, {
			ids: ids,
			sets: sets,
		});
	}

	forceStopAll(): Observable<object> {
		return this.http.post(`${this.url}/force/stop`, null);
	}

	forceResumeAll(): Observable<object> {
		return this.http.post(`${this.url}/force/resume`, null);
	}

	updateNote(note: FixtureNote): Observable<FixtureNote> {
		if (note._id) {
			return this.http
				.put<FixtureNote>(`${this.url}/note/${note._id}`, note)
				.pipe(map((response) => new FixtureNote(response)));
		} else {
			return this.http.post<FixtureNote>(`${this.url}/note/`, note).pipe(map((response) => new FixtureNote(response)));
		}
	}

	getFixtureInfo(matchId: number): Observable<Fixture> {
		return this.http.get<Fixture>(`${this.url}/info/${matchId}`);
	}

	getFixtureEvents(matchId: number, fromDate: number = null, toDate: number = null): Observable<ScoreEvent[]> {
		return this.http.get<ScoreEvent[]>(`${this.url}/events/${matchId}`).pipe(
			map((events) =>
				events
					.map((event) => new ScoreEvent(event))
					.filter((event) => (fromDate ? event.time.getTime() >= fromDate : true))
					.filter((event) => (toDate ? event.time.getTime() <= toDate : true))
			)
		);
	}

	getLoggedMarkets(matchId: number) {
		return this.http.get<{ marketTypeId: string; marketType: string; market: string }[]>(
			`${this.url}/logMarkets/${matchId}`
		);
	}

	extractPricesFromLogs(matchId: number, filters): Observable<MatchLogPrices[]> {
		return this.http.post<MatchLogPrices[]>(`${this.url}/logPrices/${matchId}`, filters);
	}
}
