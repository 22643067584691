import { KeyValuePair } from "./keyValuePair";

export class Dictionary<T1, T2> {
	private items: Array<KeyValuePair<T1, T2>>;

	constructor(values: KeyValuePair<T1, T2>[] = null) {
		if (values) {
			this.items = values;
		} else {
			this.items = new Array<KeyValuePair<T1, T2>>();
		}
	}

	public get length(): number {
		return this.items.length;
	}

	public clear() {
		this.items.splice(0, this.items.length);
	}

	public add(key: T1, value: T2) {
		if (this.hasKey(key)) {
			this.items.find((entry) => entry.key === key).value = value;
		} else {
			this.items.push(new KeyValuePair<T1, T2>(key, value));
		}
	}

	public remove(key: T1) {
		const entry = this.items.find((entry) => entry.key === key);
		if (entry) {
			const index = this.items.indexOf(entry);
			this.items.splice(index, 1);
		}
	}

	public addRange(values: KeyValuePair<T1, T2>[]) {
		if (values) {
			values.forEach((entry) => this.add(entry.key, entry.value));
		}
	}

	public getItem(key: T1): KeyValuePair<T1, T2> {
		const entry = this.items.find((entry) => entry.key === key);
		if (entry) {
			return entry;
		} else {
			return null;
		}
	}

	public toArray(): KeyValuePair<T1, T2>[] {
		return this.items.sort(KeyValuePair.keySorter);
	}

	public values(): T2[] {
		return this.items.map((item) => item.value);
	}

	public keys(): T1[] {
		return this.items.map((item) => item.key);
	}

	public hasKey(key: T1): boolean {
		return this.items.find((entry) => entry.key === key) != null;
	}
}
