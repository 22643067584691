<mat-card class="gv-fullscreen-card">
	<mat-card-header>
		<div class="left-align">
			<button mat-button class="gv-button-small" [disabled]="loading" (click)="loadData()"><mat-icon>refresh</mat-icon></button>
			<h1>Users</h1>
		</div>
		<div class="right-align">
			<mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
		</div>
	</mat-card-header>
	<mat-card-content>
		<table mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort class="alternate" [class.dark-theme]="darkTheme">
			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let record">
					<button mat-icon-button #detailMenuTrigger
																	[matMenuTriggerFor]="detailMenu"
																	[matMenuTriggerData]="{record: record}"
																	[disabled]="false">
						<mat-icon>more_vert</mat-icon>
					</button>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
				<td mat-cell *matCellDef="let record"> {{record.name}} </td>
			</ng-container>

			<ng-container matColumnDef="role">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
				<td mat-cell *matCellDef="let record"> {{record.role}} </td>
			</ng-container>

			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
				<td mat-cell *matCellDef="let record"> {{record.email}} </td>
			</ng-container>

			<ng-container matColumnDef="sms">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
				<td mat-cell *matCellDef="let record"> {{record.sms}} </td>
			</ng-container>

			<ng-container matColumnDef="enable_email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
				<td mat-cell *matCellDef="let record">
						<mat-icon *ngIf="record.enable_email">done</mat-icon>
						<mat-icon *ngIf="!record.enable_email">clear</mat-icon>
				 </td>
			</ng-container>

			<ng-container matColumnDef="enable_sms">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Sms</th>
				<td mat-cell *matCellDef="let record">
						<mat-icon *ngIf="record.enable_sms">done</mat-icon>
						<mat-icon *ngIf="!record.enable_sms">clear</mat-icon>
				 </td>
			</ng-container>

			<ng-container matColumnDef="spacer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
				<td mat-cell *matCellDef="let record"></td>
			</ng-container>

			<tr mat-header-row class="sticky" *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
			<tr mat-row [class.warn]="row.warn" *matRowDef="let row; columns: columnsToDisplay;"></tr>
		</table>
	</mat-card-content>
</mat-card>

<mat-menu #detailMenu="matMenu" xPosition="after">
	<ng-template let-record="record" matMenuContent>
		<ng-container>
			<button mat-menu-item (click)="edit(record)">Edit</button>
			<button mat-menu-item (click)="delete(record)">Delete</button>
		</ng-container>
	</ng-template>
</mat-menu>
