import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/env";
import { Alert, AlertSummary, Balance } from "../models";

@Injectable()
export class ReportService {
	protected url: string;
	private _alertTypes: Array<[string, string, string]>;

	constructor(protected http: HttpClient) {
		this.url = environment.BETROBOT_API_ENDPOINT + "/reports";

		this._alertTypes = new Array<[string, string, string]>();
		this._alertTypes.push(["bf_liability", "Liability Diff.", "Liab"]);
		this._alertTypes.push(["tge_drift", "TGE Drift", "TGE"]);
		this._alertTypes.push(["fixed_asians", "Fixed Asians", "FA"]);
		this._alertTypes.push(["fixed", "Fixed Games", "FG"]);
		this._alertTypes.push(["late_bet", "Late Suspensions", "LS"]);
		this._alertTypes.push(["game_time", "Game Time", "GT"]);
		this._alertTypes.push(["preemptive_mPrice", "Preemptive mPrice", "mPrice (P)"]);
		this._alertTypes.push(["different_mPrice", "Different mPrice", "mPrice"]);
		this._alertTypes.push(["fork_under", "Under Forks", "UF"]);
		this._alertTypes.push(["old_bets", "Old Bets", "OB"]);
		this._alertTypes.push(["bet_count", "Bet Count", "BC"]);
		this._alertTypes.push(["bf_heartbeat", "Heartbeat (BF)", "HBF"]);
		this._alertTypes.push(["heartbeat", "Heartbeat (BR)", "HBB"]);
		this._alertTypes.push(["betfair_exposure_alert", "Exposure Alert", "EXP"]);
		this._alertTypes.push(["scraper_count", "Low Scraper Count", "SC"]);
		this._alertTypes.push(["runnerChange", "Runner Change", "RC"]);
		this._alertTypes.push(["eventRescheduled", "Rescheduled Event", "RSC"]);
		this._alertTypes.push(["oldOdds", "Old Odds", "OO"]);
		this._alertTypes.push(["horses_liab", "Horses Liability Alert", "hLiab"]);
		this._alertTypes.push(["low_link_score", "Low Link Score", "LS"]);
		this._alertTypes.push(["bb_link_mismatch", "BB Link Mismatch", "BB"]);
		this._alertTypes.push(["sp_link_mismatch", "SP Link Mismatch", "SP"]);
		this._alertTypes.push(["max_liability", "Max Liability", "ML"]);
		this._alertTypes.push(["bookies_mismatch", "Bookies Price Mismatch", "BPM"]);
		this._alertTypes.push(["goal_mismatch", "Goal Mismatch", "GM"]);
		this._alertTypes.push(["robot_var", "RoboVAR", "VAR"]);
		this._alertTypes.push(["bf_too_low", "BF Too Low", "LOW"]);
		this._alertTypes.push(["hb_max_liability", "HB Max Liability", "HML"]);
		this._alertTypes.push(["hb_no_prices", "HB No Prices", "HNP"]);
		this._alertTypes.push(["no_gf_bets", "No GF Bets", "NGF"]);
		this._alertTypes.push(["horses_bets", "Horses Bets Mismatch", "HBM"]);
	}

	get alertTypes() {
		return this._alertTypes;
	}

	getAlertContent(id: string): Observable<Record<string, string>> {
		if (!id.startsWith("id_")) {
			id = "id_" + id;
		}

		return this.http.get<Record<string, string>>(`${this.url}/alert/${id}`);
	}

	getAlerts(
		alertType: string[],
		fromDate: string = null,
		toDate: string = null,
		pageIndex = 0,
		pageSize = 100,
		initTotal
	): Observable<Array<Alert>> {
		const params = [`page=${pageIndex}`, `size=${pageSize}`];

		if (alertType) {
			params.push(`type=${alertType.join(",")}`);
		}
		if (fromDate) {
			params.push(`from=${fromDate}`);
		}
		if (toDate) {
			params.push(`to=${toDate}`);
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.http.get<any>(`${this.url}/alerts?${params.join("&")}`).pipe(
			map((response) => {
				if (initTotal) {
					initTotal(response.recordCount);
				}
				return response.content.map((record) => new Alert(record));
			})
		);
	}

	getAlertsSummary(fromDate: string, toDate: string): Observable<Array<AlertSummary>> {
		const params = [`from=${fromDate}`, `to=${toDate}`];

		return this.http.get<unknown>(`${this.url}/alerts/summary?${params.join("&")}`).pipe(
			map((response) => {
				return Object.keys(response).map((record) => new AlertSummary(+record, response[record]));
			})
		);
	}

	updateAlertNote(ids: string[], note: string) {
		// add prefix to ids so that they are not converted to number
		ids = ids.map((id) => {
			if (!id.startsWith("id_")) {
				id = "id_" + id;
			}

			return id;
		});

		return this.http.patch(`${this.url}`, { ids, note });
	}

	setAlertChecking(ids: string[]) {
		// add prefix to ids so that they are not converted to number
		ids = ids.map((id) => {
			if (!id.startsWith("id_")) {
				id = "id_" + id;
			}

			return id;
		});

		return this.http.patch(`${this.url}/check`, { ids });
	}

	getBalance(): Observable<Array<Balance>> {
		return this.http.get<Array<Balance>>(`${this.url}/balance`).pipe(
			map((data) => {
				return data.map((record) => new Balance(record));
			})
		);
	}
}
