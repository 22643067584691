export class StatisticsFeed {
	origin: string;
	feed: string;
	main: string;
	side: string;
	xy: string;

	constructor(statisticsFeed: StatisticsFeed) {
		this.origin = statisticsFeed.origin;
		this.feed = statisticsFeed.feed;
		this.main = statisticsFeed.main;
		this.side = statisticsFeed.side || "";
		this.xy = statisticsFeed.xy;
	}

	get origin_feed(): string {
		return this.origin + (this.feed && this.feed != "-" ? " (" + this.feed + ")" : "");
	}

	get x(): number {
		if (this.xy) {
			return parseFloat(this.xy.split(",")[0]);
		} else {
			return 2;
		}
	}

	get y(): number {
		if (this.xy) {
			return parseFloat(this.xy.split(",")[1]);
		} else {
			return 2;
		}
	}
}
