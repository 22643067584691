export class LiabilityCell {
	id: string;
	label: string;
	subLabel: string;
	order: number;
	type: string;
	value: number;
	marketTypeId: number;

	columns: LiabilityCell[];

	constructor(id: string, label: string, order: number, type: string, marketTypeId?: number, value?: number) {
		this.id = id;
		this.label = label;
		this.order = order;
		this.type = type;
		this.value = value;
		this.marketTypeId = marketTypeId;
	}

	get blank(): boolean {
		return !this.value;
	}
}
