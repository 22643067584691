import { Dictionary, FixtureUpdatesTracker } from "../../shared/models";

export class ActionsMenu extends FixtureUpdatesTracker {
	public links: Dictionary<string, string>;

	constructor(id) {
		super([id]);
		this.links = new Dictionary<string, string>();
	}

	get selectedIds(): string[] {
		return super.getSelectedIds();
	}

	get pendingStopStart(): boolean {
		return super.getPendingStopStart();
	}

	get pendingRestart(): boolean {
		return super.getPendingRestart();
	}

	get pendingUpdates(): boolean {
		return super.getPendingUpdates();
	}

	get updates() {
		return super.getUpdates();
	}

	addLink(code: string, name: string) {
		this.links.add(code, name);
	}
}
