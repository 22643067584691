import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class AlertService {
	constructor(public snackBar: MatSnackBar) {}

	alertSuccess(message: string) {
		this.snackBar.open(message, "Close", {
			panelClass: ["success"],
		});
	}

	alertError(message: string) {
		this.snackBar.open(message, "Close", {
			panelClass: ["error"],
		});
	}
}
