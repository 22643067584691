export class Parameter {
	public _id: string;
	public market: string;
	public markettype: string;
	public set_id: string;
	public key: string;
	public value: string;
	public description: string;
	public fromMinute: number;
	public toMinute: number;
	public matchHalf: number;

	constructor(parameter: Parameter) {
		if (parameter) {
			this._id = parameter._id;
			this.market = parameter.market ? parameter.market.toString() : parameter.market;
			this.markettype = parameter.markettype;
			this.set_id = parameter.set_id;
			this.key = parameter.key;
			this.value = parameter.value;
			this.description = parameter.description;

			this.fromMinute = typeof parameter.fromMinute == "undefined" ? null : parameter.fromMinute;
			this.toMinute = typeof parameter.toMinute == "undefined" ? null : parameter.toMinute;
			this.matchHalf = typeof parameter.matchHalf == "undefined" ? null : parameter.matchHalf;
		}
	}

	get global(): boolean {
		return this.fromMinute == null && this.toMinute == null && this.matchHalf == null;
	}

	get group(): string {
		if (this.markettype) {
			// build up group key adding space to the general so that it is displayed on top
			return (
				(this.markettype === "general" ? " " : "") +
				this.markettype.toUpperCase() +
				(this.market ? " [Market " + this.market + "]" : "") +
				" - "
			);
		} else {
			return "";
		}
	}
}
