import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AlertService, ReportService } from "../../shared/services";
import { Alert } from "../../shared/models";

@Component({
	selector: "app-reports-alert-content-dialog",
	templateUrl: "alert-content-dialog.component.html",
	styleUrls: ["./alert-content-dialog.component.scss"],
})
export class AlertContentDialogComponent implements OnInit {
	content = "";

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Alert,
		private dialogRef: MatDialogRef<AlertContentDialogComponent>,
		protected alertService: AlertService,
		private reportService: ReportService
	) {}

	ngOnInit() {
		if (this.data && this.data._id) {
			this.reportService
				.getAlertContent(this.data._id)
				.toPromise()
				.then((response) => {
					if (response && response.content) {
						if (response.content.includes("ruleUrl")) {
							window.open(JSON.parse(response.content).ruleUrl, "_blank", "noreferrer");
							this.dialogRef.close();
						} else {
							this.content = response.content;
						}
					} else if (this.data.details) {
						this.content = `<div class='centered'>${this.data.details}</div>`;
					} else {
						this.content = "<div class='centered'>No Content!</div>";
					}
				})
				.catch((error) => this.handleError(error));
		}
	}

	private handleError(error) {
		if (error.message) {
			this.alertService.alertError(error.message);
		} else {
			this.alertService.alertError(error);
		}
	}
}
