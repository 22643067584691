import { Fixture as SharedFixture, ExchangeList } from "../../shared/models";
import { LiabilityGroup } from "./liabilityGroup";

export class Fixture extends SharedFixture {
	halt: number;
	home_score: number;
	away_score: number;
	gameTime: string;
	gameTime_bb: number;
	score_bb: string;
	score_ht: string;
	score_ht_bb: string;
	score_ft: string;
	warn: number;
	eventsFeed: string;
	eventsSources: string;
	liabilities: LiabilityGroup[];

	protected _exchangeList: ExchangeList;

	constructor(fixture: Fixture, exchangeList: ExchangeList) {
		super(fixture);
		this._exchangeList = exchangeList;

		this.server = fixture.server || " - ";
		this.halt = fixture.halt || 0;
		this.home_score = fixture.home_score || 0;
		this.away_score = fixture.away_score || 0;
		this.gameTime = fixture.gameTime || "-";
		this.gameTime_bb = fixture.gameTime_bb;
		this.score_bb = fixture.score_bb;
		this.score_ht = fixture.score_ht;
		this.score_ht_bb = fixture.score_ht_bb;
		this.score_ft = fixture.score_ft;
		this.warn = fixture.warn || 0;
		this.eventsFeed = fixture.eventsFeed || "-";
		this.eventsSources = fixture.eventsSources || "";

		this.liabilities = new Array<LiabilityGroup>();
		this.setLiabilities(fixture.liabilities);
	}

	public update(data) {
		super.update(data);
		this.setLiabilities(data.liabilities);
	}

	private setLiabilities(liabilities) {
		if (liabilities) {
			const restructured = LiabilityGroup.restructureLiabilities(liabilities);

			Object.keys(restructured).forEach((group) => {
				const marketTypeId = parseInt(group, null);
				Object.keys(restructured[group]).forEach((setId) => {
					const index = this.liabilities.findIndex((liabilityGroup) => liabilityGroup.marketTypeId === marketTypeId);
					if (index > -1) {
						this.liabilities[index].update(setId, restructured[marketTypeId][setId]);
					} else {
						this.liabilities.push(
							new LiabilityGroup(marketTypeId, setId, restructured[marketTypeId][setId], this._exchangeList)
						);
					}
				});
			});
		}
	}
}
