import { FixtureAlerts } from "./fixtureAlerts";

export class SystemInfo {
	emergency: number;
	autostart: number;
	txFeed: string;
	bfFeed: string;
	forceStop: boolean;
	ukHorsesBetter: string;
	betrobotBetter: string;
	hbSoccerBetter: string;
	spEvents: string;
	bbEvents: string;

	activeServers: string;
	fixtureAlerts: FixtureAlerts;

	constructor(systemInfo: SystemInfo) {
		this.emergency = systemInfo.emergency;
		this.autostart = systemInfo.autostart;
		this.txFeed = systemInfo.txFeed;
		this.bfFeed = systemInfo.bfFeed;
		this.spEvents = systemInfo.spEvents;
		this.bbEvents = systemInfo.bbEvents;
		this.forceStop = systemInfo.forceStop;
		this.activeServers = systemInfo.activeServers;
		this.ukHorsesBetter = systemInfo.ukHorsesBetter;
		this.betrobotBetter = systemInfo.betrobotBetter;
		this.hbSoccerBetter = systemInfo.hbSoccerBetter;

		this.fixtureAlerts = new FixtureAlerts();
		this.fixtureAlerts.parse(systemInfo);
	}

	public update(data) {
		Object.keys(data).forEach((property) => {
			if (property in this) {
				this[property] = data[property];
			}
		});

		this.fixtureAlerts.parse(data);
	}

	get alerts(): string {
		return this.fixtureAlerts.getSummary();
	}

	get alertsTooltip(): string {
		return this.fixtureAlerts.getDetails();
	}
}
