import { FixtureAlert } from "./fixtureAlert";

export class FixtureAlerts {
	private alertsPool: { [index: string]: FixtureAlert };

	constructor() {
		this.alertsPool = {
			halted: new FixtureAlert(0, "H", "Halted Games", 10),
			bf_liability: new FixtureAlert(0, "L", "Liability Alerts", 20),
			tge_drift: new FixtureAlert(0, "T", "TGE Drifts", 30),
			fixed_asians: new FixtureAlert(0, "A", "Fixed Asians", 40),
			fixed: new FixtureAlert(0, "F", "Fixed Games", 50),
			late_bet: new FixtureAlert(0, "B", "Late Bets", 60),
			game_time: new FixtureAlert(0, "M", "Game Time", 70),
			preemptive_mPrice: new FixtureAlert(0, "P", "Preemptive mPrice", 80),
			different_mPrice: new FixtureAlert(0, "D", "Different mPrice", 90),
			fork_under: new FixtureAlert(0, "U", "Under Forks", 100),
			scraper_count: new FixtureAlert(0, "S", "Low Scraper Count", 110),
			horses_liab: new FixtureAlert(0, "I", "Horses Liability Alerts", 22),
			low_link_score: new FixtureAlert(0, "C", "Low Link Score", 120),
			bb_link_mismatch: new FixtureAlert(0, "K", "BB Link Mismatch", 130),
			sp_link_mismatch: new FixtureAlert(0, "V", "SP Link Mismatch", 135),
			bookies_mismatch: new FixtureAlert(0, "O", "Bookies price mismatch", 140),
			eventRescheduled: new FixtureAlert(0, "R", "Event rescheduled", 150),
			runnerChange: new FixtureAlert(0, "N", "Runner Change", 160),
			max_liability: new FixtureAlert(0, "X", "Max Liability", 170),
			goal_mismatch: new FixtureAlert(0, "G", "Goal Mismatch", 180),
			robot_var: new FixtureAlert(0, "VAR", "RoboVAR", 190),
			bf_too_low: new FixtureAlert(0, "W", "BF Too Low", 200),
			hb_max_liability: new FixtureAlert(0, "HML", "HB Max Liability", 210),
			hb_no_prices: new FixtureAlert(0, "HNP", "HB No Prices", 220),
			no_gf_bets: new FixtureAlert(0, "NGF", "No GF Bets", 230),
			horses_bets: new FixtureAlert(0, "HBM", "Horses Bets Mismatch", 22),
		};
	}

	parse(data) {
		Object.keys(data).forEach((property) => {
			if (property in this.alertsPool) {
				const alert = this.alertsPool[property];
				alert.count = data[property];
			}
		});
	}

	getSummary(): string {
		const alerts = Object.values(this.alertsPool).filter((alert) => alert.count > 0);
		if (alerts.length > 0) {
			return alerts.map((alert) => `${alert.initial}:${alert.count}`).join(" ");
		} else {
			return null;
		}
	}

	getDetails(): string {
		const alerts = Object.values(this.alertsPool).filter((alert) => alert.count > 0);
		if (alerts.length > 0) {
			return alerts.map((alert) => `${alert.description}: ${alert.count}`).join("\n");
		} else {
			return null;
		}
	}

	getValues(): [string, number][] {
		return Object.values(this.alertsPool)
			.sort((lh, rh) => (lh.order < rh.order ? -1 : 1))
			.map((alert) => [alert.description, alert.count]);
	}
}
