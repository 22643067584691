import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import { NavigationEnd, Router } from "@angular/router";
import { OverlayContainer } from "@angular/cdk/overlay";
import { Subscription } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { filter } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { DateTime, Interval } from "luxon";

import { environment } from "../environments/env";
import { AuthService } from "./auth/services/auth.service";
import { ActionsDialogComponent } from "./actions-dialog.component";
import { AmazonServersDialogComponent } from "./amazonServers-dialog.component";
import {
	AlertService,
	ApplicationService,
	ExchangeService,
	PushFeedService,
	FixtureService,
	LinkService,
} from "./shared/services";
import { User, SystemInfo, Exchange, Alert } from "./shared/models";
import { AlertComponent } from "./alerts/alert.component";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
	@ViewChild("alertsPanel", { static: true }) alertsPanel: MatExpansionPanel;
	@ViewChild("alertsTable", { static: true }) alertsTable: AlertComponent;

	public envName: string = environment.name;
	public envColor: string = environment["color"]
		? environment["color"]
		: environment.production
		? "production"
		: "development";
	public envProduction: boolean = environment.production;
	public exchanges: Exchange[];
	public systemInfo: SystemInfo;

	public alerts = new Array<Alert>();
	public alertButtonState = 0;
	private alertsSubscription: Subscription;

	private alertNewTimeout: ReturnType<typeof setTimeout>;
	private alertQueue = new Array<Alert>();
	private loginSubscription: Subscription;
	private pushFeedRoom = "system";
	private pushDisconnectionSubscription: Subscription;
	private pushConnectionSubscription: Subscription;
	private pushDataSubscription: Subscription;

	constructor(
		private windowTitle: Title,
		private overlayContainer: OverlayContainer,
		private authService: AuthService,
		private cookieService: CookieService,
		private alertService: AlertService,
		private exchangeService: ExchangeService,
		private pushFeedService: PushFeedService,
		private applicationService: ApplicationService,
		private fixtureService: FixtureService,
		private linkService: LinkService,
		private router: Router,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
			this.windowTitle.setTitle("Bet Robot");
		});

		this.loginSubscription = this.applicationService.onLogin.subscribe(() => {
			this.postLogin();
		});

		this.alertsSubscription = this.applicationService.alerts$.subscribe((alerts) => {
			this.alerts = alerts;
			this.refreshAlertsButton();
		});

		const theme = this.cookieService.get("theme");
		this.setDarkTheme(theme && theme === "dark");

		this.authService
			.authenticate()
			.toPromise()
			.then(() => this.postLogin())
			.catch(() => {
				//
			});
		setInterval(this.removeOldAlerts.bind(this), 1000 * 60);
	}

	ngOnDestroy() {
		this.pushDataSubscription?.unsubscribe();
		this.pushDisconnectionSubscription?.unsubscribe();
		this.pushConnectionSubscription?.unsubscribe();
		this.pushFeedService.leaveRoom(this.pushFeedRoom);
		this.pushFeedService.closeConnection();
		this.alertsSubscription?.unsubscribe();
		this.loginSubscription?.unsubscribe();
	}

	get user(): User {
		return this.applicationService.user;
	}

	get mobileView(): boolean {
		return this.applicationService.mobileView;
	}

	get darkTheme(): boolean {
		return this.applicationService.darkTheme;
	}

	get activeServersTooltip(): string {
		if (this.systemInfo && this.systemInfo.activeServers) {
			return this.systemInfo.activeServers.replace(/,/g, "\n");
		} else {
			return "";
		}
	}

	get pushfeedConnected(): boolean {
		return this.applicationService.isPushfeedConnected;
	}

	private postLogin() {
		this.applicationService
			.getSystemInfo()
			.toPromise()
			.then((systemInfo) => this.handleSystemInfo(systemInfo));
		this.exchangeService.getExchangeList().then((exchanges) => (this.exchanges = exchanges));
	}

	private handleSystemInfo(systemInfo: SystemInfo) {
		this.systemInfo = new SystemInfo(systemInfo);
		this.applicationService.setForceStop(this.systemInfo.forceStop);

		this.pushDisconnectionSubscription = this.pushFeedService.onDisconnected.subscribe(
			this.handlePushFeedDisconnection.bind(this)
		);
		this.pushConnectionSubscription = this.pushFeedService.onConnected.subscribe(this.handlePushFeedConnection.bind(this));
		this.pushFeedService.openConnection();
		this.pushDataSubscription = this.pushFeedService
			.enterRoom(this.pushFeedRoom)
			.subscribe(this.handlePushFeedData.bind(this));
	}

	private handlePushFeedDisconnection(message: string) {
		this.applicationService.setPushfeedConnected(false);
		if (!this.mobileView) {
			this.alertService.alertError(message);
		}
	}

	private handlePushFeedConnection(message: string) {
		if (!this.mobileView) {
			this.alertService.alertSuccess(message);
		}
	}

	private handlePushFeedData(data) {
		this.applicationService.setPushfeedConnected(true);
		if (data) {
			if (data.systemInfo) {
				setTimeout(() => {
					this.systemInfo.update(data.systemInfo);
					this.applicationService.setForceStop(this.systemInfo.forceStop);
					this.applicationService.setHorsesBetterStatus(this.systemInfo.ukHorsesBetter);
					this.applicationService.setSoccerBetterStatus(this.systemInfo.hbSoccerBetter);
					this.applicationService.setBetrobotBetterStatus(this.systemInfo.betrobotBetter);
				});
			}
			if (data.alerts) {
				this.applicationService.setAlerts(data.alerts.map((alert) => new Alert(alert)));
			}
			if (data.newAlert) {
				this.processAlert(new Alert(data.newAlert));
			}
			if (data.updateAlert) {
				this.processAlert(new Alert(data.updateAlert));
			}
		}
	}

	private processAlert(alert: Alert) {
		this.alertQueue.push(alert);
		if (this.alertNewTimeout) {
			clearTimeout(this.alertNewTimeout);
		}
		this.alertNewTimeout = setTimeout(() => {
			this.handleAlerts(this.alertQueue.reverse());
			this.alertQueue = [];
		}, 100);
	}

	private handleAlerts(alert: Alert[]) {
		const alerts = [
			...alert,
			...this.applicationService.alerts$.value.filter((a) => !alert.map((n) => n._id).includes(a._id)),
		].sort((lh, rh) => (lh.timestamp < rh.timestamp ? 1 : -1));
		this.applicationService.setAlerts(
			alerts.filter(
				(alert) =>
					alert.state != 2 &&
					(DateTime.now() < DateTime.fromJSDate(alert.timestamp) ||
						Interval.fromDateTimes(DateTime.fromJSDate(alert.timestamp), DateTime.now()).length("hours") < 2)
			)
		);
		this.applicationService.setClosedAlerts(alerts.filter((alert) => alert.state == 2));
		if (this.alertsPanel.expanded) {
			this.alertsTable.refresh();
		}
	}

	private refreshAlertsButton() {
		if (this.alerts.length > 0) {
			if (this.alerts.find((alert) => alert.priority >= 0 && alert.state === 0)) {
				this.alertButtonState = 2;
			} else if (this.alerts.find((alert) => alert.priority >= 0 && alert.state === 1)) {
				this.alertButtonState = 1;
			} else {
				this.alertButtonState = 0;
			}
		} else {
			this.alertButtonState = 0;
			this.alertsPanel.close();
		}
	}

	togglePanel() {
		this.alertsPanel.toggle();
		if (this.alertsPanel.expanded) {
			this.alertsTable.refresh();
		}
	}

	private removeOldAlerts() {
		if (this.applicationService.alerts$.value.length > 0) {
			const oldAlerts = this.applicationService.alerts$.value.filter(
				(alert) => Date.now() - +alert.timestamp >= 2 * 60 * 60 * 1000
			);
			if (oldAlerts.length > 0) {
				this.applicationService.setAlerts(
					this.applicationService.alerts$.value.filter((alert) => !oldAlerts.includes(alert))
				);
			}
		}
	}

	private setDarkTheme(set: boolean) {
		if (set) {
			this.applicationService.setDarkTheme(true);
			this.overlayContainer.getContainerElement().classList.add("dark-theme");
		} else {
			this.applicationService.setDarkTheme(false);
			this.overlayContainer.getContainerElement().classList.remove("dark-theme");
		}
	}

	openTXOddsPushFeed() {
		this.router.navigate([`txodds-pushfeed/filter`]);
	}

	clearParams() {
		this.openConfirmationDialog(
			"Are you sure you want to CLEAR ALL PARAMETERS?",
			"All parameters cleared from cache",
			this.applicationService.clearParams.bind(this.applicationService)
		);
	}

	switchAutoStartupOn() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE autostartup?",
			"Autostartup enabled",
			this.applicationService.switchAutoStartupOn.bind(this.applicationService)
		);
	}

	switchAutoStartupOff() {
		this.openConfirmationDialog(
			"Are you sure you want to DISABLE autostartup?",
			"Autostartup disabled",
			this.applicationService.switchAutoStartupOff.bind(this.applicationService)
		);
	}

	forceStopAll() {
		this.openConfirmationDialog(
			"Are you sure you want to STOP all matches?",
			"Matches stopped",
			this.fixtureService.forceStopAll.bind(this.fixtureService)
		);
	}

	forceResumeAll() {
		this.openConfirmationDialog(
			"Are you sure you want to RESUME all matches?",
			"Matches resumed",
			this.fixtureService.forceResumeAll.bind(this.fixtureService)
		);
	}

	enableEmergencyHaltLight() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE emergency halt LIGHT?",
			"",
			this.applicationService.enableEmergencyHaltLight.bind(this.applicationService),
			(response): string => {
				if (response.cancelBetsError) {
					return "An error occurred contacting the exchange. BETS MAY HAVE NOT BEEN CANCELLED FOR THE BETROBOT!";
				} else if (response.success) {
					return "Emergency Halt LIGHT activated. System disabled and Bets for Horses system kept open where needed.";
				}
			}
		);
	}

	enableEmergencyHalt() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE emergency halt?",
			"",
			this.applicationService.enableEmergencyHalt.bind(this.applicationService),
			(response): string => {
				if (response.cancelBetsError) {
					return "An error occurred contacting the exchange. BETS MAY HAVE NOT BEEN CANCELLED!";
				} else if (response.success) {
					return "Emergency Halt activated. System disabled.";
				}
			}
		);
	}

	disableEmergencyHalt() {
		this.openConfirmationDialog(
			"Are you sure you want to REMOVE emergency halt?",
			"Emergency Halt removed. System enabled.",
			this.applicationService.disableEmergencyHalt.bind(this.applicationService)
		);
	}

	enableSPEvents() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE SP Events?",
			"SP Events enabled",
			this.applicationService.enableSPEvents.bind(this.applicationService)
		);
	}

	disableSPEvents() {
		this.openConfirmationDialog(
			"Are you sure you want to DISABLE SP Events?",
			"SP Events disabled",
			this.applicationService.disableSPEvents.bind(this.applicationService)
		);
	}

	enableBBEvents() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE BB Events?",
			"BB Events enabled",
			this.applicationService.enableBBEvents.bind(this.applicationService)
		);
	}

	disableBBEvents() {
		this.openConfirmationDialog(
			"Are you sure you want to DISABLE BB Events?",
			"BB Events disabled",
			this.applicationService.disableBBEvents.bind(this.applicationService)
		);
	}

	cancelBets() {
		this.openConfirmationDialog(
			"Are you sure you want to cancel all bets directly on the exchanges?",
			"",
			this.applicationService.cancelAllBets.bind(this.applicationService),
			(response): string => {
				if (response.error) {
					return "An error occurred contacting the exchange. BETS MAY HAVE NOT BEEN CANCELLED!";
				} else if (response.success) {
					return "All bets have been cancelled";
				}
			}
		);
	}

	enableBetrobotBetter() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE the Betrobot Better?",
			"",
			this.applicationService.enableBetrobotBetter.bind(this.applicationService),
			(response): string => {
				if (response.error) {
					return "An error occurred enabling the Betrobot Better!";
				} else if (response.success) {
					return "Betrobot Better enabled";
				}
			}
		);
	}

	disableBetrobotBetter() {
		this.openConfirmationDialog(
			"Are you sure you want to DISABLE the Betrobot Better?",
			"",
			this.applicationService.disableBetrobotBetter.bind(this.applicationService),
			(response): string => {
				if (response.error) {
					return "An error occurred disabling the Betrobot Better!";
				} else if (response.success) {
					return "Betrobot Better disabled";
				}
			}
		);
	}

	enableUKHorsesBetter() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE the UK Horses Better?",
			"",
			this.applicationService.enableUKHorsesBetter.bind(this.applicationService),
			(response): string => {
				if (response.error) {
					return "An error occurred enabling the Horses Better!";
				} else if (response.success) {
					return "Horses Better enabled";
				}
			}
		);
	}

	disableUKHorsesBetter() {
		this.openConfirmationDialog(
			"Are you sure you want to DISABLE the UK Horses Better?",
			"",
			this.applicationService.disableUKHorsesBetter.bind(this.applicationService),
			(response): string => {
				if (response.error) {
					return "An error occurred disabling the Horses Better!";
				} else if (response.success) {
					return "Horses Better disabled";
				}
			}
		);
	}

	enableSoccerPreMatchBetter() {
		this.openConfirmationDialog(
			"Are you sure you want to ENABLE the Soccer pre-match Better?",
			"",
			this.applicationService.enableSoccerPreMatchBetter.bind(this.applicationService),
			(response): string => {
				if (response.error) {
					return "An error occurred enabling the Soccer pre-match Better!";
				} else if (response.success) {
					return "Soccer pre-match Better enabled";
				}
			}
		);
	}

	disableSoccerPreMatchBetter() {
		this.openConfirmationDialog(
			"Are you sure you want to DISABLE the Soccer pre-match Better?",
			"",
			this.applicationService.disableSoccerPreMatchBetter.bind(this.applicationService),
			(response): string => {
				if (response.error) {
					return "An error occurred disabling the Soccer pre-match Better!";
				} else if (response.success) {
					return "Soccer pre-match Better disabled";
				}
			}
		);
	}

	private openConfirmationDialog(prompt: string, message: string, action, successHandler = null) {
		this.dialog.open(ActionsDialogComponent, {
			data: {
				prompt: prompt,
				message: message,
				action: action,
				successHandler: successHandler,
			},
			disableClose: true,
		});
	}

	openSoccerOverview() {
		this.linkService.openLink("Soccer Overview", null);
	}

	openSoccerSummary(exchangeId: number, setCode: string) {
		this.linkService.openLink("Summary", `${exchangeId}:${setCode}`);
	}

	openSoccerSummaryOU(exchangeId: number) {
		this.linkService.openLink("Summary (OU)", `${exchangeId}`);
	}

	openSoccerSummaryOUArchive(exchangeId: number) {
		this.linkService.openLink("Summary (OU Archive)", `${exchangeId}`);
	}

	openSoccerSummaryAllSets(exchangeId: number) {
		this.linkService.openLink("Summary (All)", `${exchangeId}`);
	}

	openSoccerSummaryAllSetsArchive(exchangeId: number) {
		this.linkService.openLink("Summary (All Archive)", `${exchangeId}`);
	}

	openSoccerSummaryArchive(exchangeId: number, setCode: string) {
		this.linkService.openLink("Summary (Archive)", `${exchangeId}:${setCode}`);
	}

	openRacesOverview() {
		this.linkService.openLink("Races Overview", null);
	}

	openRacesSummary() {
		this.linkService.openLink("Races Summary", null);
	}

	openRacesSummaryArchive() {
		this.linkService.openLink("Races Summary (Archive)", null);
	}

	openRacesSummaryAll() {
		this.linkService.openLink("Races Summary (All)", null);
	}

	openOtherSportOverview() {
		this.linkService.openLink("Other Sport Overview", null);
	}

	openOtherSportSummary() {
		this.linkService.openLink("Other Sport Summary", null);
	}

	openOtherSportSummaryArchive() {
		this.linkService.openLink("Other Sport Summary (Archive)", null);
	}

	openOtherSportSummaryAll() {
		this.linkService.openLink("Other Sport Summary (All)", null);
	}

	openTicker() {
		this.linkService.openLink("Ticker", null);
	}

	openTickerArchive() {
		this.linkService.openLink("Ticker (Archive)", null);
	}

	amazonServers() {
		this.dialog.open(AmazonServersDialogComponent, {
			disableClose: false,
		});
	}
}
