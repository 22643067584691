import { LiabilityLine } from "./liabilityLine";

export class Liability {
	type: string;
	lines: LiabilityLine[];

	constructor(type: string, liability: LiabilityLine[] | object) {
		this.type = type;
		this.lines = new Array<LiabilityLine>();

		if (Array.isArray(liability)) {
			liability.forEach((l) => {
				if (l.line !== "hashCode") {
					this.lines.push(new LiabilityLine(l.line, l.value));
				}
			});
		} else {
			Object.keys(liability).forEach((line) => {
				if (line !== "hashCode") {
					this.lines.push(new LiabilityLine(line, liability[line]));
				}
			});
		}
	}

	add(lines: LiabilityLine[]) {
		lines.forEach((line) => {
			const index = this.lines.findIndex((l) => l.line === line.line);
			if (index > -1) {
				this.lines[index].value += line.value;
			}
		});
	}

	update(liability): boolean {
		let updated = false;

		Object.keys(liability).forEach((line) => {
			const index = this.lines.findIndex((l) => l.line === line);
			if (index > -1) {
				if (this.lines[index].value !== liability[line]) {
					this.lines[index].value = liability[line];
					updated = true;
				}
			} else {
				this.lines.push(new LiabilityLine(line, liability[line]));
				updated = true;
			}
		});

		return updated;
	}
}
