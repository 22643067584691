import { Fixture as SharedFixture, ExchangeList, Alert, Parameter, FixtureNote } from "../../models";

import { Calculation } from "./calculation";
import { Statistics } from "./statistics";
import { ScoreEvent } from "./scoreEvent";
import { OddsMarket } from "./oddsMarket";
import { OddsLine } from "./oddsLine";
import { Bet } from "./bet";
import { EventEmitter } from "@angular/core";

export class Fixture extends SharedFixture {
	currentTime: Date;
	lastRun: Date;
	calcTime: number;
	follow: number;
	setId: string;
	paramsSetCode: string;
	paramsSetName: string;
	statistics: Statistics;
	scoreEvents: ScoreEvent[];
	calculations: Calculation[];
	odds: OddsMarket[];
	bets: Bet[];
	alerts: Alert[];
	notes: FixtureNote[];
	txLastUpdate: Date;
	injuryTime: number;

	current_tge: number;
	current_sup: number;

	selectedSets: string[];

	onEventsUpdate = new EventEmitter();
	onBetsUpdate = new EventEmitter<Bet>();

	private _hasBFPrices = false;

	constructor(fixture: Fixture, exchangeList: ExchangeList) {
		super(fixture, exchangeList);

		this.odds = new Array<OddsMarket>();

		this.setId = fixture.setId;
		if (fixture.lastRun) {
			this.lastRun = new Date(fixture.lastRun);
		}
		this.calcTime = fixture.calcTime || 0;
		this.currentTime = fixture.currentTime;
		this.follow = fixture.follow || 0;
		this.paramsSetCode = fixture.paramsSetCode;
		this.paramsSetName = fixture.paramsSetName;

		this.current_tge = fixture.current_tge || 0;
		this.current_sup = fixture.current_sup || 0;

		this.selectedSets = fixture.selectedSets;

		this.statistics = new Statistics(fixture.statistics);
		this.txLastUpdate = fixture.txLastUpdate || null;
		this.injuryTime = fixture.injuryTime || 0;

		this.scoreEvents = new Array<ScoreEvent>();
		this.updateEvents(fixture.scoreEvents);

		this.calculations = new Array<Calculation>();
		if (fixture.calculations) {
			fixture.calculations.map((calculation) => this.calculations.push(new Calculation(calculation, exchangeList)));
		}

		this.bets = new Array<Bet>();
		this.updateBets(fixture.bets);

		this.alerts = new Array<Alert>();
		if (fixture.alerts) {
			fixture.alerts.map((alert) => this.alerts.push(new Alert(alert)));
		}

		this.notes = new Array<FixtureNote>();
		if (fixture.notes) {
			fixture.notes.map((note) => this.notes.push(new FixtureNote(note)));
		}
	}

	get customParams(): number {
		const params = this.parameters.find((param) => param.setId === this.setId);
		if (params && params.custom) {
			return params.custom.length;
		} else {
			return 0;
		}
	}

	get customParamsTooltip(): string {
		const params = this.parameters.find((param) => param.setId === this.setId);

		let customParamsTooltip = "";
		if (params && params.custom && params.custom.length > 0) {
			for (const param of params.custom.sort(this.paramSorter)) {
				if (param.markettype !== "general") {
					let marketType = this._exchangeList.translateMarketName(+param.markettype);
					if (param.market && param.market !== marketType) {
						marketType += " " + param.market;
					}
					customParamsTooltip += `${param.key} -- ${marketType}: ${param.value}\n`;
				} else {
					customParamsTooltip += `${param.key}: ${param.value}\n`;
				}
			}
		}

		return customParamsTooltip;
	}

	get serverTooltip(): string {
		if (this.halted) {
			return "Game halted";
		} else if (this.server) {
			return "Running on server: " + this.server;
		} else {
			return "";
		}
	}

	get halted(): boolean {
		return this.halt > 0 ? true : false;
	}

	get following(): boolean {
		return this.follow > 0 ? true : false;
	}

	get followingTooltip(): string {
		if (this.following) {
			return "Currently following";
		} else {
			return "Not following";
		}
	}

	get calcTimeFormatted(): string {
		if (this.calcTime) {
			return this.calcTime + "s";
		} else {
			return null;
		}
	}

	get hasBFPrices(): boolean {
		if (this.logging) {
			if (!this._hasBFPrices) {
				if (this.calculations && this.calculations.length > 0) {
					this._hasBFPrices =
						this.calculations.filter((calc) => calc.betfair_back_depth && calc.betfair_back_depth !== "-").length > 0;
				}
			}
		} else {
			this._hasBFPrices = false;
		}

		return this._hasBFPrices;
	}

	isMarketAvailable(marketCode: string): boolean {
		if (this.unAvailableMarkets) {
			return (this.unAvailableMarkets as string).split(";").indexOf(marketCode) === -1;
		} else {
			return true;
		}
	}

	addOdds(odds: OddsLine[]) {
		for (const odd of odds) {
			let market = this.odds.find((entry) => entry.id === odd.marketTypeId);
			if (!market) {
				market = new OddsMarket(odd.marketTypeId, odd.marketType);
				market.add(odd);
				this.odds.push(market);
				this.odds.sort(this.oddsSorter);
			} else {
				market.add(odd);
			}
		}

		this.txLastUpdate = new Date();
	}

	updateOdds(odds) {
		for (const odd of odds) {
			let market = this.odds.find((entry) => entry.id === odd.marketTypeId);
			if (!market && odd.changes && Object.keys(odd.changes).filter((field) => field.startsWith("bookie_")).length > 0) {
				market = new OddsMarket(odd.marketTypeId, odd.marketType);
				this.odds.push(market);
				this.odds.sort(this.oddsSorter);
			}

			if (market) {
				market.update(odd);

				if (market.lineCount === 0) {
					this.odds.splice(this.odds.indexOf(market), 1);
				}
			}
		}

		this.txLastUpdate = new Date();
	}

	removeOdds(odds: string[]) {
		for (const oddId of odds) {
			for (const market of this.odds) {
				if (market.hasLine(oddId)) {
					market.remove(oddId);

					if (market.lineCount === 0) {
						this.odds.splice(this.odds.indexOf(market), 1);
					}
					break;
				}
			}
		}

		this.txLastUpdate = new Date();
	}

	updateEvents(events: ScoreEvent[]) {
		if (events) {
			events.forEach((event) => {
				const index = this.scoreEvents.findIndex((se) => se.hashCode === event.hashCode);
				if (index > -1) {
					this.scoreEvents[index] = new ScoreEvent(event);
				} else {
					this.scoreEvents.push(new ScoreEvent(event));
				}
			});

			this.scoreEvents.sort((lh: ScoreEvent, rh: ScoreEvent) => (lh.time < rh.time ? 1 : -1));
			this.onEventsUpdate.emit(null);
		}
	}

	updateBets(bets) {
		if (bets) {
			if (!Array.isArray(bets)) {
				bets = [bets];
			}

			bets.forEach((bet) => {
				const index = this.bets.findIndex((b) => b.id === bet.id);
				if (index > -1) {
					this.bets[index] = new Bet(bet);
				} else {
					const newBet = new Bet(bet);
					this.bets.push(newBet);
					this.onBetsUpdate.emit(newBet);
				}
			});
		}
	}

	private paramSorter(lh: Parameter, rh: Parameter) {
		if (lh.key === rh.key) {
			if (lh.markettype === rh.markettype) {
				return +lh.market < +rh.market ? -1 : 1;
			} else {
				return lh.markettype < rh.markettype ? -1 : 1;
			}
		} else {
			return lh.key < rh.key ? -1 : 1;
		}
	}

	private oddsSorter(lh: OddsMarket, rh: OddsMarket): number {
		return lh.id < rh.id ? 1 : -1;
	}
}
