<div class="layout" [class.dark-theme]="darkTheme">
	<mat-toolbar class="header" [class]="envColor" *ngIf="user && !mobileView">
		<span id="title" routerLink="/">Bet Robot ({{ envName }})</span>

		<button mat-button [matMenuTriggerFor]="soccerMenu">
			Soccer <mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #soccerMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="openSoccerOverview()">Overview</button>
			<button mat-menu-item *ngFor="let exchange of exchanges" [matMenuTriggerFor]="soccerSummaryMenu"
				[matMenuTriggerData]="{exchange: exchange}">Summary</button>
			<button mat-menu-item *ngFor="let exchange of exchanges" [matMenuTriggerFor]="soccerSummaryArchiveMenu"
				[matMenuTriggerData]="{exchange: exchange}">Archive</button>
			<button mat-menu-item [routerLink]="['/reports/scraperlinks']">Scraper Links</button>
		</mat-menu>
		<mat-menu #soccerSummaryMenu="matMenu">
			<ng-template let-exchange="exchange" matMenuContent>
				<button mat-menu-item (click)="openSoccerSummaryAllSets(exchange.id)">All Sets</button>
				<button mat-menu-item (click)="openSoccerSummaryOU(exchange.id)">OU Sets</button>
				<mat-divider></mat-divider>
				<ng-container *ngFor="let set of exchange.sets">
					<button mat-menu-item (click)="openSoccerSummary(exchange.id, set.code)">{{set.name}}</button>
				</ng-container>
			</ng-template>
		</mat-menu>
		<mat-menu #soccerSummaryArchiveMenu="matMenu">
			<ng-template let-exchange="exchange" matMenuContent>
				<button mat-menu-item (click)="openSoccerSummaryAllSetsArchive(exchange.id)">All Sets</button>
				<button mat-menu-item (click)="openSoccerSummaryOUArchive(exchange.id)">OU Sets</button>
				<mat-divider></mat-divider>
				<button mat-menu-item *ngFor="let set of exchange.sets"
					(click)="openSoccerSummaryArchive(exchange.id, set.code)">{{set.name}}</button>
			</ng-template>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="racesMenu">
			Horses / Greyhounds <mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #racesMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="openRacesOverview()">Overview</button>
			<button mat-menu-item (click)="openRacesSummary()">Summary</button>
			<button mat-menu-item (click)="openRacesSummaryArchive()">Archive</button>
			<button mat-menu-item (click)="openRacesSummaryAll()">Profit/Loss</button>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="otherSportMenu">
			Other Sport <mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #otherSportMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="openOtherSportOverview()">Overview</button>
			<button mat-menu-item (click)="openOtherSportSummary()">Summary</button>
			<button mat-menu-item (click)="openOtherSportSummaryArchive()">Archive</button>
			<button mat-menu-item (click)="openOtherSportSummaryAll()">Profit/Loss</button>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="tickerMenu">
			Ticker
			<mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #tickerMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="openTicker()">Live</button>
			<button mat-menu-item (click)="openTickerArchive()">Archive</button>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="preMatchMenu">
			Leagues
			<mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #preMatchMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item [routerLink]="['/prematch-stats']">Statistics</button>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="reportsMenu">
			Reports
			<mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #reportsMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item [routerLink]="['/reports/alerts']">Alerts</button>
			<button mat-menu-item [routerLink]="['/reports/activities']">Activity Stats</button>
			<button mat-menu-item [routerLink]="['/reports/bets']">Bets</button>
			<button mat-menu-item [routerLink]="['/reports/betstops']">Betstops</button>
			<button mat-menu-item [routerLink]="['/reports/early_exits']">Early Exits</button>
			<button mat-menu-item [routerLink]="['/reports/history']">History</button>
			<button mat-menu-item [routerLink]="['/reports/horses']">Horses</button>
			<button mat-menu-item [routerLink]="['/reports/betcount']">Betcount</button>
			<button mat-menu-item [routerLink]="['/reports/transactions']">BF Transactions</button>
			<button mat-menu-item [routerLink]="['/reports/balance']">Balances</button>
			<button mat-menu-item [matMenuTriggerFor]="auditMenu">
				Audit logs
			</button>
		</mat-menu>

		<mat-menu #auditMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item [routerLink]="['/reports/betrobot/parameter_edits']">Soccer Parameters</button>
			<button mat-menu-item [routerLink]="['/reports/horsebet/parameter_edits']">UK Horses Parameters</button>
			<button mat-menu-item [routerLink]="['/reports/horsebet/audit']">UK Horses Events Audit</button>
			<button mat-menu-item [routerLink]="['/reports/horsebet/schedule_audit']">UK Horses Schedule Audit</button>
			<button mat-menu-item [routerLink]="['/reports/betrobot/audit']">Betrobot Events Audit</button>
			<button mat-menu-item [routerLink]="['/reports/cluster']">Cluster logs</button>
			<button mat-menu-item *ngIf="user && user.role==='admin'" [routerLink]="['/reports/logins']">Logins</button>
			<button mat-menu-item *ngIf="user && user.role==='admin'" [routerLink]="['/reports/user_edits']">User
				Edits</button>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="settingsMenu">
			Settings
			<mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #settingsMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item [matMenuTriggerFor]="settingsSoccerMenu">
				InPlay
			</button>
			<mat-menu #settingsSoccerMenu="matMenu" [overlapTrigger]="false">
				<button mat-menu-item [routerLink]="['settings/parameters']">
					Parameters
				</button>

				<button mat-menu-item [routerLink]="['settings/bookies']">
					Bookies
				</button>

				<button mat-menu-item [matMenuTriggerFor]="tablesMenu">
					Tables
				</button>
				<mat-menu #tablesMenu="matMenu" [overlapTrigger]="false">
					<button mat-menu-item [routerLink]="['settings/tables/deductionTable']">Deduction</button>
					<button mat-menu-item [routerLink]="['settings/tables/marketDepthTable']">Market Depth</button>
					<button mat-menu-item [routerLink]="['settings/tables/drawWeight']">Draw Weighting</button>
					<button mat-menu-item [routerLink]="['settings/tables/factorAWCC']">AWCC</button>
					<button mat-menu-item [routerLink]="['settings/tables/factorLeague']">Leagues</button>
					<button mat-menu-item [routerLink]="['settings/tables/factorMatch']">Match Amount</button>
					<button mat-menu-item [routerLink]="['settings/tables/badTGE']">Bad TGE</button>
				</mat-menu>

				<!-- <button mat-menu-item [routerLink]="['settings/servers']">
					Servers
				</button> -->
			</mat-menu>

			<button mat-menu-item [matMenuTriggerFor]="settingsRacesUKMenu">
				PreMatch
			</button>
			<mat-menu #settingsRacesUKMenu="matMenu" [overlapTrigger]="false">
				<button mat-menu-item [routerLink]="['horsebet/settings/UK/parameters/sets']">
					Sets Parameters
				</button>
				<button mat-menu-item [routerLink]="['horsebet/settings/UK/parameters/markets']">
					Markets Parameters
				</button>

				<button mat-menu-item [matMenuTriggerFor]="horsesTablesMenu">
					Tables
				</button>
				<mat-menu #horsesTablesMenu="matMenu" [overlapTrigger]="false">
					<button mat-menu-item [routerLink]="['horsebet/settings/UK/deductions']">Deductions</button>
					<button mat-menu-item [routerLink]="['horsebet/settings/UK/tables/venueFactor']">Venue / League
						Factor</button>
					<button mat-menu-item [routerLink]="['horsebet/settings/UK/tables/tge']">TGE</button>
				</mat-menu>
			</mat-menu>

			<!-- <button mat-menu-item [matMenuTriggerFor]="settingsRacesFHMenu">
				FH-Horses
			</button> -->
			<mat-menu #settingsRacesFHMenu="matMenu" [overlapTrigger]="false">
				<button mat-menu-item [routerLink]="['horsebet/settings/FH/parameters']">
					Parameters
				</button>
				<button mat-menu-item [routerLink]="['horsebet/settings/FH/deductions']">
					Deductions
				</button>
			</mat-menu>

			<button *ngIf="envProduction" mat-menu-item (click)="amazonServers()">
				Amazon Servers
			</button>

			<button mat-menu-item *ngIf="user && user.role==='admin'" [routerLink]="['/users']">
				Users
			</button>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="linksMenu">
			Links
			<mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #linksMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)=openTXOddsPushFeed()>
				Bookies Odds Data
			</button>
			<button mat-menu-item routerLink="/betfair-historic-data">
				Betfair Historic Data
			</button>
			<button mat-menu-item routerLink="/betfair-catalogue">
				Betfair Catalogue
			</button>
			<button mat-menu-item routerLink="/gold-finger-monitor">
				Gold Finger Monitor
			</button>
		</mat-menu>

		<button mat-button [matMenuTriggerFor]="actionsMenu">
			Actions
			<mat-icon>expand_more</mat-icon>
		</button>
		<mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="forceStopAll()">Force Stop All</button>
			<button mat-menu-item (click)="forceResumeAll()">Force Resume All</button>
			<button mat-menu-item [matMenuTriggerFor]="autoStartMenu">Auto Startup</button>
			<button mat-menu-item [matMenuTriggerFor]="spEventsMenu">SP Events</button>
			<button mat-menu-item [matMenuTriggerFor]="bbEventsMenu">BB Events</button>
			<button mat-menu-item (click)="clearParams()">Clear Params</button>
			<button mat-menu-item (click)="cancelBets()">Cancel All Bets</button>
			<button mat-menu-item [matMenuTriggerFor]="betrobotBetterMenu">Betrobot Better</button>
			<button mat-menu-item [matMenuTriggerFor]="horsesBetterMenu">Horses (UK) Better</button>
			<!-- <button mat-menu-item [matMenuTriggerFor]="soccerBetterMenu">Soccer PreMatch Better</button> -->
			<button mat-menu-item (click)="enableEmergencyHaltLight()">Enable Emergency Halt Light</button>
			<button mat-menu-item (click)="enableEmergencyHalt()">Enable Emergency Halt</button>
			<button mat-menu-item (click)="disableEmergencyHalt()">Disable Emergency Halt</button>
		</mat-menu>
		<mat-menu #autoStartMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="switchAutoStartupOn()">Enable</button>
			<button mat-menu-item (click)="switchAutoStartupOff()">Disable</button>
		</mat-menu>
		<mat-menu #spEventsMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="enableSPEvents()">Enable</button>
			<button mat-menu-item (click)="disableSPEvents()">Disable</button>
		</mat-menu>
		<mat-menu #bbEventsMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="enableBBEvents()">Enable</button>
			<button mat-menu-item (click)="disableBBEvents()">Disable</button>
		</mat-menu>
		<mat-menu #betrobotBetterMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="enableBetrobotBetter()">Enable</button>
			<button mat-menu-item (click)="disableBetrobotBetter()">Disable</button>
		</mat-menu>
		<mat-menu #horsesBetterMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="enableUKHorsesBetter()">Enable</button>
			<button mat-menu-item (click)="disableUKHorsesBetter()">Disable</button>
		</mat-menu>
		<mat-menu #soccerBetterMenu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item (click)="enableSoccerPreMatchBetter()">Enable</button>
			<button mat-menu-item (click)="disableSoccerPreMatchBetter()">Disable</button>
		</mat-menu>

		<!-- <button mat-button (click)=openTXOddsPushFeed()>
			TxOdds Pushfeed
		</button>
		<button mat-button routerLink="/betfair-historic-data">
			Betfair Historic Data
		</button> -->

		<div class="status-icons">
			<mat-chip-list [ngClass]="{'disabled': !pushfeedConnected}">
				<div class="container" *ngIf="systemInfo">
					<div class="container" *ngIf="systemInfo.emergency">
						<mat-chip class="info warn">
							<mat-icon>warning</mat-icon>
							System Halted!
						</mat-chip>
					</div>
					<div class="container"
						*ngIf="!systemInfo.emergency && (systemInfo.ukHorsesBetter !== 'enabled' || systemInfo.hbSoccerBetter !== 'enabled')">
						<mat-chip class="info alert">
							<span *ngIf="systemInfo.ukHorsesBetter !== 'enabled'" matTooltip="Horses Better">HB:
								{{systemInfo.ukHorsesBetter}}</span>&nbsp;
							<!-- <span *ngIf="systemInfo.hbSoccerBetter !== 'enabled'" matTooltip="Pre-Match Soccer Better">PSB: {{systemInfo.hbSoccerBetter}}</span> -->
						</mat-chip>
					</div>
					<div class="container" *ngIf="!systemInfo.emergency && (systemInfo.betrobotBetter !== 'enabled')">
						<mat-chip class="info alert">
							<span *ngIf="systemInfo.betrobotBetter !== 'enabled'" matTooltip="Betrobot Better">BR:
								{{systemInfo.betrobotBetter}}</span>&nbsp;
						</mat-chip>
					</div>
					<div class="container" *ngIf="!systemInfo.emergency">
						<mat-chip *ngIf="systemInfo.alerts" class="info feed warn"
							[matTooltip]="systemInfo.alertsTooltip">
							{{systemInfo.alerts}}
						</mat-chip>
					</div>
					<div class="container">
						<mat-chip class="info feed"
							[ngClass]="{'warn': !systemInfo.autostart, 'ok': systemInfo.autostart}"
							[matTooltip]="'Autostart: ' + (systemInfo.autostart ? 'enabled' : 'disabled')">
							AS
						</mat-chip>
						<mat-chip class="info feed" *ngIf="systemInfo.spEvents !== 'enabled'" class="info alert"
							matTooltip="SP Events disabled">
							SP
						</mat-chip>
						<mat-chip class="info feed" *ngIf="systemInfo.bbEvents !== 'enabled'" class="info alert"
							matTooltip="BB Events disabled">
							BB
						</mat-chip>
						<!-- <mat-chip class="info feed" [ngClass]="{'alert': systemInfo.txFeed !== 'enabled', 'ok': systemInfo.txFeed === 'enabled'}" [matTooltip]="'TX Pushfeed: ' + systemInfo.txFeed">
							TX
						</mat-chip>
						<mat-chip class="info feed" [ngClass]="{'warn': systemInfo.bfFeed !== 'push', 'ok': systemInfo.bfFeed === 'push'}" [matTooltip]="'BF Feed: ' + systemInfo.bfFeed">
							BF
						</mat-chip> -->
					</div>
				</div>
				<app-user></app-user>
			</mat-chip-list>
			<div class="staging-status" *ngIf="envProduction && systemInfo && systemInfo.activeServers"
				[matTooltip]="activeServersTooltip">
				Staging Servers<br />On
			</div>
		</div>
	</mat-toolbar>
	<div class="content">
		<router-outlet></router-outlet>
		<button mat-icon-button class="alerts-button"
			[ngClass]="{'partial': alertButtonState === 1, 'full': alertButtonState === 2}" (click)="togglePanel()"
			*ngIf="user && user.authenticated && !mobileView">
			<mat-icon>warning</mat-icon>
		</button>
	</div>
	<mat-expansion-panel #alertsPanel class="alerts-panel" [@.disabled]="alerts.length >= 50">
		<app-alerts #alertsTable [data]="alerts" mode="live"></app-alerts>
	</mat-expansion-panel>
</div>