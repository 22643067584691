import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class CodeInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const codeToken = localStorage.getItem("codeToken");
		const authRequest = req.clone({
			headers: req.headers.set("code-token", [codeToken]),
		});
		return next.handle(authRequest);
	}
}
