export class Account {
	public id: number;
	public name: string;

	constructor(account: Account) {
		if (account != null) {
			this.id = account.id;
			this.name = account.name;
		}
	}
}
