import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/env";
import { User } from "../../shared/models";
import { ApplicationService } from "../../shared/services";

@Injectable()
export class AuthService {
	user: User;

	constructor(private http: HttpClient, private systemService: ApplicationService) {}

	login(user) {
		return this.http.post<string>(environment.AUTH_ENDPOINT + "/login", user);
	}

	register(password: string) {
		return this.http.post(environment.AUTH_ENDPOINT + "/register", {
			password: password,
		});
	}

	authenticate(): Observable<User> {
		return this.http.get(environment.AUTH_ENDPOINT + "/authenticate").pipe(
			map((user: User) => {
				this.user = new User(user);
				this.systemService.setUser(this.user);
				return this.user;
			})
		);
	}

	logout() {
		return this.http.post(environment.AUTH_ENDPOINT + "/logout", this.user).pipe(
			map(() => {
				this.systemService.setUser(null);
				delete this.user;
			})
		);
	}

	verifyCode() {
		return this.http.get(environment.AUTH_ENDPOINT + "/verifyCode");
	}

	validateCode(code: string) {
		return this.http.post<string>(environment.AUTH_ENDPOINT + "/validateCode", {
			code,
		});
	}

	signRequest() {
		return this.http.get(environment.AUTH_ENDPOINT + "/signRequest");
	}

	verifySignature(signature) {
		return this.http.post<string>(environment.AUTH_ENDPOINT + "/verifySignature", { signature });
	}
}
