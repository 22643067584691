import { BFPricesSelection } from "./bfPricesSelection";
import { Calculation } from "./calculation";

export class BFPricesMarket {
	private _entries: BFPricesSelection[];
	name: string;

	constructor(data: Calculation = null) {
		this._entries = new Array<BFPricesSelection>();
		if (data) {
			this.update(data);
		}
	}

	get entries(): BFPricesSelection[] {
		const list = Array.from(this._entries.sort(this.dataSorter.bind(this)));

		if (list.length > 0) {
			const totals = new BFPricesSelection();
			totals.totals = true;
			totals.back = this.totalBFBackPercentage;
			totals.lay = this.totalBFLayPercentage;
			totals.backIncr = this.totalBFBackIncrPercentage;
			totals.layIncr = this.totalBFLayIncrPercentage;
			totals.backDepth = this.totalBFBackDepthPercentage;
			totals.layDepth = this.totalBFLayDepthPercentage;
			totals.backOptimal = this.totalBFBackOptimalPercentage;
			totals.layOptimal = this.totalBFLayOptimalPercentage;
			totals.layBetsRemoved = this.totalBFLayBetsRemovedPercentage;
			totals.backBetsRemoved = this.totalBFBackBetsRemovedPercentage;
			totals.hundredPercentPrice = this.totalHundredPercentPrice;
			totals.hundredPercentPriceCorrectedBack = this.totalHundredPercentPriceCorrectedBack;
			totals.hundredPercentPriceCorrectedLay = this.totalHundredPercentPriceCorrectedLay;
			list.push(totals);
		}

		return list;
	}

	update(data: Calculation) {
		if (data) {
			if (!this.name) {
				this.name = data.fullMarketName;
			}

			if (data.hasBfPrices) {
				const existing = this._entries.find((selection) => selection.name === data.selection);
				if (existing) {
					existing.update(data);
				} else {
					this._entries.push(new BFPricesSelection(data));
				}
			}
		}
	}

	clear() {
		for (const entry of this._entries) {
			entry.clear();
		}
	}

	get hasRecords(): boolean {
		return this._entries.length > 0;
	}

	get totalBFBackPercentage(): number {
		return this.totalPercentage("back");
	}

	get totalBFLayPercentage(): number {
		return this.totalPercentage("lay");
	}

	get totalBFBackIncrPercentage(): number {
		return this.totalPercentage("backIncr");
	}

	get totalBFLayIncrPercentage(): number {
		return this.totalPercentage("layIncr");
	}

	get totalBFBackDepthPercentage(): number {
		return this.totalPercentage("backDepth");
	}

	get totalBFLayDepthPercentage(): number {
		return this.totalPercentage("layDepth");
	}

	get totalBFLayOptimalPercentage(): number {
		return this.totalPercentage("layOptimal");
	}

	get totalBFLayBetsRemovedPercentage(): number {
		return this.totalPercentage("layBetsRemoved");
	}

	get totalBFBackBetsRemovedPercentage(): number {
		return this.totalPercentage("backBetsRemoved");
	}

	get totalBFBackOptimalPercentage(): number {
		return this.totalPercentage("backOptimal");
	}

	get totalHundredPercentPrice(): number {
		return this.totalPercentage("hundredPercentPrice");
	}

	get totalHundredPercentPriceCorrectedBack(): number {
		return this.totalPercentage("hundredPercentPriceCorrectedBack");
	}

	get totalHundredPercentPriceCorrectedLay(): number {
		return this.totalPercentage("hundredPercentPriceCorrectedLay");
	}

	private totalPercentage(field: string) {
		let total = 0;
		for (const runner of this._entries) {
			if (field in runner && !Number.isNaN(runner[field]) && runner[field]) {
				total += this.percentage(runner[field]);
			}
		}
		return total / 100;
	}

	private percentage(value: number) {
		return (1 / value) * 100;
	}

	private dataSorter(a: BFPricesSelection, b: BFPricesSelection): number {
		// if (this.name === "1X2") {
		// 	if (a.name.toString() === "1" || b.name.toString() === "2") {
		// 		return -1;
		// 	} else if (a.name.toString() === "2" || b.name.toString() === "1") {
		// 		return 1;
		// 	} else {
		// 		return -1;
		// 	}
		// } else {
		return a.name < b.name ? -1 : 1;
		// }
	}
}
