<h3 mat-dialog-title
	cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle>
	<span>Confirm</span>
</h3>
<mat-dialog-content>
	<span>{{data.caption}}</span>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="buttons">
		<button mat-button mat-dialog-close color="primary">No</button>
		<button mat-button (click)="proceed()" color="primary" mat-raised-button>Yes</button>
	</div>
</mat-dialog-actions>
