import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { FixtureService } from "../../shared/services";
import { FixtureNote } from "../../shared/models";

@Component({
	selector: "app-reports-note-edit-dialog",
	templateUrl: "note-edit-dialog.component.html",
	styleUrls: ["./note-edit-dialog.component.scss"],
})
export class NoteEditDialogComponent implements OnInit {
	updateForm: UntypedFormGroup;

	constructor(
		private fixtureService: FixtureService,
		private formBuilder: UntypedFormBuilder,
		private dialogRef: MatDialogRef<NoteEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: FixtureNote
	) {}

	ngOnInit() {
		this.updateForm = this.formBuilder.group({
			note: [this.data.note, Validators.required],
		});
	}

	save(): void {
		if (this.updateForm.status === "VALID") {
			this.data.note = this.updateForm.controls.note.value;
			this.fixtureService
				.updateNote(this.data)
				.toPromise()
				.then((response) => {
					this.dialogRef.close(response);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}
}
