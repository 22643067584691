import { BrowserModule, Title } from "@angular/platform-browser";
import { enableProdMode, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";

import { CookieService } from "ngx-cookie-service";

import { environment } from "../environments/env";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { NotFoundComponent } from "./not-found/not-found.component";
import { MaterialModule } from "./material.module";

import {
	AlertService,
	ApplicationService,
	ExchangeService,
	LinkService,
	PushFeedService,
	FixtureService,
	ReportService,
	BetService,
	ScraperService,
} from "./shared/services";

import { PipesModule } from "./pipes.module";
import { AuthService, AuthGuardService, CodeGuardService, AdminGuardService, DuoGuardService } from "./auth/services";
import { AuthInterceptor } from "./shared/interceptors/auth.interceptor";

import { ActionsDialogComponent } from "./actions-dialog.component";
import { CodeInterceptor } from "./shared/interceptors/code.interceptor";
import { UsersModule } from "./users/users.module";
import { SharedComponentsModule } from "./shared-components.module";
import { AmazonServersDialogComponent } from "./amazonServers-dialog.component";

if (environment.production === true) {
	enableProdMode();
}

@NgModule({
	declarations: [AppComponent, NotFoundComponent, ActionsDialogComponent, AmazonServersDialogComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		MaterialModule,
		ReactiveFormsModule,
		PipesModule,
		UsersModule,
		SharedComponentsModule,
	],
	exports: [
		// LiabilityPipe
	],
	providers: [
		Title,
		CookieService,
		AlertService,
		ApplicationService,
		BetService,
		FixtureService,
		ExchangeService,
		LinkService,
		PushFeedService,
		ScraperService,
		ReportService,
		AuthService,
		AuthGuardService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		AdminGuardService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		CodeGuardService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CodeInterceptor,
			multi: true,
		},
		DuoGuardService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CodeInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
