import { BaseTableColumn } from "./baseTableColumn";

export class BaseTableRow {
	public cssClass: string;
	columns: BaseTableColumn[];

	constructor(cssClass = null) {
		this.cssClass = cssClass;
		this.columns = new Array<BaseTableColumn>();
	}
}
