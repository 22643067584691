import { Account } from "./account";
import { MarketType } from "./marketType";
import { ExchangeSet } from "./exchangeSet";

export class Exchange {
	public id: number;
	public name: string;
	public sets: ExchangeSet[];
	public marketTypes: MarketType[];
	public accounts: Account[];

	constructor(exchange: Exchange) {
		if (exchange != null) {
			this.id = exchange.id;
			this.name = exchange.name;
			this.sets = exchange.sets.map((set) => new ExchangeSet(set));
			this.marketTypes = exchange.marketTypes.map((marketType) => new MarketType(marketType));
			this.accounts = exchange.accounts ? exchange.accounts.map((account) => new Account(account)) : null;
		}
	}

	getSet(setId: string): ExchangeSet {
		return this.sets.find((set) => set.code === setId);
	}

	getCSSets(): ExchangeSet[] {
		return this.sets.filter((set) =>
			[
				"514839858df9013969000002", // set 4
				"64e73def573a33aeac213e67", // set 15
			].includes(set.code)
		);
	}

	get1X2Sets(): ExchangeSet[] {
		return this.sets.filter((set) =>
			[
				"51ac434ff973b27f68373160", // set 5
				"621e1675dbd03eda20d0f302", // set 13
				"649547a6774925e513613c19", // set 14
			].includes(set.code)
		);
	}

	getOUSets(): ExchangeSet[] {
		return this.sets.filter((set) =>
			[
				"4e1d906a66a0848304000005",
				"4fdf23ad66a084ac50000378",
				"50ffa91dcf46094185f12b45",
				"5229765757ad18e60d000002",
				"6527e020d2aad8e1fafa178d",
				"65cf3d35acf12fee885eb9bf",
				"5c4eca0ba94869ac086852b1",
				"67f379c9c07960affa36d316",
			].includes(set.code)
		);
	}

	getMarketType(marketTypeId: number): MarketType {
		return this.marketTypes.find((marketType) => marketType.id === marketTypeId);
	}

	getMarketTypeByName(marketTypeName: string): MarketType {
		return this.marketTypes.find((marketType) => marketType.name === marketTypeName);
	}
}
