import { NgModule } from "@angular/core";

import {
	InitialPipe,
	CapitalizePipe,
	LiabilityPipe,
	TimePipe,
	GroupByPipe,
	SortPipe,
	BRNumberPipe,
	SafeHtmlPipe,
} from "./shared/pipes";
import { OddsPipe } from "./shared/pipes/odds.pipe";
import { SplitPipe } from "./shared/pipes/split.pipe";

@NgModule({
	declarations: [
		InitialPipe,
		CapitalizePipe,
		LiabilityPipe,
		TimePipe,
		GroupByPipe,
		SortPipe,
		OddsPipe,
		BRNumberPipe,
		SafeHtmlPipe,
		SplitPipe,
	],
	exports: [
		InitialPipe,
		CapitalizePipe,
		LiabilityPipe,
		TimePipe,
		GroupByPipe,
		SortPipe,
		OddsPipe,
		BRNumberPipe,
		SafeHtmlPipe,
		SplitPipe,
	],
})
export class PipesModule {}
