import { RaceSchedule } from "./raceSchedule";

export class RawData {
	_id: string;
	uniqId: string;
	sport: string;
	setId: number;
	eventId: string;
	type: string;
	distance: string;
	winners: number;
	country: string;
	marketTime: Date;
	venue: string;
	marketName: string;
	eventName: string;
	card: string;
	sortPriority: number;
	runnerName: string;
	marketType: string;
	marketId: string;
	raceStatus: string;
	status: string;
	bsp: boolean;
	dropOut: boolean;

	liability: number;
	secondaryLiability: number;
	liabilityBF: number;
	preBspLiability: number;
	noBspLiability: number;
	totalLiability: number;
	turnover: number;
	bspLay: number;
	bspBack: number;
	tradedVolume: number;
	lastUpdate: Date;
	off: Date;
	runtime: number;
	running: string;
	duration: string;
	raceId: string;
	// percentagesBack: number;
	// percentagesLay: number;

	outcome: string;
	profit: number;
	potential: number;
	bfback: number;
	bfbackStake: number;
	backLiab: number;
	backLiabStake: number;
	bflay: number;
	bflayStake: number;
	layLiab: number;
	layLiabStake: number;
	layWithoutBets: number;
	layWithoutBetsStake: number;
	backWithoutBets: number;
	backWithoutBetsStake: number;
	back: number;
	backStake: number;
	lay: number;
	layStake: number;
	hundredReal: number;
	robotBack: number;
	robot: number;
	ded_min: number;
	minBack: number;
	valid: number;
	bet: number;
	maxlayWithMargin: number;
	optimalPriceLay: number;
	optimalPriceLayStake: number;
	optimalPriceBack: number;
	optimalPriceBackStake: number;
	maxlayruns: number;
	maxlayrunsvalue: number;
	currentlayruns: number;
	currentlayrunsvalue: number;
	minbackruns: number;
	minbackrunsvalue: number;
	currentbackruns: number;
	currentbackrunsvalue: number;
	minbackWithMargin: number;
	log: string;
	logBack: string;

	avgMatchedOdds: number;
	avgMatchedOddsCorrected: number;
	bspPrice: number;
	bspcPrice: number;
	greenOutBspRunner: number;
	greenOutBspcRunner: number;
	greenOutAvgRace: number;
	greenOut100Runner: number;
	greenOut100Race: number;
	bspBackStake: number;
	bspLayStake: number;
	matchedVolume: number;
	lastTradedPrice: number;
	lastTradedPriceReplaced: number;
	lastTradedPriceCorrected: number;
	greenOutLtpRunner: number;
	greenOutLtpcRunner: number;

	runnerCount: number;
	factor_league: number;
	tgeStart: number;
	supStart: number;
	parametersTemplate: string;
	priceTemplateParams: [key: string, value: string][];
	schedule: RaceSchedule[];

	constructor(data: RawData) {
		this._id = data._id;
		this.uniqId = data.uniqId;
		this.sport = data.sport;
		this.setId = data.setId;
		this.eventId = data.eventId;
		this.type = data.type;
		this.distance = data.distance;
		this.winners = data.winners;
		this.country = data.country;
		this.marketTime = data.marketTime;
		this.venue = data.venue;
		this.marketName = data.marketName;
		this.eventName = data.eventName;
		this.card = data.card;
		this.sortPriority = data.sortPriority;
		this.runnerName = data.runnerName;
		this.marketType = data.marketType;
		if (data.marketId && data.marketId.toString().startsWith("1.")) {
			this.marketId = (+data.marketId).toFixed(9);
		} else {
			this.marketId = data.marketId;
		}
		this.raceStatus = data.raceStatus;
		this.status = data.status;
		this.bsp = data.bsp;
		this.dropOut = data.dropOut;

		this.outcome = data.outcome || null;
		this.profit = data.profit || 0;
		this.liability = data.liability;
		this.secondaryLiability = data.liability;
		this.liabilityBF = data.liabilityBF;
		this.preBspLiability = data.preBspLiability;
		this.noBspLiability = data.noBspLiability;
		this.totalLiability = data.liability + data.preBspLiability;
		this.turnover = data.turnover;
		this.bspLay = data.bspLay;
		this.bspBack = data.bspBack;
		this.tradedVolume = data.tradedVolume;
		this.lastUpdate = data.lastUpdate;
		this.off = data.off;
		this.runtime = data.runtime;
		this.running = data.running;
		this.duration = data.duration;
		this.raceId = data.raceId;
		// this.percentagesBack = data.percentagesBack;
		// this.percentagesLay = data.percentagesLay;

		this.potential = isNaN(data.potential) ? null : data.potential;
		this.bfback = isNaN(data.bfback) ? null : data.bfback;
		this.bfbackStake = isNaN(data.bfbackStake) ? null : data.bfbackStake;
		this.bflay = isNaN(data.bflay) ? null : data.bflay;
		this.bflayStake = isNaN(data.bflayStake) ? null : data.bflayStake;
		this.backLiab = isNaN(data.backLiab) ? null : data.backLiab;
		this.backLiabStake = isNaN(data.backLiabStake) ? null : data.backLiabStake;
		this.layLiab = isNaN(data.layLiab) ? null : data.layLiab;
		this.layLiabStake = isNaN(data.layLiabStake) ? null : data.layLiabStake;
		this.layWithoutBets = isNaN(data.layWithoutBets) ? null : data.layWithoutBets;
		this.layWithoutBetsStake = isNaN(data.layWithoutBetsStake) ? null : data.layWithoutBetsStake;
		this.backWithoutBets = isNaN(data.backWithoutBets) ? null : data.backWithoutBets;
		this.backWithoutBetsStake = isNaN(data.backWithoutBetsStake) ? null : data.backWithoutBetsStake;
		this.back = isNaN(data.back) ? null : data.back;
		this.backStake = isNaN(data.backStake) ? null : data.backStake;
		this.lay = isNaN(data.lay) ? null : data.lay;
		this.layStake = isNaN(data.layStake) ? null : data.layStake;
		this.hundredReal = isNaN(data.hundredReal) ? null : data.hundredReal;
		this.robotBack = isNaN(data.robotBack) ? null : data.robotBack;
		this.robot = isNaN(data.robot) ? null : data.robot;
		this.ded_min = isNaN(data.ded_min) ? null : data.ded_min;
		this.minBack = isNaN(data.minBack) ? null : data.minBack;
		this.valid = isNaN(data.valid) ? null : data.valid;
		this.bet = isNaN(data.bet) ? null : data.bet;
		this.maxlayWithMargin = isNaN(data.maxlayWithMargin) ? null : data.maxlayWithMargin;
		this.maxlayruns = isNaN(data.maxlayruns) ? null : data.maxlayruns;
		this.maxlayrunsvalue = isNaN(data.maxlayrunsvalue) ? null : data.maxlayrunsvalue;
		this.currentlayruns = isNaN(data.currentlayruns) ? null : data.currentlayruns;
		this.currentlayrunsvalue = isNaN(data.currentlayrunsvalue) ? null : data.currentlayrunsvalue;
		this.minbackruns = isNaN(data.minbackruns) ? null : data.minbackruns;
		this.minbackrunsvalue = isNaN(data.minbackrunsvalue) ? null : data.minbackrunsvalue;
		this.currentbackruns = isNaN(data.currentbackruns) ? null : data.currentbackruns;
		this.currentbackrunsvalue = isNaN(data.currentbackrunsvalue) ? null : data.currentbackrunsvalue;
		this.optimalPriceLay = isNaN(data.optimalPriceLay) ? null : data.optimalPriceLay;
		this.optimalPriceLayStake = isNaN(data.optimalPriceLayStake) ? null : data.optimalPriceLayStake;
		this.optimalPriceBack = isNaN(data.optimalPriceBack) ? null : data.optimalPriceBack;
		this.optimalPriceBackStake = isNaN(data.optimalPriceBackStake) ? null : data.optimalPriceBackStake;
		this.minbackWithMargin = isNaN(data.minbackWithMargin) ? null : data.minbackWithMargin;
		this.log = data.log;
		this.logBack = data.logBack;

		this.avgMatchedOdds = this.round(data.avgMatchedOdds, 3);
		this.avgMatchedOddsCorrected = this.round(
			data.avgMatchedOddsCorrected ? data.avgMatchedOddsCorrected : data.avgMatchedOdds,
			3
		);

		this.bspPrice = data.bspPrice;
		this.bspcPrice = data.bspcPrice;
		this.greenOutBspRunner = data.greenOutBspRunner;
		this.greenOutBspcRunner = data.greenOutBspcRunner;
		this.greenOutAvgRace = data.greenOutAvgRace;
		this.greenOut100Runner = data.greenOut100Runner || 0;
		this.greenOut100Race = data.greenOut100Race;
		this.bspBackStake = data.bspBackStake;
		this.bspLayStake = data.bspLayStake;
		this.matchedVolume = data.matchedVolume;
		this.lastTradedPrice = data.lastTradedPrice;
		this.lastTradedPriceReplaced = data.lastTradedPriceReplaced;
		this.lastTradedPriceCorrected = data.lastTradedPriceCorrected;
		this.greenOutLtpRunner = data.greenOutLtpRunner || (this.turnover ? 0 : null);
		this.greenOutLtpcRunner = data.greenOutLtpcRunner;

		this.runnerCount = data.runnerCount;
		this.factor_league = data.factor_league || 0;
		this.tgeStart = data.tgeStart || null;
		this.supStart = data.supStart || null;
		this.parametersTemplate = data.parametersTemplate;
		this.priceTemplateParams = data.priceTemplateParams;

		if (data.schedule && data.schedule.length > 0) {
			this.schedule = data.schedule;
		}
	}

	private round(value, digits = 2): number {
		if (value && !Number.isNaN(value)) {
			return Number.parseFloat(Number.parseFloat(value).toFixed(digits));
		} else {
			return 0;
		}
	}
}
