import { Component, OnInit, Input, Attribute, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSort } from "@angular/material/sort";
import { DateTime, Interval } from "luxon";

import { Alert, Dictionary, KeyValuePair, Fixture, FixtureNote, ScraperLinkValidation } from "../shared/models";
import {
	ApplicationService,
	ReportService,
	LinkService,
	ExchangeService,
	FixtureService,
	AlertService,
	ScraperService,
	BetService,
} from "../shared/services";
import { AlertEditDialogComponent } from "./alert-edit-dialog/alert-edit-dialog.component";
import { NoteEditDialogComponent } from "./note-edit-dialog/note-edit-dialog.component";
import { AlertContentDialogComponent } from "./alert-content-dialog/alert-content-dialog.component";

@Component({
	selector: "app-alerts",
	templateUrl: "./alert.component.html",
	styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;

	@Input("orientation") set setOrientation(value: string) {
		this._orientation = value;
		this._columnsToDisplay = null;
	}
	@Input("data") set onData(alerts: Array<Alert | FixtureNote>) {
		if (this.mode == "mobile") {
			this.dataSource = new MatTableDataSource(alerts.filter((record) => record instanceof Alert));
		} else {
			this.dataSource = new MatTableDataSource(alerts);
		}
		this.dataSource.sort = this.sort;
		this.recordSelection = new SelectionModel<Alert>(true);
	}

	links: Dictionary<string, string>;
	halted: Dictionary<string, string>;

	dataSource: MatTableDataSource<Alert | FixtureNote>;
	recordSelection = new SelectionModel<Alert>(true);

	private _columnsToDisplay: Array<string>;
	private _orientation: string;

	columnsNotes = ["select", "actions", "timestamp", "customNote", "processed", "user"];
	private columns = ["select", "actions", "timestamp", "note", "type", "score", "minute", "setNr", "halted", "details"];
	private columnsMatch = ["matchId", "fixture", "league", "factor"];
	private columnsLive = ["checked"];
	private columnsArchive = ["processed", "user"];
	private columnsMobileLandscape = ["select", "actions", "timestamp", "halted", "type", "fixture", "setNr", "minute"];
	private columnsMobilePortrait = ["select", "actions", "timestamp", "halted", "type"];

	constructor(
		@Attribute("mode") public mode: string,
		protected alertService: AlertService,
		protected applicationService: ApplicationService,
		protected exchangeService: ExchangeService,
		protected fixtureService: FixtureService,
		protected linkService: LinkService,
		private reportService: ReportService,
		private scraperService: ScraperService,
		private betService: BetService,
		public dialog: MatDialog
	) {
		this.mode = this.mode || "archive";
	}

	ngOnInit() {
		this.halted = new Dictionary<string, string>();

		this.links = new Dictionary<string, string>();
		this.links.add("Details", ":");
	}

	get displayedColumns() {
		if (!this._columnsToDisplay) {
			const cols = [...this.columns];
			if (this.mode === "mobile") {
				this._columnsToDisplay = this._orientation == "landscape" ? this.columnsMobileLandscape : this.columnsMobilePortrait;
			} else if (this.mode !== "match") {
				cols.splice(5, 0, ...this.columnsMatch);
				if (this.mode === "archive") {
					this._columnsToDisplay = cols.concat(this.columnsArchive);
				} else {
					this._columnsToDisplay = cols.concat(this.columnsLive);
				}
			} else {
				this._columnsToDisplay = cols.concat(this.columnsArchive);
			}
		}

		return this._columnsToDisplay;
	}

	get linksList(): KeyValuePair<string, string>[] {
		if (this.links) {
			return this.links.toArray();
		} else {
			return null;
		}
	}

	get darkTheme(): boolean {
		return this.applicationService.darkTheme;
	}

	get allRecordsSelected(): boolean {
		const numSelected = this.recordSelection.selected.length;
		const numRows = this.dataSource.data.filter((entry) => entry instanceof Alert).length;

		return numSelected === numRows;
	}

	get haltedSets(): boolean {
		return this.haltedSetsList.length > 0;
	}

	get haltedSetsList(): KeyValuePair<string, string>[] {
		return this.halted.toArray();
	}

	toggleAllRecordsSelection() {
		if (this.allRecordsSelected) {
			this.recordSelection.clear();
		} else {
			this.dataSource.filteredData.forEach((fixture) => fixture instanceof Alert && this.recordSelection.select(fixture));
		}
	}

	public refresh() {
		setTimeout(() => {
			this.dataSource.filter = "ZZZ";
			this.dataSource.filter = "";
		});
	}

	editAlert(records: Alert[]) {
		const dlg = this.dialog.open(AlertEditDialogComponent, {
			data: {
				records,
				halted: this.haltedSets,
				validations:
					records.filter((r) => r.type == "sp_link_mismatch" && r.details != "Score: -1.00").length == records.length
						? true
						: false,
			},
			disableClose: false,
		});

		dlg.afterClosed().subscribe((response) => {
			if (response) {
				if (response.resume) {
					this.resumeSet(records);
				}
				if (response.validations) {
					this.addValidations(records);
				}
			}
		});
	}

	openLink(key: string, value: string) {
		this.linkService.openLink(key, value);
	}

	initMenu(records: Array<Alert | FixtureNote>) {
		this.halted = new Dictionary<string, string>();
		records.forEach((entry) => {
			if (entry instanceof Alert && entry.haltedSets) {
				if (
					entry.matchTime &&
					entry.matchTime <= new Date() &&
					Interval.fromDateTimes(DateTime.fromJSDate(entry.matchTime), DateTime.now()).length("minutes") < 120
				) {
					entry.haltedSets.forEach((setId) => {
						const set = this.exchangeService.getExchange(1).getSet(setId);
						if (set) {
							this.halted.add(set.code, set.name);
						}
					});
				}
			}
		});
	}

	resumeSet(records, setId?: string) {
		const ids = records.map((record) => record.fixtureId);
		const updates = { halt: [] };

		if (setId != null) {
			updates.halt.push({ key: setId, value: false });
		} else {
			this.haltedSetsList.forEach((set) => {
				updates.halt.push({ key: set.key, value: false });
			});
		}

		const distinct = (value, index, self) => {
			return self.indexOf(value) === index;
		};

		this.fixtureService
			.stopStart(ids.filter(distinct), updates)
			.toPromise()
			.then((updates) => this.handleStopStart(updates))
			.catch((error) => this.handleError(error));
	}

	private addValidations(records) {
		for (const record of records) {
			this.reportService
				.getAlertContent(record._id)
				.toPromise()
				.then((response) => {
					if (response && response.content) {
						const cutoff = response.content.indexOf("Theirs:");
						if (cutoff) {
							const ours = response.content.substr(0, cutoff).split("<br/>");
							const theirs = response.content.substr(cutoff).split("<br/>");
							for (const needle of ["Home:", "Away:", "League:"]) {
								const o = ours.find((entry) => entry.includes(needle));
								const t = theirs.find((entry) => entry.includes(needle));
								if (o && t) {
									const s1 = o.substr(o.indexOf(needle) + needle.length);
									const s2 = t.substr(t.indexOf(needle) + needle.length);
									if (s1 && s2) {
										this.scraperService
											.addLinkValidation(
												new ScraperLinkValidation({
													_id: null,
													string1: s1.trim(),
													string2: s2.trim(),
													match: true,
												})
											)
											.toPromise();
									}
								}
							}
						}
					}
				})
				.catch((error) => this.handleError(error));
		}
	}

	private handleError(error) {
		if (error.message) {
			this.alertService.alertError(error.message);
		} else {
			this.alertService.alertError(error);
		}
	}

	handleStopStart(fixtures: Fixture[]) {
		fixtures.forEach((fixture) => {
			const records = this.dataSource.data.filter((record) => record instanceof Alert && record.fixtureId === fixture._id);
			if (records.length > 0) {
				records.forEach((record: Alert) => {
					record.haltedSets = fixture.haltedSets;
				});
			}
		});
	}

	translateType(key: string) {
		const type = this.reportService.alertTypes.find((alert) => alert[0] === key);
		if (type) {
			return type[1];
		} else {
			return key;
		}
	}

	pending(records: Alert[]) {
		return records.filter((record) => !record.processed && !record.checked).length > 0;
	}

	checking(records: Alert[]) {
		const toUpdate = records.filter((record) => !record.processed && !record.checked);
		if (toUpdate.length > 0) {
			this.reportService.setAlertChecking(toUpdate.map((record) => record._id)).toPromise();
		}
	}

	ignore(records: Alert[]) {
		const toUpdate = records.filter((record) => !record.processed && !record.checked);
		if (toUpdate.length > 0) {
			this.reportService
				.updateAlertNote(
					toUpdate.map((record) => record._id),
					"Closed"
				)
				.toPromise();
		}
	}

	isAlert(records) {
		return records.filter((entry) => entry instanceof Alert).length > 0;
	}

	editNote(record: FixtureNote) {
		const dlg = this.dialog.open(NoteEditDialogComponent, {
			data: record,
			disableClose: false,
		});

		dlg.afterClosed().subscribe((updated: FixtureNote) => {
			const index = this.dataSource.data.indexOf(record);
			if (index > -1) {
				this.dataSource.data[index] = updated;
				this.refresh();
			}
		});
	}

	openContent(record: Alert) {
		this.dialog.open(AlertContentDialogComponent, {
			data: record,
			disableClose: false,
		});
	}

	download() {
		const fileName = `alerts_${new Date().getTime()}.csv`;

		const data = this.dataSource.data
			.filter((record) => record instanceof Alert)
			.map((record: Alert) => {
				return {
					date: DateTime.fromJSDate(record.timestamp).toFormat("dd/MM/yyyy HH:mm:ss"),
					note: record.note,
					type: this.translateType(record.type),
					matchId: record.matchId,
					match: record.fixture,
					league: record.league,
					leagueFactor: record.leagueFactor,
					score: record.score,
					minute: record.gameTime ? record.gameTime : record.minute,
					set: record.setNr,
					halted: record.halted,
					details: record.details,
					processed: record.processed ? DateTime.fromJSDate(record.processed).toFormat("dd/MM/yyyy HH:mm:ss") : "",
					user: record.user,
				};
			});

		this.applicationService.downloadAsCSV(data, fileName);
	}

	alertRow = (i: number, row) => row instanceof Alert;
	noteRow = (i: number, row) => row instanceof FixtureNote;

	emailLateSuspensions(record: Alert) {
		this.betService.getBets_Report({ alert_id: record._id }).toPromise();
	}
}
