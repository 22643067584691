import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DateTime } from "luxon";

import { ApplicationService, BetService } from "../../services";
import { BetInfo, KeyValuePair } from "../../models";

@Component({
	selector: "app-bet-dialog-info",
	templateUrl: "bet-info-dialog.component.html",
	styleUrls: ["./bet-info-dialog.component.scss"],
})
export class BetInfoDialogComponent implements OnInit {
	public info: KeyValuePair<string, string>[];
	public message = "Loading...";

	get darkTheme(): boolean {
		return this.applicationService.darkTheme;
	}

	constructor(
		private betService: BetService,
		private applicationService: ApplicationService,
		private dialogRef: MatDialogRef<BetInfoDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit(): void {
		if (this.data.bet.project !== "GF" && this.data.bet.project !== "BB") {
			this.betService
				.getBetInfo(this.data.bet.betId, this.data.soccer)
				.toPromise()
				.then((bet) => {
					bet.mergeMatchedData(this.data.bet);
					this.info = Object.entries(bet)
						// .sort((lh, rh) => lh[0] < rh[0] ? -1 : 1)
						.map(
							(entry) =>
								new KeyValuePair<string, string>(
									entry[0],
									entry[1]
										? entry[1] instanceof Date
											? DateTime.fromJSDate(entry[1]).toFormat("yyyy-MM-dd HH:mm:ss.SSS")
											: entry[1].toString()
										: ""
								)
						);
				})
				.catch((err) => (this.message = err.message));
		} else {
			const clone = Object.assign({}, this.data.bet);
			clone.match_id = this.data.bet.matchId;
			clone.market_type = this.data.bet.marketType;
			clone.market_id = this.data.bet.marketId;
			clone.bet_id = this.data.bet.betId;
			clone.side = this.data.bet.type;
			clone.amount = this.data.bet.stake;
			if (this.data.bet.score) {
				clone.score = {
					home: this.data.bet.score.split("-")[0],
					away: this.data.bet.score.split("-")[1],
				};
			}

			const bet = new BetInfo(clone);
			bet.mergeMatchedData(this.data.bet);
			this.info = Object.entries(bet)
				// .sort((lh, rh) => lh[0] < rh[0] ? -1 : 1)
				.map((entry) => new KeyValuePair<string, string>(entry[0], entry[1] ? entry[1].toString() : ""));
		}
	}
}
