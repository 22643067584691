import { Liability } from "./liability";

export class LiabilityMarket {
	market: string;
	liabilities: Liability[];

	constructor(market: string, liabilityMarket) {
		this.market = market;
		this.liabilities = new Array<Liability>();

		if (Array.isArray(liabilityMarket)) {
			liabilityMarket.forEach((liability) => {
				this.liabilities.push(new Liability(liability.type, liability.lines));
			});
		} else {
			Object.keys(liabilityMarket).forEach((type) => {
				this.liabilities.push(new Liability(type, liabilityMarket[type]));
			});
		}
	}

	add(liabilities: Liability[]) {
		liabilities.forEach((liability) => {
			const index = this.liabilities.findIndex((l) => l.type === liability.type);
			if (index > -1) {
				this.liabilities[index].add(liability.lines);
			}
		});
	}

	update(liabilityMarket): boolean {
		let updated = false;

		Object.keys(liabilityMarket).forEach((type) => {
			const index = this.liabilities.findIndex((l) => l.type === type);
			if (index > -1) {
				updated = this.liabilities[index].update(liabilityMarket[type]);
			} else {
				this.liabilities.push(new Liability(type, liabilityMarket[type]));
				updated = true;
			}
		});

		return updated;
	}
}
