export class RaceSchedule {
	private _startDate: Date;
	private _endDate: Date;

	marketTypes: string[];

	constructor(schedule: RaceSchedule, startDate: Date = null, endDate: Date = null, markets: string[] = null) {
		if (schedule) {
			this.startDate = new Date(schedule.startDate);
			this.endDate = new Date(schedule.endDate);
			this.marketTypes = schedule.marketTypes;
		} else {
			this.startDate = startDate;
			this.endDate = endDate;
			this.marketTypes = markets || new Array<string>();
		}
	}

	get startDate(): Date {
		return this._startDate;
	}

	set startDate(value: Date) {
		this._startDate = value;
	}

	get endDate(): Date {
		return this._endDate;
	}

	set endDate(value: Date) {
		this._endDate = value;
	}

	get displayMarkets(): string {
		return this.marketTypes.join("; ");
	}

	export() {
		return {
			marketTypes: this.marketTypes,
			startDate: this._startDate,
			endDate: this._endDate,
		};
	}
}
