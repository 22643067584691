import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/env";
import { BetInfo } from "../models";

@Injectable()
export class BetService {
	protected url: string;

	constructor(protected http: HttpClient) {
		this.url = `${environment.BETROBOT_API_ENDPOINT}`;
	}

	getBetInfo(id: string, soccer: boolean): Observable<BetInfo> {
		if (soccer) {
			return this.http.get(`${this.url}/bets/info/soccer/${id}`).pipe(
				map((response) => {
					return new BetInfo(response);
				})
			);
		} else {
			return this.http.get(`${this.url}/bets/info/races/${id}`).pipe(map((response) => new BetInfo(response)));
		}
	}

	getBets_Report(filter): Observable<unknown> {
		return this.http.post(`${this.url}/bets/report`, filter);
	}
}
