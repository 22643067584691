export class Market {
	code: string;
	name: string;

	constructor(market: Market) {
		if (market) {
			this.code = market.code;
			this.name = market.name;
		}
	}
}
