import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "duration" })
export class DurationPipe implements PipeTransform {
	transform(value: number, fraction: "s" | "ms" = "s"): string {
		if (!value && value != 0) {
			return "";
		} else {
			if (fraction == "ms") {
				value = value / 1000;
			}

			let result = "";
			if (value > 24 * 60 * 60) {
				const d = Math.trunc(value / (24 * 60 * 60));
				value -= d * 24 * 60 * 60;
				result += `${d}d `;
			}
			if (value > 60 * 60) {
				const h = Math.trunc(value / (60 * 60));
				value -= h * 60 * 60;
				result += `${h}h `;
			}
			if (value > 60) {
				const m = Math.trunc(value / 60);
				value -= m * 60;
				result += `${m}m `;
			}

			return (result += `${Math.round(value)}s`);
		}
	}
}
