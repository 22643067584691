import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "initial" })
export class InitialPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return null;
		}

		return value[0].toUpperCase();
	}
}
