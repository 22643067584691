import { LiabilityMarket } from "./liabilityMarket";
import { ExchangeList } from ".";

export class LiabilitySet {
	setId: string;
	markets: LiabilityMarket[];

	private _exchangeList: ExchangeList;
	private _setName: string;

	constructor(setId: string, liabilities, exchangeList?: ExchangeList) {
		this.setId = setId;
		this.markets = new Array<LiabilityMarket>();

		if (Array.isArray(liabilities)) {
			liabilities.forEach((liability) => {
				this.markets.push(new LiabilityMarket(liability.market, liability.liabilities));
			});
		} else {
			Object.keys(liabilities).forEach((market) => {
				this.markets.push(new LiabilityMarket(market, liabilities[market]));
			});
		}

		this._exchangeList = exchangeList;
		this._setName = null;
	}

	update(liabilities): boolean {
		let updated = false;

		Object.keys(liabilities).forEach((market) => {
			const index = this.markets.findIndex((m) => m.market === market);
			if (index > -1) {
				updated = this.markets[index].update(liabilities[market]);
			} else {
				this.markets.push(new LiabilityMarket(market, liabilities[market]));
				updated = true;
			}
		});

		return updated;
	}

	get setName(): string {
		if (!this._setName && this._exchangeList) {
			const setName = this._exchangeList.translateSetName(this.setId);
			if (setName) {
				this._setName = setName;
			}
		}

		if (!this._setName) {
			if (this._exchangeList) {
				return `[${this.setId}]`;
			} else {
				return this.setId;
			}
		} else {
			return this._setName;
		}
	}
}
