import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "brNumber" })
export class BRNumberPipe implements PipeTransform {
	transform(value: number): string {
		const separator = 3;

		let valueStr = "";
		try {
			if (typeof value !== "undefined" && value != null) {
				valueStr = value.toString();
				if (valueStr.indexOf(".") > -1) {
					valueStr = value.toFixed(2);
				} else {
					valueStr = Math.round(value).toString();
				}
			}
		} catch (ex) {
			console.log(ex);
			valueStr = "err";
		}

		let formatted = "";
		let tmpStr = valueStr.replace("-", "");
		if (tmpStr.length > separator) {
			while (tmpStr.length > separator) {
				formatted = tmpStr.substr(tmpStr.length - separator, separator) + " " + formatted;
				tmpStr = tmpStr.slice(0, tmpStr.length - separator);
			}
		}
		formatted = (tmpStr + " " + formatted).trim();
		if (valueStr.startsWith("-")) {
			formatted = "-" + formatted;
		}

		return formatted;
	}
}
