<h3 mat-dialog-title
	cdkDrag
	cdkDragRootElement=".cdk-overlay-pane"
	cdkDragHandle>
	<span *ngIf="!record._id">Add User</span>
	<span *ngIf="record._id && !isProfile">Edit - {{record.name}}</span>
	<span *ngIf="record._id && isProfile">Edit Profile</span>
</h3>
<form [formGroup]="inputForm" novalidate (ngSubmit)="save()">
	<mat-dialog-content>
		<div class="form-group">
			<mat-form-field class="input-full-width">
				<input type="text"
							matInput
							class="form-control"
							formControlName="name"
							placeholder="Name"
							[readonly]="record._id"
							required>
				<mat-error *ngIf="inputForm.controls['name'].invalid">
					<span *ngIf="inputForm.controls['name'].hasError('required')">Field is required</span>
					<span *ngIf="inputForm.controls['name'].hasError('maxlength')">Maximum length {{USER_NAME_MAX_LENGTH}} characters</span>
					<span *ngIf="inputForm.controls['name'].hasError('existsIn')">There's already another user with this name</span>
				</mat-error>
			</mat-form-field>

			<mat-form-field *ngIf="!isProfile">
				<mat-select formControlName="role" placeholder="Role" required>
					<mat-option value="user">User</mat-option>
					<mat-option value="admin">Admin</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="input-full-width">
				<input type="email"
							matInput
							class="form-control"
							formControlName="email"
							placeholder="Email"
							required>
				<mat-error *ngIf="inputForm.controls['email'].invalid">
					<span *ngIf="inputForm.controls['email'].hasError('required')">Field is required</span>
					<span *ngIf="inputForm.controls['email'].hasError('email')">Not a valid email address</span>
					<span *ngIf="inputForm.controls['email'].hasError('existsIn')">There's already another user wih this email</span>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="input-full-width">
				<input type="tel"
							matInput
							class="form-control"
							formControlName="sms"
							placeholder="Mobile No."
							required>
				<mat-error *ngIf="inputForm.controls['sms'].invalid">
					<span *ngIf="inputForm.controls['sms'].hasError('required')">Field is required</span>
					<span *ngIf="inputForm.controls['sms'].hasError('minlength')">Minimum length {{MOBILE_MIN_LENGTH}} characters</span>
					<span *ngIf="inputForm.controls['sms'].hasError('maxlength')">Maximum length {{MOBILE_MAX_LENGTH}} characters</span>
					<span *ngIf="inputForm.controls['sms'].hasError('pattern')">Invalid mobile no</span>
				</mat-error>
			</mat-form-field>

			<div class="input-full-width checkbox">
				<mat-slide-toggle formControlName="enable_email">Emails</mat-slide-toggle>
			</div>

			<div class="input-full-width checkbox">
				<mat-slide-toggle formControlName="enable_sms">SMS</mat-slide-toggle>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<div class="buttons">
			<button mat-button mat-dialog-close color="primary">Close</button>
			<button mat-button type="submit" [disabled]="!inputForm.valid" color="primary" mat-raised-button>Save</button>
		</div>
	</mat-dialog-actions>
</form>
