<h3 mat-dialog-title
	cdkDrag
	cdkDragRootElement=".cdk-overlay-pane"
	cdkDragHandle>
	<span>Confirm</span>
</h3>
<mat-dialog-content>
	<span>Are you sure you want to remove '{{record.name}}'? </span>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="buttons">
		<button mat-button mat-dialog-close [disabled]="state === 'running'" color="primary">No</button>
		<button mat-button [disabled]="state === 'running'" (click)="proceed()" color="primary" mat-raised-button>Yes</button>
	</div>
</mat-dialog-actions>
