import { Bet } from "./bet";

export class BetInfo {
	matchId: string;
	league: string;
	match: string;

	setId: string;
	betId: number;
	placedDate: Date;
	openDate: Date;
	marketId: string;
	marketType: string;
	market: string;
	selection: string;
	type: string;
	delay: string;
	placedBookiesOUMarket: string;
	matchedBookiesOUMarket: string;

	selectedLine: string;
	placedBookiesOUSelected: string;
	matchedBookiesOUSelected: string;
	matchedBookiesOUSet8: string;
	placedBookiesAHC: string;
	matchedBookiesAHC: string;
	placedBookies1X2: string;
	matchedBookies1X2: string;
	placedBookiesBTTS: string;
	matchedBookiesBTTS: string;
	matchedBookiesCS: string;
	matchedBookiesOU: string;
	hundredPrice: string;
	placedHundred1X2: string;
	matchedHundred1X2: string;
	priceMax: string;
	deductionMin: string;
	deductionMax: string;
	priceMin: string;
	price: string;
	stake: string;
	activity: string;
	colour: string;

	matchedDate: Date;
	matchedPrice: string;
	matchedStake: string;

	score: string;
	minutePlaced: string;
	minuteMatched: string;

	sport: string;
	account: string;
	liability: number;
	eventId: number;
	raceId: string;
	raceTime: number;
	bookPercentage: number;
	bsp: number;
	card: string;
	draw: string;
	greenOut100Race: number;
	greenOut100Runner: number;
	greenOutAvgRace: number;
	greenOutAvgRunner: number;
	greenOutRace: number;
	greenOutRunner: number;
	marketTime: string;
	off: string;
	raceStatusPlaced: string;
	runner: string;
	runners: number;

	constructor(betData) {
		if (betData) {
			if ("match_id" in betData) {
				//Soccer
				this.matchId = betData.match_id;
				this.league = betData.league;
				this.match = `${betData.home} - ${betData.away}`;

				this.minutePlaced = betData.minute;
				this.minuteMatched = null;
				this.score = betData.score ? `${betData.score.home}-${betData.score.away}` : null;

				this.setId = betData.set_id;
				this.betId = betData.bet_id;
				this.marketId = betData.market_id;
				this.delay = betData.delay;
				if (betData.placed_date) {
					const dt = +betData.placed_date;
					if (Math.trunc(dt).toString().length > 10) {
						this.placedDate = new Date(dt);
					} else {
						this.placedDate = new Date(dt * 1000);
					}
				} else {
					this.placedDate = null;
				}
				if (betData.open_date) {
					const dt = +betData.open_date;
					if (Math.trunc(dt).toString().length > 10) {
						this.openDate = new Date(dt);
					} else {
						this.openDate = new Date(dt * 1000);
					}
				} else {
					this.openDate = null;
				}
				this.matchedDate = null;
				this.marketType = betData.market_type;
				this.type = betData.side ? betData.side.toUpperCase()[0] : "";
				this.market = betData.market;
				this.selection = betData.selection;

				if (this.marketType === "AHC") {
					this.selection += ` (${betData.handicap})`;
				}

				this.placedBookiesOUMarket = this.convertToTable(
					betData.odds ? this.parseOUOdds(this.preOddsFilter(betData.odds, "market", true)) : null
				);
				this.matchedBookiesOUMarket = null;
				this.placedBookiesOUSelected = this.convertToTable(
					betData.odds ? this.parseOUOdds(this.preOddsFilter(betData.odds, "selected")) : null
				);
				this.matchedBookiesOUSelected = null;
				this.matchedBookiesOUSet8 = null;
				this.hundredPrice = this.round(betData.hundredPrice, 3);
				this.placedHundred1X2 = betData["1x2Hundred"];
				this.matchedHundred1X2 = null;
				this.matchedPrice = null;
				this.price = this.round(betData.price);
				this.selectedLine = betData.lines;
				this.placedBookiesAHC = this.convertToTable(
					betData.odds ? this.parseAhcOdds(this.preOddsFilter(betData.odds, "selected")) : null
				);
				this.matchedBookiesAHC = null;
				this.placedBookies1X2 = this.convertToTable(
					betData.odds ? this.parse1X2Odds(this.preOddsFilter(betData.odds, "selected")) : null
				);
				this.matchedBookies1X2 = null;
				this.placedBookiesBTTS = this.convertToTable(
					betData.odds ? this.parseBttsOdds(this.preOddsFilter(betData.odds, "selected")) : null
				);
				this.matchedBookiesBTTS = null;
				this.deductionMin = betData.deduction && betData.deduction.min ? this.round(betData.deduction.min, 3) : null;
				this.deductionMax = betData.deduction && betData.deduction.max ? this.round(betData.deduction.max, 3) : null;
				this.matchedStake = null;
				this.stake = this.round(betData.amount);
				this.activity = betData.rbActivityPlaced ? this.parseActivity(betData.rbActivityPlaced) : null;
				this.colour = betData.area ? betData.area.code : null;
			} else {
				//Prematch
				this.sport = betData.sport;
				this.account = betData.account;
				this.setId = betData.setId;
				this.betId = betData.betId;
				this.type = betData.side;

				this.marketId = betData.marketId;
				this.marketType = betData.marketType;
				this.placedDate = new Date(betData.timestampPlaced);
				this.openDate = null;
				this.liability = betData.liability;
				this.price = betData.price;
				this.stake = betData.stake;
				this.matchedPrice = betData.matchedPrice;
				this.matchedStake = betData.matchedStake;

				this.eventId = betData.eventId;
				this.raceId = betData.raceId;
				this.raceTime = betData.raceTime;
				this.bookPercentage = betData.report ? betData.report.bookPercentage : null;
				this.bsp = betData.report ? betData.report.bsp : null;
				this.card = betData.report ? betData.report.card : null;
				this.deductionMax = betData.report ? betData.report.ded_max : null;
				this.deductionMin = betData.report ? betData.report.ded_min : null;
				this.greenOutAvgRace = betData.report ? betData.report.greenOutAvgRace : null;
				this.greenOutAvgRunner = betData.report ? betData.report.greenOutAvgRunner : null;
				this.greenOutRace = betData.report ? betData.report.greenOutRace : null;
				this.greenOutRunner = betData.report ? betData.report.greenOutRunner : null;
				this.greenOut100Race = null;
				this.greenOut100Runner = null;
				this.marketTime = betData.report ? betData.report.marketTime : null;
				this.off = betData.report ? betData.report.off : null;
				this.raceStatusPlaced = betData.report ? betData.report.raceStatusPlaced : null;
				this.runner = betData.report ? betData.report.runner : null;
				this.runners = betData.report ? betData.report.runners : null;

				this.matchedBookies1X2 = null;
			}
		}
	}

	mergeMatchedData(bet: Bet) {
		if (!this.betId) {
			this.betId = bet.betId;
			this.matchId = bet.matchId.toString();
			this.marketType = bet.marketType;
			this.market = bet.market;
			this.selection = bet.selection;
			this.type = bet.type;
			this.score = bet.score;
			this.setId = bet.setId;
		}

		this.matchedDate = bet.mDate;
		this.matchedPrice = this.round(bet.mPrice);
		this.matchedStake = this.round(bet.mStake);
		this.minutePlaced = bet.minutePlaced;
		this.minuteMatched = bet.minuteMatched;

		if (!this.matchId) {
			this.greenOut100Race = bet.greenOut100Race;
			this.greenOut100Runner = bet.greenOut100Runner;
		}

		if (bet.hundredPrices1X2) {
			this.matchedHundred1X2 = bet.hundredPrices1X2;
		}

		if (bet.bookiePricesCS) {
			this.matchedBookiesCS = this.convertToTable(
				bet.bookiePricesCS
					? bet.bookiePricesCS
							.substring(0, bet.bookiePricesCS.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
		if (bet.bookiePrices1X2) {
			this.matchedBookies1X2 = this.convertToTable(
				bet.bookiePrices1X2
					? bet.bookiePrices1X2
							.substring(0, bet.bookiePrices1X2.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
		if (bet.bookiePricesBtts) {
			this.matchedBookiesBTTS = this.convertToTable(
				bet.bookiePricesBtts
					? bet.bookiePricesBtts
							.substring(0, bet.bookiePricesBtts.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
		if (bet.bookiePricesAHC) {
			this.matchedBookiesAHC = this.convertToTable(
				bet.bookiePricesAHC
					? bet.bookiePricesAHC
							.substring(0, bet.bookiePricesAHC.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
		if (bet.bookiePricesOUSelected) {
			this.matchedBookiesOUSelected = this.convertToTable(
				bet.bookiePricesOUSelected
					? bet.bookiePricesOUSelected
							.substring(0, bet.bookiePricesOUSelected.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
		if (bet.bookiePricesOUMarket) {
			this.matchedBookiesOUMarket = this.convertToTable(
				bet.bookiePricesOUMarket
					? bet.bookiePricesOUMarket
							.substring(0, bet.bookiePricesOUMarket.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
		if (bet.bookiePricesOUSet8) {
			this.matchedBookiesOUSet8 = this.convertToTable(
				bet.bookiePricesOUSet8
					? bet.bookiePricesOUSet8
							.substring(0, bet.bookiePricesOUSet8.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
		if (bet.bookiePricesOU) {
			this.matchedBookiesOU = this.convertToTable(
				bet.bookiePricesOU
					? bet.bookiePricesOU
							.substring(0, bet.bookiePricesOU.length - 2)
							.split("; ")
							.join("\n")
					: ""
			);
		}
	}

	private convertToTable(data) {
		if (data) {
			const result = [];

			result.push("<table>");
			for (const line of data.split("\n")) {
				if (line) {
					result.push("<tr>");

					const columns = line.split(" - ");
					result.push("<td>");
					result.push(columns[0] + "&nbsp;");
					result.push("</td>");

					let index = 0;
					const odds = columns[1].split(" / ");
					for (const odd of odds) {
						if (index > 0) {
							result.push("<td>");
							result.push(" / ");
							result.push("</td>");
						}
						result.push("<td>");
						result.push(odd);
						result.push("</td>");
						index++;
					}

					result.push("</tr>");
				}
			}
			result.push("</table>");

			return result.join("");
		} else {
			return "";
		}
	}

	private preOddsFilter(odds, type: string, strict = false) {
		let source = null;

		if (type) {
			if (type in odds) {
				source = odds[type];
			} else if (!strict) {
				source = odds;
			}
		} else {
			source = odds;
		}

		return source;
	}

	private parseOUOdds(odds): string {
		let source = null;

		if ((odds && this.marketType === "OU") || this.marketType === "OUHT" || this.marketType === "OUET") {
			source = odds;
		} else {
			if (odds) {
				if ("4" in odds) {
					source = odds["4"];
				} else if ("65540" in odds) {
					source = odds["65540"];
				} else if ("140" in odds) {
					source = odds["140"];
				}
			}
		}

		return this.parseOdds(source);
	}

	private parseAhcOdds(odds): string {
		let source = null;

		if ((odds && this.marketType === "CS") || this.marketType === "CSHT" || this.marketType === "AHC") {
			if ("5" in odds) {
				source = odds["5"];
			} else if ("6" in odds) {
				source = odds["6"];
			}
		}

		return this.parseOdds(source);
	}

	private parse1X2Odds(odds): string {
		let source = null;

		if (odds) {
			if (this.marketType === "1X2" && "1" in odds) {
				source = odds["1"];
			}
			if (this.marketType === "1X2HT" && "2" in odds) {
				source = odds["2"];
			}
		}

		return this.parseOdds(source);
	}

	private parseBttsOdds(odds): string {
		let source = null;

		if (odds) {
			if (this.marketType === "BTTS" && "8" in odds) {
				source = odds["8"];
			}
		}

		return this.parseOdds(source);
	}

	private parseOdds(source): string {
		const result = [];

		if (source) {
			for (const bookieId in source) {
				const bookie = source[bookieId];
				if (bookie.valid && !bookie.outlier && !bookie.monitor) {
					result.push(`${bookie.bookie} - `);
					if (bookie["30246"]) {
						result.push(`${this.round(bookie["30246"], 3)} / `);
					} else {
						result.push(`${this.round(bookie["2"], 3)} / `);
					}
					if (bookie["58805"]) {
						result.push(`${this.round(bookie["58805"], 3)} / `);
					}
					if (bookie["2004841"]) {
						result.push(`${this.round(bookie["2004841"], 3)} / `);
					}
					if (bookie["30247"]) {
						result.push(`${this.round(bookie["30247"], 3)}\n`);
					} else {
						result.push(`${this.round(bookie["3"], 3)}\n`);
					}
				}
			}
		}

		return result.join("");
	}

	private round(value, digits = 2): string {
		if (value && !Number.isNaN(value)) {
			return Number.parseFloat(value).toFixed(digits);
		} else {
			return "";
		}
	}

	private parseActivity(activityFeed): string {
		let result = "";

		if (activityFeed.fastestFeed) {
			const main = this.readValue(activityFeed, `${activityFeed.fastestFeed}.activity.main`);
			const sub = this.readValue(activityFeed, `${activityFeed.fastestFeed}.activity.sub`);
			const side = this.readValue(activityFeed, `${activityFeed.fastestFeed}.side`);

			if (main) {
				result += main;
			}

			if (sub) {
				if (result) {
					result += "-";
				}
				result += sub;
			}

			if (side) {
				result += ` (${side})`;
			}
		}

		return result;
	}

	private readValue(source, field) {
		let result = null;
		let exists = false;

		let value = source;
		for (const entry of field.split(".")) {
			if (entry in value) {
				value = value[entry];
				exists = true;
			} else {
				exists = false;
			}
		}

		if (exists) {
			result = value;
		}

		return result;
	}
}
