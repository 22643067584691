import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sort" })
export class SortPipe implements PipeTransform {
	transform(values, ascending?: string) {
		if (values) {
			values.sort((a, b) => {
				if (a instanceof Object || b instanceof Object) {
					let aStr = a.toString();
					if ("key" in a) {
						aStr = a.key;
					}

					let bStr = b.toString();
					if ("key" in b) {
						bStr = b.key;
					}

					return aStr > bStr ? (ascending === "true" ? 1 : -1) : ascending === "true" ? -1 : 1;
				} else {
					if (ascending) {
						return b - a;
					} else {
						return a - b;
					}
				}
			});
		}

		return values;
	}
}
