import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/env";
import { User } from "../models";

@Injectable()
export class UserService {
	private url: string;

	constructor(private http: HttpClient) {
		this.url = environment.BETROBOT_API_ENDPOINT + "/users";
	}

	getUsers(): Observable<Array<User>> {
		return this.http.get<Array<User>>(`${this.url}/all`).pipe(
			map((data) => {
				return data.map((record) => new User(record));
			})
		);
	}

	deleteUser(userId: string) {
		return this.http.delete(`${this.url}/${userId}`);
	}

	updateUser(user: User, id: string) {
		return this.http.put(`${this.url}/${id}`, { user });
	}
}
