import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { ReportService } from "../../shared/services";

@Component({
	selector: "app-reports-alert-edit-dialog",
	templateUrl: "alert-edit-dialog.component.html",
	styleUrls: ["./alert-edit-dialog.component.scss"],
})
export class AlertEditDialogComponent implements OnInit {
	updateForm: UntypedFormGroup;

	constructor(
		private reportService: ReportService,
		private formBuilder: UntypedFormBuilder,
		private dialogRef: MatDialogRef<AlertEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		this.updateForm = this.formBuilder.group({
			note: [this.data.records.length > 1 ? "" : this.data.records[0].note, Validators.required],
			resume: [false],
			validations: [true],
		});
	}

	save(): void {
		if (this.updateForm.status === "VALID") {
			this.reportService
				.updateAlertNote(
					this.data.records.map((record) => record._id),
					this.updateForm.controls.note.value
				)
				.toPromise()
				.then(() => {
					this.dialogRef.close({
						resume: this.data.halted ? this.updateForm.controls.resume.value : false,
						validations: this.data.validations ? this.updateForm.controls.validations.value : false,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}
}
