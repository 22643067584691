import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { UserService } from "../services";
import { User } from "../models";

@Component({
	selector: "app-users-confirm-dialog",
	templateUrl: "confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.scss"],
})
export class UsersConfirmDialogComponent {
	state = "prompt";

	constructor(
		private userService: UserService,
		private dialogRef: MatDialogRef<UsersConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public record: User
	) {}

	proceed(): void {
		this.state = "running";

		this.userService
			.deleteUser(this.record._id)
			.toPromise()
			.then(() => {
				this.dialogRef.close("update");
			})
			.catch((error) => {
				this.state = "error";
				console.error(error);
			});
	}
}
