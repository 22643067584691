export class User {
	_id: string;
	name: string;
	email: string;
	sms: string;
	enable_email: number;
	enable_sms: number;
	role: string;
	authenticated: boolean;

	constructor(user: User) {
		this.authenticated = false;

		if (user) {
			this._id = user._id;
			this.name = user.name;
			this.email = user.email;
			this.sms = user.sms;
			this.enable_email = user.enable_email;
			this.enable_sms = user.enable_sms;
			this.role = user.role;
			this.authenticated = user.authenticated ? true : false;
		}
	}
}
