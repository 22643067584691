import { ActionsMenu as SharedActionsMenu } from "../..//models";

import { Exchange, FixtureUpdates } from ".";

export class ActionsMenu extends SharedActionsMenu {
	private default: FixtureUpdates;
	private exchange: Exchange;

	constructor(id: string, defaults: FixtureUpdates, exchange: Exchange) {
		super(id);

		this.default = defaults;
		this.exchange = exchange;
	}

	setMarket(marketCode: string, selected: boolean, setId: string = null) {
		marketCode = setId ? `${marketCode}:${setId}` : marketCode;

		if (this.ids.length === 1) {
			const def = this.default.markets.getItem(marketCode);
			if (def) {
				if (selected !== def.value) {
					this._updates.addMarket(marketCode, selected);
				} else {
					this._updates.removeMarket(marketCode);
				}
			} else {
				if (selected) {
					this._updates.addMarket(marketCode, selected);
				} else {
					this._updates.removeMarket(marketCode);
				}
			}
		} else {
			this._updates.addMarket(marketCode, selected);
		}
	}

	selectedMarket(marketCode: string, setId: string = null): boolean {
		marketCode = setId ? `${marketCode}:${setId}` : marketCode;

		if (this._updates.markets) {
			const market = this._updates.markets.getItem(marketCode);
			if (market) {
				return market.value;
			}
		}

		if (this.default.markets) {
			const market = this.default.markets.getItem(marketCode);
			if (market) {
				return market.value;
			}
		}

		return false;
	}

	selectAllMarkets(marketTypeId: number, setId: string = null) {
		this.setAllMarkets(true, marketTypeId, setId);
	}

	unSelectAllMarkets(marketTypeId: number, setId: string = null) {
		this.setAllMarkets(false, marketTypeId, setId);
	}

	allMarketsSelected(marketTypeId: number, setId: string = null): number {
		const marketType = this.exchange.marketTypes.find((marketType) => marketType.id === marketTypeId);

		if (marketType) {
			const selected = marketType.markets.filter((market) => this.selectedMarket(market.code, setId));
			const result = selected.length === 0 ? 0 : selected.length === marketType.markets.length ? 1 : 2;

			return result;
		} else {
			return 0;
		}
	}

	private setAllMarkets(selected: boolean, marketTypeId: number, setId: string) {
		const marketType = this.exchange.marketTypes.find((marketType) => marketType.id === marketTypeId);

		if (marketType) {
			marketType.markets.forEach((market) => {
				this.setMarket(market.code, selected, setId);
			});
		}
	}
}
