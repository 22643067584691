import { Subject } from "rxjs";
import { SocketIOMessage } from "./message";
import { io } from "socket.io-client";
import { EventEmitter } from "@angular/core";

const config = {
	reconnection: true,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 5000,
	reconnectionAttempts: Infinity,
};

export class SocketIOClient {
	private url: string;
	private socket;
	private connectionRetries: number;

	private messageSource = new Subject<SocketIOMessage>();
	public message$ = this.messageSource.asObservable();
	public onDisconnected = new EventEmitter();
	public onConnected = new EventEmitter();
	public isConnected = false;

	private rooms: { [name: string]: boolean } = {};
	private hasConnected = false;

	constructor(path, port) {
		this.connectionRetries = 0;
		this.url = path + ":" + port;
		this.socket = io(this.url, config);
		this.socket.on("connect", this.handleConnect.bind(this));
		this.socket.on("connect_error", this.handleConnectionError.bind(this));
		this.socket.on("reconnect_error", this.handleConnectionError.bind(this));
	}

	private handleConnectionError() {
		this.isConnected = false;
		this.connectionRetries++;
		if (this.connectionRetries === 10) {
			this.onDisconnected.emit();
		}
	}

	private handleConnect() {
		this.isConnected = true;
		this.emitMessage("connected", "Successfully connected to " + this.url);
		if (this.connectionRetries >= 10) {
			this.onConnected.emit();
		}
		this.connectionRetries = 0;

		if (this.hasConnected) {
			this.handleReconnect();
		}
		this.hasConnected = true;
	}

	private handleReconnect() {
		this.isConnected = true;
		this.emitMessage("reconnected", "Rejoining rooms on " + this.url);
		if (this.connectionRetries >= 10) {
			this.onConnected.emit();
		}
		this.connectionRetries = 0;

		const roomsCopy = Object.keys(this.rooms);
		for (const room of roomsCopy) {
			this.leaveRoom(room);
			this.joinRoom(room);
		}
	}

	public close() {
		this.isConnected = false;
		this.messageSource.complete();
		this.socket.destroy();
	}

	private emitMessage(subject: string, message: string) {
		const socketIOMessage = new SocketIOMessage(subject, message);
		this.messageSource.next(socketIOMessage);
	}

	public joinRoom(room: string) {
		if (this.roomExists(room)) {
			console.error("Room '" + room + "' already exists");
			return;
		}

		// console.log("Creating room '" + room + "'");
		this.socket.on(room, this.emitMessage.bind(this, room));
		this.emitToSocket("subscribe", room);
		this.rooms[room] = true;
	}

	public leaveRoom(room: string) {
		if (!this.roomExists(room)) {
			console.error("Room '" + room + "' does not exist so it can not be deleted");
			return;
		}

		// console.log("Deleting room '" + room + "'");
		this.emitToSocket("unSubscribe", room);
		this.socket.removeListener(room);
		delete this.rooms[room];
	}

	private roomExists(name: string) {
		return name in this.rooms;
	}

	private emitToSocket(subject, message) {
		this.socket.emit(subject, message);
	}
}
