import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { DateTime } from "luxon";

import { environment } from "../../../environments/env";
import { SystemInfo } from "../models/systemInfo";
import { User, Alert } from "../models";

@Injectable()
export class ApplicationService {
	public alerts$ = new BehaviorSubject<Alert[]>([]);
	public closedAlerts$ = new BehaviorSubject<Alert[]>([]);

	private url: string;
	private isDarkTheme: boolean;
	private forceStop: boolean;
	private horsesBetterEnabled: boolean;
	private betrobotBetterEnabled: boolean;
	private soccerBetterEnabled: boolean;
	private _user: User;
	private _pushfeedConnected: boolean;

	public mobileView: boolean;

	onRefresh: EventEmitter<boolean>;
	onLogin: EventEmitter<boolean>;
	onChangeTheme: EventEmitter<string>;
	onHorsesBetterChangeStatus: EventEmitter<boolean>;
	onBetrobotBetterChangeStatus: EventEmitter<boolean>;
	onSoccerBetterChangeStatus: EventEmitter<boolean>;

	constructor(private http: HttpClient) {
		this.url = environment.BETROBOT_API_ENDPOINT + "/system";
		this.mobileView = false;
		this.forceStop = false;
		this.horsesBetterEnabled = false;
		this.soccerBetterEnabled = false;
		this._pushfeedConnected = false;

		this.onRefresh = new EventEmitter<boolean>();
		this.onLogin = new EventEmitter<boolean>();
		this.onChangeTheme = new EventEmitter<string>();
		this.onHorsesBetterChangeStatus = new EventEmitter<boolean>();
		this.onBetrobotBetterChangeStatus = new EventEmitter<boolean>();
		this.onSoccerBetterChangeStatus = new EventEmitter<boolean>();
	}

	get user(): User {
		return this._user;
	}

	get isPushfeedConnected(): boolean {
		return this._pushfeedConnected;
	}

	setPushfeedConnected(value: boolean) {
		this._pushfeedConnected = value;
	}

	get awsServers() {
		const servers = new Array<[string, string]>();
		servers.push(["Staging1", "https://betrobot-staging.grassiventures.com"]);
		servers.push(["Staging2", "https://betrobot-staging2.grassiventures.com"]);
		// servers.push(["French-Horses-Production", null]);
		// servers.push(["Soccer", null]);
		servers.push(["AWS-RDP", null]);

		return servers;
	}

	notifyRefresh() {
		this.onRefresh.emit(true);
	}

	notifyLogin() {
		this.onLogin.emit(true);
	}

	setUser(user: User) {
		this._user = user;
	}

	getSystemInfo(): Observable<SystemInfo> {
		return this.http.get<SystemInfo>(this.url + "/info");
	}

	setDarkTheme(value: boolean) {
		this.isDarkTheme = value;
		this.onChangeTheme.emit(value ? "dark" : "light");
	}

	get darkTheme(): boolean {
		return this.isDarkTheme;
	}

	setForceStop(value: boolean) {
		this.forceStop = value;
	}

	setHorsesBetterStatus(value: string) {
		const oldValue = this.horsesBetterEnabled;
		this.horsesBetterEnabled = value === "enabled" ? true : false;
		if (oldValue !== this.horsesBetterEnabled) {
			this.onHorsesBetterChangeStatus.emit(this.horsesBetterEnabled);
		}
	}

	setBetrobotBetterStatus(value: string) {
		const oldValue = this.betrobotBetterEnabled;
		this.betrobotBetterEnabled = value === "enabled" ? true : false;
		if (oldValue !== this.betrobotBetterEnabled) {
			this.onBetrobotBetterChangeStatus.emit(this.betrobotBetterEnabled);
		}
	}

	setSoccerBetterStatus(value: string) {
		const oldValue = this.soccerBetterEnabled;
		this.soccerBetterEnabled = value === "enabled" ? true : false;
		if (oldValue !== this.soccerBetterEnabled) {
			this.onSoccerBetterChangeStatus.emit(this.soccerBetterEnabled);
		}
	}

	isForceStop(): boolean {
		return this.forceStop;
	}

	isHorsesBetterEnabled(): boolean {
		return this.horsesBetterEnabled;
	}

	clearParams(): Observable<object> {
		return this.http.post(`${this.url}/clearParams`, null);
	}

	clearBets(): Observable<object> {
		return this.http.post(`${this.url}/clearBets?ts=${new Date().getTime()}`, null);
	}

	switchAutoStartupOn(): Observable<object> {
		return this.http.post(`${this.url}/autostartup/on`, null);
	}

	switchAutoStartupOff(): Observable<object> {
		return this.http.post(`${this.url}/autostartup/off`, null);
	}

	enableEmergencyHaltLight(): Observable<object> {
		return this.http.post(`${this.url}/emergencyHaltLight/enable`, null);
	}

	enableEmergencyHalt(): Observable<object> {
		return this.http.post(`${this.url}/emergencyHalt/enable`, null);
	}

	disableEmergencyHalt(): Observable<object> {
		return this.http.post(`${this.url}/emergencyHalt/disable`, null);
	}

	enableSPEvents(): Observable<object> {
		return this.http.post(`${this.url}/spEvents/enable`, null);
	}

	disableSPEvents(): Observable<object> {
		return this.http.post(`${this.url}/spEvents/disable`, null);
	}

	enableBBEvents(): Observable<object> {
		return this.http.post(`${this.url}/bbEvents/enable`, null);
	}

	disableBBEvents(): Observable<object> {
		return this.http.post(`${this.url}/bbEvents/disable`, null);
	}

	cancelAllBets(): Observable<object> {
		return this.http.post(`${this.url}/cancelAllBets`, null);
	}

	serverStatus(serverName: string): Observable<object> {
		return this.http.get(`${this.url}/${serverName}/status`);
	}

	allServersStatus(): Observable<object> {
		return this.http.get(`${this.url}/servers/status`);
	}

	serverStart(serverName: string): Observable<object> {
		return this.http.get(`${this.url}/${serverName}/start`);
	}

	serverStop(serverName: string): Observable<object> {
		return this.http.get(`${this.url}/${serverName}/stop`);
	}

	enableBetrobotBetter(): Observable<object> {
		return this.http.get(`${this.url}/better/enable`);
	}

	disableBetrobotBetter(): Observable<object> {
		return this.http.get(`${this.url}/better/disable`);
	}

	enableUKHorsesBetter(): Observable<object> {
		return this.http.get(`${this.url}/races/UK/enable`);
	}

	disableUKHorsesBetter(): Observable<object> {
		return this.http.get(`${this.url}/races/UK/disable`);
	}

	enableSoccerPreMatchBetter(): Observable<object> {
		return this.http.get(`${this.url}/races/soccer/enable`);
	}

	disableSoccerPreMatchBetter(): Observable<object> {
		return this.http.get(`${this.url}/races/soccer/disable`);
	}

	downloadAsCSV(data: Array<unknown>, fileName: string, header: string = null, stringify: string[] = null) {
		if (data && data.length > 0) {
			if (!stringify) {
				stringify = [];
			}

			let headerText = header;
			if (!headerText && data.length > 0) {
				headerText = Object.keys(data[0])
					.map((field) => field.replace(",", "_"))
					.join(",");
			}

			const contents = data.map((entry) =>
				Object.entries(entry)
					.map((record) => {
						const property = record[0];
						let value = record[1];
						if (value || value === 0) {
							if (value instanceof Date) {
								value = DateTime.fromJSDate(value).toFormat("dd/MM/yyyy HH:mm:ss");
							} else if (stringify.includes(property)) {
								value = ` ${value}`;
							} else if (value.replace) {
								value = value.replace(",", "_");
							}
							return value;
						} else {
							return "";
						}
					})
					.join(",")
			);
			const blob = new Blob([["sep=,", headerText].concat(contents).join("\n")], { type: "text/csv" });

			const anchor = document.createElement("a");
			anchor.download = fileName;
			anchor.href = window.URL.createObjectURL(blob);
			anchor.dataset.downloadurl = ["text/plain", anchor.download, anchor.href].join(":");
			anchor.click();
		}
	}

	setAlerts(alerts: Alert[]) {
		this.alerts$.next(alerts);
	}

	setClosedAlerts(alerts: Alert[]) {
		this.closedAlerts$.next(alerts);
	}
}
