<div class="box" [class.scroll]="mode==='live'">
	<table mat-table [dataSource]="dataSource" matSort class="alternate" [ngClass]="{'dark-theme': darkTheme, 'reverse': mode==='live', 'mobile': mode==='mobile'}">
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef>
				<button mat-icon-button #detailMenuTrigger
									[matMenuTriggerFor]="detailMenu"
									[matMenuTriggerData]="{records: recordSelection.selected}"
									[disabled]="recordSelection.isEmpty()"
									(click)="initMenu(recordSelection.selected)">
					<mat-icon>more_vert</mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let record">
				<button mat-icon-button #detailMenuTrigger
									[matMenuTriggerFor]="detailMenu"
									[matMenuTriggerData]="{records: [record]}"
									[disabled]="false"
									(click)="initMenu([record])">
					<mat-icon>more_vert</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox [checked]="recordSelection.hasValue() && allRecordsSelected"
											[indeterminate]="recordSelection.hasValue() && !allRecordsSelected"
											(change)="$event ? toggleAllRecordsSelection() : null">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let record">
				<mat-checkbox *ngIf="isAlert([record])"
										    [checked]="recordSelection.isSelected(record)"
											(click)="$event.stopPropagation()"
											(change)="$event ? recordSelection.toggle(record) : null">
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container matColumnDef="timestamp">
			<th mat-header-cell *matHeaderCellDef>Time</th>
			<td mat-cell *matCellDef="let record">
				<span *ngIf="mode=='mobile'">{{record.timestamp | date:'HH:mm:ss'}}</span>
				<span *ngIf="mode!='mobile'">{{record.timestamp | date:'dd-MM-yyyy HH:mm:ss'}}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="type">
			<th mat-header-cell *matHeaderCellDef>Type</th>
			<td mat-cell *matCellDef="let record"> {{translateType(record.type)}} </td>
		</ng-container>

		<ng-container matColumnDef="matchId">
			<th mat-header-cell *matHeaderCellDef>Match ID</th>
			<td mat-cell *matCellDef="let record">
				<mat-chip *ngIf="record.logging" class="logging" matTooltip="Logging enabled">{{record.matchId}}</mat-chip>
				<span *ngIf="!record.logging">{{record.matchId}}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="fixture">
			<th mat-header-cell *matHeaderCellDef>Match</th>
			<td mat-cell *matCellDef="let record">
				<span *ngIf="mode=='mobile'">{{record.fixture || record.details}}</span>
				<span *ngIf="mode!='mobile'">{{record.fixture}}</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="league">
			<th mat-header-cell *matHeaderCellDef>League</th>
			<td mat-cell *matCellDef="let record"> {{record.league}} </td>
		</ng-container>

		<ng-container matColumnDef="factor">
			<th mat-header-cell *matHeaderCellDef>Factor</th>
			<td mat-cell *matCellDef="let record"> {{record.leagueFactor}} </td>
		</ng-container>

		<ng-container matColumnDef="score">
			<th mat-header-cell *matHeaderCellDef>Score</th>
			<td mat-cell *matCellDef="let record"> {{record.score}} </td>
		</ng-container>

		<ng-container matColumnDef="minute">
			<th mat-header-cell *matHeaderCellDef>Minute</th>
			<td mat-cell *matCellDef="let record"> {{record.gameTime ? record.gameTime : record.minute}} </td>
		</ng-container>

		<ng-container matColumnDef="setNr">
			<th mat-header-cell *matHeaderCellDef>Set</th>
			<td mat-cell *matCellDef="let record"> {{record.setNr}} </td>
		</ng-container>

		<ng-container matColumnDef="halted">
			<ng-container *ngIf="mode!=='live'">
				<th mat-header-cell *matHeaderCellDef>Halted</th>
				<td mat-cell *matCellDef="let record"> {{record.halted ? "Yes" : "No"}} </td>
			</ng-container>
			<ng-container *ngIf="mode==='live'">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Halted</th>
				<td mat-cell *matCellDef="let record"> {{record.halted ? "Yes" : "No"}} </td>
			</ng-container>
		</ng-container>

		<ng-container matColumnDef="details">
			<th mat-header-cell *matHeaderCellDef>Details</th>
			<td mat-cell *matCellDef="let record">
				<span *ngIf="isAlert([record])" class="link" (click)="openContent(record)"> {{record.details}} </span>
				<span *ngIf="!isAlert([record])"> {{record.details}} </span>
			</td>
		</ng-container>

		<ng-container matColumnDef="checked">
			<th mat-header-cell *matHeaderCellDef>User</th>
			<td mat-cell *matCellDef="let record"> {{record.beingChecked}} </td>
		</ng-container>

		<ng-container matColumnDef="processed">
			<th mat-header-cell *matHeaderCellDef>Processed</th>
			<td mat-cell *matCellDef="let record"> {{record.processed | date:'HH:mm:ss'}} </td>
		</ng-container>

		<ng-container matColumnDef="user">
			<th mat-header-cell *matHeaderCellDef>User</th>
			<td mat-cell *matCellDef="let record"> {{record.user}} </td>
		</ng-container>

		<ng-container matColumnDef="note">
			<th mat-header-cell *matHeaderCellDef>Note</th>
			<td mat-cell *matCellDef="let record"> {{record.note}} </td>
		</ng-container>

		<ng-container matColumnDef="customNote">
			<th mat-header-cell *matHeaderCellDef>Note</th>
			<td mat-cell *matCellDef="let record" colspan="7"> {{record.note}} </td>
		</ng-container>

		<tr mat-header-row class="sticky" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: columnsNotes; when: noteRow"></tr>
		<tr mat-row [class.warn]="row.warn" *matRowDef="let row; columns: displayedColumns; when: alertRow"></tr>
	</table>
</div>

<mat-menu #detailMenu="matMenu" xPosition="after">
	<ng-template let-records="records" matMenuContent>
		<ng-container *ngIf="isAlert(records)">
			<button mat-menu-item (click)="editAlert(records)">Comment & Close</button>
			<button mat-menu-item [disabled]="!pending(records)" (click)="checking(records)">Checking</button>
			<button mat-menu-item [disabled]="!pending(records)" (click)="ignore(records)">Close</button>
		</ng-container>
		<ng-container *ngIf="records.length == 1 && isAlert(records)">
			<button mat-menu-item (click)="openContent(records[0])">View details</button>
		</ng-container>
		<ng-container *ngIf="records.length == 1 && !isAlert(records)">
			<button mat-menu-item (click)="editNote(records[0])">Edit note</button>
		</ng-container>
		<ng-container *ngIf="records.length == 1 && isAlert(records) && records[0].type=='late_bet'">
			<mat-divider></mat-divider>
			<button mat-menu-item (click)="emailLateSuspensions(records[0])">Send email</button>
		</ng-container>
		<ng-container *ngIf="mode !== 'match' && mode !== 'mobile' && links && records.length == 1 && (records[0].matchId || records[0].raceId)">
			<mat-divider></mat-divider>
			<button mat-menu-item [matMenuTriggerFor]="linksMenu">Links</button>
			<mat-menu #linksMenu="matMenu" xPosition="after">
				<ng-template matMenuContent>
					<button mat-menu-item *ngFor="let link of linksList" (click)="openLink(records[0].linkDetails, records[0].linkArgs)">{{link.key}}</button>
				</ng-template>
			</mat-menu>
		</ng-container>
		<ng-container *ngIf="haltedSets">
			<mat-divider></mat-divider>
			<ng-container *ngIf="haltedSetsList.length == 1">
				<button mat-menu-item class="gv-menu-hover gv-menu-info gv-menu-icon" (click)="resumeSet(records, haltedSetsList[0].key)">
					<mat-icon>play_arrow</mat-icon>
					<span>Resume {{haltedSetsList[0].value}}</span>
				</button>
			</ng-container>
			<ng-container *ngIf="haltedSetsList.length > 1">
				<button mat-menu-item class="gv-menu-hover gv-menu-info gv-menu-icon" [matMenuTriggerFor]="resumeMenu">
					<mat-icon>play_arrow</mat-icon>
					<span>Resume</span>
				</button>
				<mat-menu #resumeMenu="matMenu" xPosition="after">
					<ng-template matMenuContent>
						<button mat-menu-item class="gv-menu-hover gv-menu-info" (click)="resumeSet(records)">Resume all sets</button>
						<button mat-menu-item *ngFor="let set of haltedSetsList" (click)="resumeSet(records, set.key)">Resume {{set.value}}</button>
					</ng-template>
				</mat-menu>
			</ng-container>
		</ng-container>
	</ng-template>
</mat-menu>
