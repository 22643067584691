export class FixtureAlert {
	count: number;
	initial: string;
	description: string;
	order: number;

	constructor(count, initial, description, order) {
		this.count = count;
		this.initial = initial;
		this.description = description;
		this.order = order;
	}
}
