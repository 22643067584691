import { DateTime } from "luxon";

export class TransactionHour {
	public hour: string;
	public values;

	constructor(date: Date) {
		this.hour = DateTime.fromJSDate(date).toFormat("HH:mm");
		this.values = {};
	}

	public add(values) {
		for (const action in values) {
			if (!(action in this.values)) {
				this.values[action] = {};
			}

			for (const outcome in values[action]) {
				if (!(outcome in this.values[action])) {
					this.values[action][outcome] = 0;
				}

				this.values[action][outcome] += values[action][outcome];
			}
		}
	}
}

export class TransactionSet {
	public setNr: number;
	public setName: string;
	public setShort: string;
	private records: TransactionHour[];

	constructor(setName: string) {
		this.setNr = +setName.replace("Set ", "");
		this.setName = setName;
		this.setShort = setName.replace("Set ", "S");
		this.records = new Array<TransactionHour>();
	}

	public add(date: Date, values) {
		let existing = this.records.find((entry) => entry.hour === DateTime.fromJSDate(date).toFormat("HH:mm"));
		if (!existing) {
			existing = new TransactionHour(date);
			this.records.push(existing);
		}

		existing.add(values);
	}

	get data(): TransactionHour[] {
		return this.records.sort((lh, rh) => (lh.hour < rh.hour ? -1 : 1));
	}

	getHourData(hour: string) {
		return this.records.find((entry) => entry.hour === hour);
	}
}

export class TransactionDate {
	public date: string;
	private records: TransactionSet[];

	constructor(date: Date) {
		this.date = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
		this.records = new Array<TransactionSet>();
	}

	public add(date: Date, values) {
		Object.keys(values).forEach((set) => {
			let existing = this.records.find((entry) => entry.setName === set);
			if (!existing) {
				existing = new TransactionSet(set);
				this.records.push(existing);
			}
			// this.setList.add(+(set.replace("set ", "")));
			existing.add(date, values[set]);
		});
	}

	get data(): TransactionSet[] {
		return this.records.sort((lh, rh) => (lh.setNr < rh.setNr ? -1 : 1));
	}

	get length(): number {
		return this.records.length;
	}
}

export class Transactions {
	private records: TransactionDate[];

	constructor() {
		this.records = new Array<TransactionDate>();
	}

	public add(date: Date, values) {
		let existing = this.records.find((entry) => entry.date === DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"));
		if (!existing) {
			existing = new TransactionDate(date);
			this.records.push(existing);
		}

		existing.add(date, values);
	}

	get data(): TransactionDate[] {
		return this.records.sort((lh, rh) => (lh.date < rh.date ? 1 : -1));
	}

	get length(): number {
		return this.records.length;
	}
}
