import { EventEmitter } from "@angular/core";

import { Hundred } from "./hundred";
import { Bookie } from "./bookie";

export class OddsLine {
	id: string;
	marketTypeId: number;
	marketType: string;
	market: string;
	lineTimestamp: number;
	newLineCountdown: number;
	hundred: Hundred[];
	bookies: Bookie[];

	sortField: string;
	sortDirection: number;
	onUpdate = new EventEmitter();

	_lineAge: number = null;
	_injectedHundred: Hundred[];

	constructor(odd: OddsLine) {
		this.sortField = "bookieName";
		this.sortDirection = 0;
		this._injectedHundred = new Array<Hundred>();

		this.id = odd.id;
		this.marketTypeId = odd.marketTypeId;
		this.marketType = odd.marketType;
		this.market = typeof odd.market === "number" ? parseFloat(odd.market).toFixed(2) : odd.market;
		this.lineTimestamp = odd.lineTimestamp || 0;
		this.newLineCountdown = odd.newLineCountdown || 0;
		this.bookies = new Array<Bookie>();

		const bookies = {};
		const hundred = {};
		Object.keys(odd).forEach((property) => {
			if (property.startsWith("bookie_")) {
				const { bookieId, fieldName } = this.splitBookieField(property);

				if (!bookies[bookieId]) {
					bookies[bookieId] = new Bookie(null);
				}

				bookies[bookieId][fieldName] = odd[property];
			}
			if (property.startsWith("hundred_")) {
				const { bookieId, fieldName } = this.splitBookieField(property);

				if (!hundred[bookieId]) {
					hundred[bookieId] = new Hundred(null);
					hundred[bookieId].desc = bookieId;
				}

				hundred[bookieId][fieldName] = odd[property];
			}
		});

		Object.keys(bookies).forEach((bookieId) => {
			this.bookies.push(new Bookie(bookies[bookieId]));
		});
		Object.keys(hundred).forEach((entry) => {
			this._injectedHundred.push(new Hundred(hundred[entry]));
		});

		this.recalculateHundred();
	}

	private splitBookieField(field: string) {
		const firstDelimiter = field.indexOf("_");
		const secondDelimiter = field.indexOf("_", firstDelimiter + 1);

		const bookieId = field.substring(firstDelimiter + 1, secondDelimiter);
		const fieldName = field.substr(secondDelimiter + 1);

		return { bookieId, fieldName };
	}

	get is1X2(): boolean {
		return this.marketTypeId === 1 || this.marketTypeId === 2;
	}

	get isBTTS(): boolean {
		return this.marketTypeId === 8;
	}

	get isAHC(): boolean {
		return this.marketTypeId === 5;
	}

	get lineAge(): number {
		if (this._lineAge === null) {
			this._lineAge = (Date.now() - this.lineTimestamp) / 1000;
		}

		return this._lineAge;
	}

	set lineAge(value: number) {
		this._lineAge = value;
	}

	get isNew(): boolean {
		return this.lineAge < this.newLineCountdown;
	}

	get hundredCalcs(): Hundred[] {
		if (!this.hundred) {
			this.recalculateHundred();
		}

		return this.hundred;
	}

	get marketName(): string {
		if (this.marketType.startsWith("CS")) {
			return this.marketType.replace("CS", "AHC");
		} else {
			return this.marketType;
		}
	}

	get marketHeaderLeft(): string {
		if (this.is1X2) {
			return "H";
		} else if (this.marketTypeId === 5 || this.marketTypeId === 6) {
			return "1";
		} else if (this.marketTypeId === 8) {
			return "Y";
		} else {
			return "O";
		}
	}

	get marketHeaderMiddle(): string {
		if (this.is1X2) {
			return "D";
		} else {
			return "";
		}
	}

	get marketHeaderRight(): string {
		if (this.is1X2) {
			return "A";
		} else if (this.marketTypeId === 5 || this.marketTypeId === 6) {
			return "2";
		} else if (this.marketTypeId === 8) {
			return "N";
		} else {
			return "U";
		}
	}

	get filteredBookies(): Bookie[] {
		const result = new Array<Bookie>();

		this.bookies.forEach((bookie) => {
			if (bookie.valid && !bookie.outlier && !bookie.monitor) {
				result.push(bookie);
			}
		});

		return result;
	}

	public update(updates) {
		if (updates.changes) {
			const bookies = [];
			const hundred = [];

			Object.keys(updates.changes).forEach((property) => {
				if (property.startsWith("bookie_")) {
					const { bookieId, fieldName } = this.splitBookieField(property);
					const existing = this.bookies.find((bookie) => bookie.bookieId === +bookieId);
					if (existing) {
						existing.update(fieldName, updates.changes[property]);
					} else {
						if (!bookies[bookieId]) {
							bookies[bookieId] = new Bookie(null);
							bookies[bookieId].bookieId = bookieId;
						}
						bookies[bookieId][fieldName] = updates.changes[property];
					}
				} else if (property.startsWith("hundred_")) {
					const { bookieId, fieldName } = this.splitBookieField(property);
					const existing = this._injectedHundred.find((entry) => entry.desc === bookieId);
					if (existing) {
						existing[fieldName] = updates.changes[property];
					} else {
						if (!hundred[bookieId]) {
							hundred[bookieId] = new Hundred(null);
							hundred[bookieId].desc = bookieId;
						}
						hundred[bookieId][fieldName] = updates.changes[property];
					}
				} else {
					this[property] = updates.changes[property];
				}
			});

			if (Object.keys(bookies).length > 0) {
				Object.keys(bookies).forEach((bookieId) => {
					this.bookies.push(new Bookie(bookies[bookieId]));
				});
			}
			if (Object.keys(hundred).length > 0) {
				Object.keys(hundred).forEach((entry) => {
					this._injectedHundred.push(new Hundred(hundred[entry]));
				});
			}
		}

		if (updates.deletions) {
			Object.keys(updates.deletions).forEach((property) => {
				if (property.startsWith("bookie_")) {
					const { bookieId, fieldName } = this.splitBookieField(property);
					const index = this.bookies.findIndex((bookie) => bookie.bookieId === +bookieId);
					if (index > -1) {
						if (fieldName === "bookieId") {
							this.bookies.splice(index, 1);
						} else {
							this.bookies[index][fieldName] = "";
						}
					}
				}
				if (property.startsWith("hundred_")) {
					const { bookieId } = this.splitBookieField(property);
					const index = this._injectedHundred.findIndex((entry) => entry.desc === bookieId);
					if (index > -1) {
						this._injectedHundred.splice(index, 1);
					}
				}
			});
		}

		this.recalculateHundred();
		this.refresh();
	}

	refresh() {
		this.onUpdate.emit(null);
	}

	private recalculateHundred() {
		const result = new Array<Hundred>();

		result.push(this.calculateNewHundredPercentPrices());
		result.push(this.calculateHundredPercentPrices());
		result.push(...this._injectedHundred);

		this.hundred = result;
	}

	private calculateHundredPercentPrices(): Hundred {
		let home = 0;
		let away = 0;
		let draw = 0;
		let counter = 0;

		for (const bookie of this.filteredBookies) {
			const homePerc = 100 / bookie.home;
			const awayPerc = 100 / bookie.away;
			const drawPerc = bookie.draw ? 100 / bookie.draw : 0;
			const total = homePerc + awayPerc + drawPerc;

			home += (homePerc / total) * 100;
			away += (awayPerc / total) * 100;
			draw += drawPerc ? (drawPerc / total) * 100 : 0;
			counter++;
		}

		return new Hundred({
			desc: "100%",
			home: 100 / (home / counter),
			away: 100 / (away / counter),
			draw: draw ? 100 / (draw / counter) : null,
			percentage: null,
		});
	}

	private calculateNewHundredPercentPrices(): Hundred {
		let overs = 0;
		let unders = 0;
		let draw = 0;
		let counter = 0;

		for (const bookie of this.filteredBookies) {
			const correctedPrices = this.complexDemargin(bookie.home, bookie.away, bookie.draw ? bookie.draw : null);
			overs += correctedPrices.over;
			unders += correctedPrices.under;
			if (correctedPrices.draw !== null) {
				draw += correctedPrices.draw;
			}
			counter++;
		}

		return new Hundred({
			desc: "NEW",
			home: 1 / (overs / counter),
			away: 1 / (unders / counter),
			draw: draw ? 1 / (draw / counter) : null,
			percentage: null,
		});
	}

	private complexDemargin(p1: number, p2: number, p3: number = null) {
		let error = 1;
		let c = 1;

		while (error > 0.0001) {
			let f = Math.pow(1 / p1, c) + Math.pow(1 / p2, c);
			if (p3) {
				f += Math.pow(1 / p3, c);
			}
			f -= 1;

			let f_dash = Math.pow(1 / p1, c) * -Math.log(p1) + Math.pow(1 / p2, c) * -Math.log(p2);
			if (p3) {
				f_dash += Math.pow(1 / p3, c) * -Math.log(p3);
			}

			c = c - f / f_dash;
			error = Math.pow(1 / p1, c) + Math.pow(1 / p2, c);
			if (p3) {
				error += Math.pow(1 / p3, c);
			}
			error = Math.abs(error) - 1;
		}

		return this.plainDemargin(1 / Math.pow(1 / p1, c), 1 / Math.pow(1 / p2, c), p3 ? 1 / Math.pow(1 / p3, c) : null);
	}

	private plainDemargin(p1: number, p2: number, p3: number = null) {
		let total = 1 / p1 + 1 / p2;
		if (p3) {
			total += 1 / p3;
		}

		return {
			over: 1 / p1 / total,
			under: 1 / p2 / total,
			draw: p3 ? 1 / p3 / total : null,
		};
	}
}
