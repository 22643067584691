import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/env";
import { RawData, RaceSchedule } from "../models";

@Injectable()
export class HorsebetService {
	protected url: string;

	constructor(protected http: HttpClient) {
		this.url = `${environment.BETROBOT_API_ENDPOINT}/races`;
	}

	getUpcomingRaces(sport: string[]): Observable<RawData[]> {
		const params = `?sport=${sport.join(",")}`;
		return this.http.get<RawData[]>(`${this.url}/upcoming${params}`);
	}

	stopUKRaces(raceIds: string[]): Observable<unknown> {
		return this.http.post<unknown>(`${this.url}/UK/stop`, raceIds);
	}

	startUKRaces(raceIds: string[]): Observable<unknown> {
		return this.http.post<unknown>(`${this.url}/UK/start`, raceIds);
	}

	resumeUKRaces(raceIds: string[]): Observable<unknown> {
		return this.http.post<unknown>(`${this.url}/UK/resume`, raceIds);
	}

	getSchedule(uniqId: string[]): Observable<RaceSchedule[]> {
		if (uniqId.length == 1) {
			return this.http
				.get<RaceSchedule[]>(`${this.url}/UK/schedule/${uniqId[0]}`)
				.pipe(map((response) => response.map((entry) => new RaceSchedule(entry))));
		} else {
			return of([]);
		}
	}

	saveSchedule(uniqId: string[], schedule: RaceSchedule[]): Observable<RaceSchedule[]> {
		return this.http
			.post<RaceSchedule[]>(`${this.url}/UK/schedule`, {
				ids: uniqId,
				schedule: schedule.map((entry) => entry.export()),
			})
			.pipe(map((response) => response.map((entry) => new RaceSchedule(entry))));
	}
}
