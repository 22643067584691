import * as localEnvironment from "./env.local";
import * as developmentEnvironment from "./env.development";
import * as staging1Environment from "./env.staging1";
import * as staging2Environment from "./env.staging2";
import * as staging3Environment from "./env.staging3";
import * as staging4Environment from "./env.staging4";
import * as productionEnvironment from "./env.production";

import { env } from "./selectedEnv";

const environments = {
	local: localEnvironment,
	development: developmentEnvironment,
	staging1: staging1Environment,
	staging2: staging2Environment,
	staging3: staging3Environment,
	staging4: staging4Environment,
	production: productionEnvironment,
};

export const environment = environments[env].environment;
