import { BookieNames } from "./bookieNames";

export class Bookie {
	bookieId: number;
	bookie: string;
	bookieName: string;
	home: number;
	away: number;
	draw: number;
	lineAge: number;
	lineId: number;
	expiry: number;
	outlier: boolean;
	valid: boolean;
	monitor: boolean;

	private _expires: string;

	constructor(bookie: Bookie) {
		const bookieNames = new BookieNames();

		if (bookie) {
			this.bookieId = bookie.bookieId || 0;
			this.bookie = bookie.bookie;
			this.bookieName = bookieNames.translate(this.name);
			this.home = bookie.home || 0;
			this.away = bookie.away || 0;
			this.draw = bookie.draw || 0;
			this.lineAge = bookie.lineAge || 0;
			this.lineId = bookie.lineId || 0;
			this.expiry = bookie.expiry || 0;
			this.outlier = bookie.outlier.toString() === "1" || bookie.outlier.toString() === "true";
			this.valid = bookie.valid.toString() === "1" || bookie.valid.toString() === "true";
			this.monitor = bookie.monitor.toString() === "1" || bookie.monitor.toString() === "true";
		}

		this._expires = "-";
		this.refreshExpiry();
	}

	get source(): string {
		if (this.bookie.startsWith("sp_")) {
			return "SP";
		} else if (this.bookie.startsWith("be_") || this.bookie.endsWith("eastbridge")) {
			return "BE";
		} else if (this.bookie.startsWith("bm_") || this.bookie.endsWith("mollybet")) {
			return "BM";
		} else if (this.bookie.startsWith("bi_")) {
			return "BI";
		} else {
			return "TX";
		}
	}

	get name(): string {
		if (this.bookie.startsWith("sp_")) {
			return this.bookie.replace("sp_", "");
		} else if (this.bookie.startsWith("be_")) {
			return this.bookie.replace("be_", "");
		} else if (this.bookie.startsWith("bm_")) {
			return this.bookie.replace("bm_", "");
		} else if (this.bookie.startsWith("bi_")) {
			return this.bookie.replace("bi_", "");
		} else if (this.bookie.startsWith("pin_")) {
			return "pinnacle";
		} else {
			return this.bookie;
		}
	}

	get expires(): string {
		return this._expires;
	}

	get percentage(): number {
		const home = this.home ? (1 / this.home) * 100 : 0;
		const away = this.away ? (1 / this.away) * 100 : 0;
		const draw = this.draw ? (1 / this.draw) * 100 : 0;

		return home + away + draw;
	}

	update(fieldName, value) {
		if (fieldName === "outlier" || fieldName === "valid" || fieldName === "monitor") {
			this[fieldName] = value.toString() === "1" || value.toString() === "true";
		} else {
			this[fieldName] = value;
		}
	}

	refreshExpiry() {
		if (this.expiry) {
			this._expires = ((Date.now() - this.expiry) / 1000).toFixed(0);
		} else {
			this._expires = "-";
		}
	}
}
