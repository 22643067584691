<mat-form-field>
	<input matInput [matDatepicker]="fromDatePicker" placeholder="From date" [formControl]="fromDate" (dateChange)="changeFromDate($event)">
	<mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
	<mat-datepicker #fromDatePicker></mat-datepicker>
</mat-form-field>
&nbsp;
<mat-form-field>
	<input matInput [matDatepicker]="toDatePicker" placeholder="To date" [formControl]="toDate" (dateChange)="changeToDate($event)">
	<mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
	<mat-datepicker #toDatePicker></mat-datepicker>
</mat-form-field>
