import { Parameter } from "./parameter";

export class FixtureParameter {
	public setId: string;
	public defaults: Parameter[];
	public custom: Parameter[];

	constructor(source: FixtureParameter = null) {
		if (source) {
			this.setId = source.setId;
			this.defaults = source.defaults.map((param) => new Parameter(param));
			this.custom = source.custom.map((param) => new Parameter(param));
		} else {
			this.defaults = new Array<Parameter>();
			this.custom = new Array<Parameter>();
		}
	}
}
