import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { AuthService } from "./auth.service";
import { User } from "../../shared/models/user";

@Injectable()
export class AdminGuardService implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(): Promise<boolean> {
		return new Promise((resolve) => {
			this.authService.authenticate().subscribe(
				(user: User) => {
					if (user.role === "admin") {
						resolve(true);
					} else {
						resolve(false);
					}
				},
				() => {
					resolve(false);
				}
			);
		});
	}
}
