import { Coordinates } from "./coordinates";

export class Side {
	name: string;
	coordinates: Coordinates;

	constructor(name: string, coordinates: Coordinates) {
		this.name = name;
		this.coordinates = coordinates;
	}
}
