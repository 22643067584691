import { Side } from "./side";

export class Area {
	name: string;
	color: string;
	alpha: number;
	sides: Side[];

	constructor(name: string, color: string, alpha: number, sides: Side[]) {
		this.name = name;
		this.color = color;
		this.alpha = alpha;
		this.sides = sides;
	}
}
