import { BFPricesMarket } from "./bfPricesMarket";
import { Calculation } from "./calculation";

export class BFPrices {
	private _entries: BFPricesMarket[];

	constructor(data: Calculation[] = null) {
		this._entries = new Array<BFPricesMarket>();
		if (data) {
			this.update(data);
		}
	}

	update(data: Calculation[]) {
		if (data) {
			for (const entry of data.sort(this.dateSorter.bind(this))) {
				if (entry.market && entry.market !== "-") {
					let existing = this._entries.find((market) => market.name === entry.fullMarketName);
					if (!existing) {
						existing = new BFPricesMarket(entry);
						this._entries.push(existing);
						this._entries.sort(this.marketSorter.bind(this));
					}
					if (entry.hasBfPrices) {
						existing.update(entry);
					} else {
						existing.clear();
					}
				}
			}
		}
	}

	get entries(): BFPricesMarket[] {
		return this._entries.sort(this.marketSorter.bind(this));
	}

	get hasRecords(): boolean {
		return this._entries.filter((entry) => entry.hasRecords).length > 0;
	}

	private marketSorter(a: BFPricesMarket, b: BFPricesMarket): number {
		return a.name < b.name ? -1 : 1;
	}

	private dateSorter(a: Calculation, b: Calculation): number {
		return a.timestamp.getTime() < b.timestamp.getTime() ? -1 : 1;
	}
}
